<template>
  <div class="text-center"
  :style="`width: ${$vuetify.breakpoint.xs ? 30 : 50}px`">
    <v-icon
      :color="!isEmbed ? 'primary' : ''"
      :style="isEmbed ? `color: #${embed.customization.alternative}` : ''"
      :size="$vuetify.breakpoint.xs ? 30 : 50"
    >
      {{ game.sport.icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "SportBadge",
  props: {
    game: Object,
  },
  computed: {
    smallScreenScale() {
      if (this.windowWidth > 412) return 1;
      return this.windowWidth / 412;
    },
    embed() {
      return this.$store.getters.getCurrentEmbed;
    },
  },
  data: () => ({
    windowWidth: null,
  }),
  mounted() {
    this.onResize();
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
::v-deep i {
  font-style: normal;
}


@media screen and (max-width: 412px) {}
</style>