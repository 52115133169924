<template>
    <v-badge
        icon="mdi-information"
        color="transparent"
        overlap
        :offset-x="25"
        :offset-y="-7"
      >
        <template v-slot:badge>
          <v-avatar color="transparent" class="action-link" @click="hypeDialogOpen = !hypeDialogOpen">
            <v-icon small color="black">mdi-information</v-icon>
          </v-avatar>
        </template>
        <div>FAN EXCITEMENT</div>
        <v-dialog v-model="hypeDialogOpen">
          <hype-explainer @done="hypeDialogOpen=false"/>
        </v-dialog>
      </v-badge>
</template>

<script>
import HypeExplainer from "./HypeExplainer";

export default {
  name: "FanExcitementTitle",
  components: {
    HypeExplainer,
  },
  data() {
    return {
      hypeDialogOpen: false,
    }
  },
}
</script>
