<template>
  <!-- DENSE -->
  <v-sheet
    justify="center"
    class="text-center text-uppercase"
    :class="sheetClass"
    :style="isEmbed ? 'background-color: #FCFCFC;' : ''"
    v-if="layout"
  >
    <component
      v-if="team.logo_exists"
      :is="teamLinkOrDiv"
      :href="
        team.show_season_schedule
          ? appUrl + '/leagues/' + team.league_slug.toUpperCase() + '/teams/' + team.text_id.toUpperCase()
          : false
      "
    >
      <v-img
        v-if="!isNfl"
        class="mx-auto d-print-none"
        content-class="contain-paint"
        contain
        :height="$vuetify.breakpoint.xl ? 40 : 30"
        :max-width="$vuetify.breakpoint.xl ? 60 : 40"
        :src="logoSource"
        :alt="team.display_name_short + ' Logo'"
      />
    </component>
    <a
      v-if="!isEmbed && team.show_season_schedule"
      :href="appUrl + '/leagues/' + team.league_slug.toUpperCase() + '/teams/' + team.text_id.toUpperCase()"
    >
      {{ team.display_name_short }}
    </a>
    <template v-else>
      {{ team.display_name_short }}
    </template>
  </v-sheet>

  <!-- DESKTOP -->
  <component
    v-else
    :is="teamLinkOrDiv"
    :href="appUrl + '/leagues/' + team.league_slug.toUpperCase() + '/teams/' + team.text_id.toUpperCase()"
  >
    <v-img
      v-if="team.logo_exists"
      content-class="contain-paint"
      class="mx-auto d-print-none mb-2"
      transition="none"
      contain
      max-width="81"
      height="54"
      :src="logoSource"
      :alt="team.display_name_short + ' Logo'"
    />
    <div class="text-uppercase font-weight-thin team-name my-3">
      {{ team.display_name_short }}
    </div>
    <!--<div>15-15</div>-->
  </component>
</template>

<script>
export default {
  name: "Team",
  props: {
    team: Object,
  },
  computed: {
    teamLinkOrDiv() {
      return !this.isEmbed && this.team.show_season_schedule ? "a" : "div";
    },
    layout() {
      return this.$store.getters.getCurrentSelectedOptions("layout");
    },
    sheetClass() {
      if (this.$vuetify.breakpoint.xsOnly) return 'caption';

      if (this.layout == 1 && this.$vuetify.breakpoint.mdOnly) return 'caption';

      if (this.layout == 1 && this.$vuetify.breakpoint.lgOnly) return 'caption';

      if (this.$vuetify.breakpoint.smOnly && this.$vuetify.breakpoint.width <= 700) return 'caption';

      return '';
    },
    logoSource() {
      var logo = this.team.master_team_id
        ? this.team.master_team_id
        : this.team.id;
      return this.appUrl + "/img/logos/teams/" + logo + ".svg";
    },
    isCommercial() {
        return this.$store.getters.getIsCommercial;
    },
    isNfl() {
        return false;
        if ((this.team.league_id == 10)) {
            return true;
        }
        return false;
    },
  },
};
</script>

<style scoped>
.team-name {
  letter-spacing: 0.03em;
  color: black;
  text-wrap: nowrap;
}
.text-uppercase {
  font-size: 1.2em;
}
a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:active {
  text-decoration: none;
  color: black;
}
</style>
