<template>
  <v-row>
    <v-col>
      <v-container style="max-width: 1360px" class="py-0">
                  <v-row justify="space-around">
                    <v-col
                      :class="option.class ? option.class : 'px-5'"
                      v-for="option in options"
                      v-bind:key="option.key"
                      :cols="option.cols ? option.cols : false"
                      :sm="option.sm ? option.sm : false"
                      :md="option.md ? option.md : false"
                      :lg="option.lg ? option.lg : false"
                      :xl="option.xl ? option.xl : false"
                    >
                      <Field :option="option" />
                    </v-col>
                  </v-row>
              <!--
              <v-row>
                <v-col class="px-5">
                  <v-btn
                    x-large
                    elevation="0"
                    color="darkGray"
                    dark
                    block
                    class="rounded-0 large-button-text py-10"
                    >RESET FILTERS</v-btn
                  >
                </v-col>
              </v-row>
              -->
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Field from "./Field";
export default {
  name: "Filters",
  components: {
    Field,
  },
  computed: {
    options() {
      return this.$store.getters.getCurrentOptions;
    },
    bars() {
      return this.$store.getters.getCurrentBars;
    },
    chevron() {
      return this.showOptions ? "mdi-chevron-up" : "mdi-chevron-down";
    },
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-header {
  font-size: 1.65em;
}
.v-expansion-panel {
  background-color: transparent !important;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.large-button-text {
  font-size: 1.4em !important;
}

</style>
