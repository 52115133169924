<template>
  <v-row v-if="isMobile" :id="'game-hype-' + game.id">
    <v-col>
      <v-simple-table>
        <tr>
          <td class="pt-1">
            <fan-excitement-title />
          </td>
          <td
            class="text-center font-weight-bold pt-1"
            :class="
              hasThuuz
                ? thuuzColor(game.thuuz_game.gex_default) + '--text'
                : ''
            "
          >
            {{ hasThuuz ? game.thuuz_game.gex_default : '-' }}
          </td>
        </tr>
        <tr>
          <td>WIN PROBABILITY</td>
          <td class="text-center">
            <img
              class="d-print-none"
              style="height: 30px; max-width: 45px; vertical-align: middle"
              v-if="this.winProbAway != this.winProbHome"
              :src="logoSource"
            />
            <div style="vertical-align: middle; display: inline">
              {{ hasWinProb ? winProb : "-" }}
            </div>
          </td>
        </tr>
      </v-simple-table>
    </v-col>
  </v-row>
  <v-row no-gutters v-else :id="'game-hype-' + game.id">
    <v-col>
      <fan-excitement-title />
      <v-row class="pt-5">
        <v-col class="text-center">
          <bordered-avatar
            :color="
              hasThuuz ? thuuzColor(game.thuuz_game.gex_default) : 'gray'
            "
            text-color="black"
            :value="hasThuuz ? game.thuuz_game.gex_default : '-'"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" class="mx-4 contain-paint">
      <svg width="2" height="150" xmlns="http://www.w3.org/2000/svg">
        <line
          stroke-width="4"
          stroke-dasharray="10,10"
          stroke-linecap="undefined"
          stroke-linejoin="undefined"
          y2="150"
          x2="2"
          y1="2"
          x1="2"
          stroke="#cccccc"
          fill="none"
        />
      </svg>
    </v-col>
    <v-col>
      WIN PROBABILITY
      <v-row class="pt-5" no-gutters align="center">
        <v-col class="text-center" v-if="logoSource">
          <v-img
            class="mx-auto d-print-none  contain-paint"
            contain
            height="54"
            max-width="70"
            v-if="winProbAway != winProbHome"
            :src="logoSource"
            :alt="game.away_team.name + ' Logo'"
          />
        </v-col>
        <v-col class="text-center">
          <bordered-avatar
            :color="hasWinProb ? 'primary' : 'gray'"
            :value="hasWinProb ? winProb : '-'"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FanExcitementTitle from "./Hype/FanExcitementTitle";
import BorderedAvatar from "../../../shared/BorderedAvatar";

export default {
  components: { FanExcitementTitle, BorderedAvatar },
  name: "Hype",
  props: {
    game: Object,
  },
  computed: {
    winProbAway() {
      return Math.round(this.game.win_prob_away * 100);
    },
    winProbHome() {
      return Math.round(this.game.win_prob_home * 100);
    },
    hasWinProb() {
      return !!this.game.win_prob_away && !!this.game.win_prob_home;
    },
    hasThuuz() {
      return !!this.game.thuuz_game;
    },
    winProb() {
      if (!this.hasWinProb) return null;

      if (this.winProbAway > this.winProbHome) {
        return this.winProbAway + "%";
      }
      if (this.winProbAway < this.winProbHome) {
        return this.winProbHome + "%";
      }
      return "50%";
    },
    logoSource() {
      if (this.winProbAway > this.winProbHome) {
        var logo = this.game.away_team.master_team_id
          ? this.game.away_team.master_team_id
          : this.game.away_team_id;
        return this.appUrl + "/img/logos/teams/" + logo + ".svg";
      }
      if (this.winProbAway < this.winProbHome) {
        var logo = this.game.home_team.master_team_id
          ? this.game.home_team.master_team_id
          : this.game.home_team_id;
        return this.appUrl + "/img/logos/teams/" + logo + ".svg";
      }
      return null;
    },
  },
};
</script>
<style scoped>
</style>
