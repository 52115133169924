<template>
  <div></div>
</template>

<script>
export default {
  name: "Help",
  data: () => ({
    skipIntro: false,
    skipBasics: false,
  }),
  computed: {
    groups() {
      return this.$store.getters.getGroups;
    },
  },
  methods: {
    startTour() {
      let self = this;
      this.$nextTick(() => {
        const tour = this.$shepherd({
          defaultStepOptions: {
            modalOverlayOpeningRadius: 4
          },
          useModalOverlay: true,
        });


        if (!this.skipIntro) {
          tour.addStep({
            title: "Welcome to the DIRECTV Sports Planner",
            text: "This tool is provided as a service for commercial DIRECTV customers to help plan live sports broadcasts on multiple screens. This tour will guide you through customizing your schedules.",
            buttons: [
              {
                text: "Cancel",
                action: tour.complete,
                secondary: true,
              },
              {
                text: "Next",
                action: tour.next,
              },
            ],
          });
          this.skipIntro = true;
        }

        if (!this.skipBasics) {
          tour.addStep({
            attachTo: { element: "#help_columns", on: "top" },
            title: "Columns",
            text: "Adjusting the columns slider will show more or fewer screens per row, which can be helpful when coordinating broadcasts.",
            buttons: [
                {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
              {
                text: "Cancel",
                action: tour.complete,
                secondary: true,
              },
              {
                text: "Next",
                action: tour.next,
              },
            ],
          });
          this.skipBasics = true;
        }

        if (!this.groups[0].screens.length) {
          tour.addStep({
            attachTo: { element: "#help_add_screen", on: "bottom" },
            title: "Add a screen",
            text:
              "You have not created a screen yet. Please do that by clicking here. Once you have added a screen, please press Help again to continue.",
            buttons: [
                {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
              {
                text: "Ok",
                action: tour.complete,
              },
            ],
          });
        }

        tour.addStep({
          attachTo: { element: "#help_date", on: "bottom" },
          title: "Date",
          text:
            "Here is the current date for events. This will change all of the screens at once making it easier to compare daily schedules.",
          buttons: [
              {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: "#help_group_name", on: "top" },
          title: "Location Name",
          text:
            "Here is the name of the location. You can organize your locations in any way that makes sense for your business. (Ex: Bar Area, Second Floor, Basketball, etc.)",
          buttons: [
              {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: "#help_group_config", on: "top" },
          title: "Configure Location",
          text:
            "Clicking the gear icon will allow you to add filters to your location. These filters will apply to all screens in the location, unless you apply a custom filter to the screen, which will override it.",
          buttons: [
              {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: "#help_screen_name", on: "top" },
          title: "Screen Name",
          text:
            "Here is the name of the screen. (Ex: Foyer, Dining Room Top Left, TV 19, etc.).",
          buttons: [
              {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: "#help_screen_config", on: "top" },
          title: "Configure Screen",
          text:
            "Clicking the gear icon will allow you to add filters to your screen. These filters will override any on the group.",
          buttons: [
              {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: "#help_views", on: "top" },
          title: "Display Options",
          text: `<strong>Scheduled</strong>, <strong>All</strong>, and <strong>Suggested</strong>, allow you to change the view of your schedule.`,
          buttons: [
              {
                text: "Prev",
                action: tour.back,
                secondary: true,
              },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: "#help_views_scheduled", on: "top" },
          title: "Scheduled",
          text: `<strong>Scheduled</strong> will show you everything you have scheduled for the day. This screen is empty until you add some events to your screen.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "scheduled");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_views_all", on: "top" },
          title: "All",
          text: `<strong>All</strong> shows you all of the events for the day available to you.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "all");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_views_suggested", on: "top" },
          title: "Suggested",
          text: `and <strong>Suggested</strong> shows an educated guess, based on your filters, of which events you may want to schedule for that day.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "suggested");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_overlap", on: "top" },
          title: "Overlap",
          text: `The overlap control toggles whether multiple events can overlap in the display, which can help when many events are available at once.
          <br>
          <i class="v-icon notranslate mdi mdi-arrange-send-backward theme--light"></i> will allow overlapping.
          <br>
          <i class="v-icon notranslate mdi mdi-view-column theme--light"></i> will turn overlapping off.
          `,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "all");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_overlap_on", on: "top" },
          title: "Overlap On",
          text: `Currently the events are allowed to overlap. When using the <strong>All</strong> view, this can make it hard to see what is available.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "overlap_on");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_overlap_off", on: "top" },
          title: "Overlap Off",
          text: `When overlapping is turned off, you can see everything available, and by hovering the mouse over an event (without clicking), you can see the full details.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "all");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: ".v-calendar", on: "top" },
          title: "Calendar",
          text: `The calendar shows the events for the day. The start and ending hours can be updated in your settings.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "suggested");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: ".v-event-timed", on: "bottom" },
          title: "Event",
          text: `Sometimes events may overlap. Hovering your mouse over an event (without clicking) will show the full details.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "suggested");
              resolve();
            });
          },
        });


        tour.addStep({
          attachTo: { element: ".v-event-timed", on: "bottom" },
          title: "Event",
          text: `Scheduling an event is easy. Simply click it once. Like so...`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "suggested");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_event_icon", on: "bottom" },
          title: "Event",
          text: `The event will turn bright green and show a <i class="v-icon notranslate mdi mdi-check theme--light"></i>, a single check mark. This means the event is scheduled.
          <br>
          <div class="v-application">
          <div class="v-card v-sheet theme--light success white--text pa-2">
           <i class="v-icon notranslate mdi mdi-check theme--light white--text" style="font-size: 16px;"></i>
            Tennis (Pro)
            <br>
            Center Court: Barcelona-ATP, Belgrade-ATP, Stuttgart-WTA &amp; Istanbul-WTA Early Rounds
            <br>
            2:00am-1:00pm
          </div>
          </div>
          `,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "favorite_event");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_event_icon", on: "bottom" },
          title: "Event",
          text: `Two checkboxes <i class="v-icon notranslate mdi mdi-check-all theme--light"></i> means the event is preferred over other overlapping events.
          <Br>
          <div class="v-application">
          <div class="v-card v-sheet theme--light success white--text pa-2">
           <i class="v-icon notranslate mdi mdi-check-all theme--light white--text" style="font-size: 16px;"></i>
            Tennis (Pro)
            <br>
            Center Court: Barcelona-ATP, Belgrade-ATP, Stuttgart-WTA &amp; Istanbul-WTA Early Rounds
            <br>
            2:00am-1:00pm
          </div>
          </div>
          `,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "prefer_event");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_views_scheduled", on: "bottom" },
          title: "Event",
          text: `Once you have an event selected, the Scheduled view will show it.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          attachTo: { element: ".v-calendar", on: "bottom" },
          title: "Event",
          text: `This view is what will be printed as the guide for the day.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
          beforeShowPromise: function () {
            return new Promise(function (resolve) {
              self.$emit("action", "scheduled");
              resolve();
            });
          },
        });

        tour.addStep({
          attachTo: { element: "#help_print", on: "bottom" },
          title: "Print",
          text: `You can click print here to print your schedule for the day.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Cancel",
              action: tour.complete,
              secondary: true,
            },
            {
              text: "Next",
              action: tour.next,
            },
          ],
        });

        tour.addStep({
          title: "Thank you",
          text: `That is the end of the introductory tour. Please refresh your browser to clear any temporary changes the tour has made.`,
          buttons: [
            {
              text: "Prev",
              action: tour.back,
              secondary: true,
            },
            {
              text: "Reload",
              action: () => {
                  location.reload();
              },
            },
          ],
        });

        tour.start();
      });
    },
  },
};
</script>

<style>
.shepherd-element {
  border: 2px solid black;
}
.shepherd-element[data-popper-placement="bottom"] .shepherd-arrow::before {
  border-top: 2px solid black;
  border-left: 2px solid black;
}
.shepherd-element[data-popper-placement="top"] .shepherd-arrow::before {
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}
.shepherd-header {
  border-bottom: 2px solid black !important;
}
</style>
