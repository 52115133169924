<template>
    <v-chip
        v-if="game.thuuz_game"
        small
        :color="game.thuuz_game ? thuuzColor(game.thuuz_game.gex_default) : 'primary'"
    >
        Hype: {{game.thuuz_game.gex_default}}
    </v-chip>
</template>

<script>
export default {
    name: "HypeChip",
    props: {
        game: Object,
    },
    computed: {
        hasHype() {
            return !!this.game.thuuz_game;
        },
    },
};
</script>

<style scoped>

</style>
