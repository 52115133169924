<template>
    <div>
        <v-btn-toggle
            :dense="$vuetify.breakpoint.xs"
            :value="selectedOptions"
            @change="updated"
            color="blue"
            rounded
            mandatory
            class=" elevation-1"

        >
            <v-btn :value="0" class="flex-grow-1 flex-lg-grow-0">
                Detailed
                <v-icon> mdi-view-grid </v-icon>
            </v-btn>

            <v-btn :value="1" class="flex-grow-1 flex-lg-grow-0">
                Compact
                <v-icon> mdi-arrow-collapse-vertical </v-icon>
            </v-btn>
            <v-btn :value="2" class="flex-grow-1 flex-lg-grow-0">
                Simple
                <v-icon> mdi-land-rows-horizontal </v-icon>
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
import { POST_SELECTED_OPTION } from "../../../store/actions.type";

export default {
    name: "Toggle",
    props: {
        option: Object
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        selectedOptions() {
            return this.$store.getters.getCurrentSelectedOptions(this.option.key);
        },
    },
    methods: {
        updated(options) {
            let key = this.option.key;
            // this.$gtag.event("api", {
            //     event_category: "options",
            //     event_label: "post_selected_option",
            //     value: options
            // });
            this.$store
                .dispatch(POST_SELECTED_OPTION, { key, options })
                .then(() => {
                    this.loading = false;

                });
        }
    }
};
</script>
