<template>
  <v-card>
    <v-card-title class="headline">
      What is the name of your new screen?
    </v-card-title>
    <v-card-text>
      <v-text-field 
        label="Screen Name*" 
        required 
        v-model="name" 
        :error="errors.length"
        :error-messages="errors && errors.name ? errors.name : null"
    />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('done')">
        Close
      </v-btn>
      <v-btn color="blue darken-1" text @click="addScreen" :disabled="!name">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { POST_SCREEN } from "../../store/actions.type";

export default {
  name: "AddScreenModal",
  data() {
    return {
      name: null,
    };
  },
  props: {
      group: {
          type: Object,
          default: null
      }
  },
  computed: {
      errors() {
          return this.$store.getters.getErrors('screens');
      }
  },
  methods: {
      addScreen() {
          this.$store
            .dispatch(POST_SCREEN, { 
                name: this.name,
                group_id: this.group.id
            } )
            .then(() => this.$emit("done"));
          this.name = null;
      }
  }
};
</script>