
<template>
    <div
        class="lightGray"
        :class="isMobile ? ' fill-height' : ''"
    >
        <sport-bar v-if="!isMobile">
            <v-col class="text-center sport-header font-weight-bold mt-4">
                {{ leagueNameDisplay }}
                <template v-if="hasTeam">
                    - {{ teamNameDisplay.toUpperCase() }}
                </template>
                SCHEDULE
            </v-col>
        </sport-bar>
        <logo-bar v-else />
        <v-main
            class="no-print-padding"
            :class="isMobile ? 'white' : ''"
        >
            <v-container
                :class="isMobile ? 'px-0' : ''"
                style="max-width: 1360px"
            >
                <v-row
                    v-if="isMobile"
                    justify="center"
                    align="center"
                >
                    <v-col cols="auto">
                        <v-img
                            class="contain-paint"
                            contain
                            :src="`${appUrl}/img/logos/leagues/${$route.params.league.toLowerCase()}.svg`"
                            width="80"
                            height="80"
                            :alt="$route.params.league.toUpperCase() + ' Logo'"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <h2 class="text-center">
                            {{ leagueNameDisplay }}
                            <template v-if="$route.params.team && $route.params.team !== '0'">
                                - {{ teamNameDisplay.toUpperCase() }}
                            </template>
                            SCHEDULE
                        </h2>
                    </v-col>
                </v-row>

                <router-view />

            </v-container>
        </v-main>

    </div>
</template>

<script>
import SportBar from "../Home/Filters/SportBar";
import LogoBar from "../Home/LogoBar";

export default {
    name: "Base",

    components: {
        SportBar,
        LogoBar,
    },
    data() {
        return {
        };
    },
    computed: {
        leagueNameDisplay() {
            //If the league param contains NCAA, put a space after NCAA
            let league = this.$route.params.league;

            if (league.includes("NCAA")) {
                league = league.replace("NCAA", "NCAA ");
            }

            return league.toUpperCase();
        },
        teamNameDisplay() {
            //Get team name from parameter
            var team = this.$route.params.team;

            // Remove dashes and numbers
            team = team.replace(/-/g, " ").replace(/\d+$/, "");

            //Force Title Case
            team = this.toTitleCase(team);

            return team;
        },

        hasTeam() {
            return this.$route.params.team && this.$route.params.team !== "0";
        },
    },
};
</script>


<style scoped>
.sport-header {
    font-size: 1.6rem;
    letter-spacing: 0.02em;
}

@media print {
    .no-print-padding {
        padding: 0 !important;
    }
}

.gradient-background-flipped {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(244, 244, 244, 1) 70px,
            rgba(244, 244, 244, 1) 100%);
}
</style>
