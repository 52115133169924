<template>
  <v-row no-gutters style="flex-wrap: nowrap;" :class="option.print ? '' : 'd-print-none'">
    <v-col class="flex-grow-1 flex-shrink-1">
        <component :is="option.component" :option="option" />
    </v-col>
    <v-col v-if="option.configurable" class="flex-grow-0 flex-shrink-1">
        <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn class="ma-2" fab dark small color="blue" v-on="on" @click="dialog=true">
              <v-icon dark>{{ option.config_icon ? option.config_icon : 'mdi-wrench' }}</v-icon>
            </v-btn>
        </template>
        <span>{{ option.config_tooltip ? option.config_tooltip : 'Customize...' }}</span>
        </v-tooltip>
        <v-dialog v-model="dialog" scrollable max-width="600">
          <component :is="option.modal" @done="dialog = false" />
        </v-dialog>
    </v-col>
  </v-row>

</template>

<script>
import BusinessHours from "./Filters/BusinessHours";
import DateBar from "./Filters/DateBar";
import Dropdown from "./Filters/Dropdown";
import SportBar from "./Filters/SportBar";
import Textfield from "./Filters/Textfield";
import Zipcode from "./Filters/Zipcode";
import Toggle from "./Filters/Toggle";
import Addons from "./Filters/Addons";

import TeamsModal from "./Filters/TeamsModal";

export default {
  name: "Field",
  props: {
      option: Object
  },
  components: {
    BusinessHours,
    DateBar,
    Dropdown,
    SportBar,
    TeamsModal,
    Textfield,
    Toggle,
    Addons,
    Zipcode
  },
  data() {
    return {
      dialog: false
    }
  },
};
</script>
