<template>
  <v-card>
    <v-card-title class="headline">
      Are you sure you want to delete the `{{ group.name }}` location?
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('done')">
        Cancel
      </v-btn>
      <v-btn color="warning" text @click="deleteGroup">DELETE</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DELETE_GROUP } from "../../store/actions.type";

export default {
  name: "DeleteGroupModal",
  props: {
      group: {
          type: Object,
          default: null
      }
  },
  computed: {
      errors() {
          return this.$store.getters.getErrors('groups');
      }
  },
  methods: {
      deleteGroup() {
          this.$store
            .dispatch(DELETE_GROUP, this.group )
            .then(() => this.$emit("done"));
      }
  }
};
</script>
