<template>
    <div :id="'game-showings-' + game.id">
        <template>
            <h4
                v-if="showHeader && game.showings && game.showings.length > 0"
                class="font-weight-bold"
                :class="layout == 1 ? 'my-3' : ''"
            >AVAILABLE ON</h4>
            <template v-if="layout == 0 && !isMvp">
                <v-row
                    no-gutters
                    justify="center"
                >
                    <v-col
                        cols="auto"
                        v-for="(showing, showing_index) in game.showings"
                        :key="showing_index"
                        @click="liteChannel(showing_index)"
                    >
                        <channel :channel="showing.channel" />
                    </v-col>
                </v-row>
            </template>
            <v-row v-if="expanded">
                <v-col
                    cols="12"
                    class="pt-0 text-left mx-2 pr-6"
                    :class="!showHeader ? 'my-3' : ''"
                >
                    <channel-details :channel="currentLitChannelDetails" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import Channel from "./Channel";
import ChannelDetails from "./ChannelDetails";

export default {
    name: "Showings",
    components: {
        Channel,
        ChannelDetails,
    },
    props: {
        game: Object,
    },
    data() {
        return {
            currentLitChannel: 0,
            expanded: false,
        };
    },
    computed: {
        showings() {
            return this.game.showings;
        },
        currentLitChannelDetails() {
            return this.showings[this.currentLitChannel].channel;
        },
        layout() {
            return this.$store.getters.getCurrentSelectedOptions("layout");
        },
        showHeader() {
            if (this.isMvp) return false;

            return (this.layout == 0) || (this.layout == 1 && this.expanded);
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
    },
    methods: {
        liteChannel(index) {
            if (this.currentLitChannel == index && this.expanded) {
                this.currentLitChannel = null;
                this.expanded = false;
                return false;
            } else {

                // console.log("game", this.game);
                const packageListForEvent = this.game.showings[index].channel.packages;
                // console.log("liteChannel", packageListForEvent);

                const eventData = [];

                packageListForEvent.forEach((channelPackage, index) => {
                    if (this.isCommercial && channelPackage.commercial) {
                        eventData.push({
                            'item_id': channelPackage.id,
                            'item_name': channelPackage.tag_title,
                            'index': index,
                            'price': 0,
                            'quantity': 1,
                        });
                    }
                    if (!this.isCommercial && channelPackage.residential) {
                        eventData.push({
                            'item_id': channelPackage.id,
                            'item_name': channelPackage.tag_title,
                            'index': index,
                            'price': 0,
                            'quantity': 1,
                        });
                    }


                });

                // console.log("eventData", eventData);

                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        'items': eventData,
                    },
                });


            this.currentLitChannel = index;
            this.expanded = true;
            return true;
        }
    },
},
};
</script>
