<template>
  <v-select
    class="input-field"
    :class="!isMobile ? 'desktop-only' : ''"
    hide-details="auto"
    :label="!isMobile ? option.name : ''"
    :items="options"
    outlined
    dense
    rounded
    :block="isEmbed"
    :multiple="option.multiple"
    @change="updated"
    :loading="loading"
    :value="selectedOptions"
  >
    <template v-slot:item='{item}'>
      <div v-if="item.type" class="text-uppercase" v-html='`${item.type}: ${item.text}`'/>
      <div v-else class="text-uppercase" v-html='`${item.text}`'/>
    </template>
    <template v-slot:selection='{item}'>
      <div v-if="item.type" class="text-uppercase" v-html='`${item.type}: ${item.text}`'/>
      <div v-else class="text-uppercase" v-html='`${item.text}`'/>
    </template>
  </v-select>
</template>

<script>
import { POST_SELECTED_OPTION } from "../../../store/actions.type";

export default {
  name: "SmallDropdown",
  props: {
    option: Object
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    selectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
    allOptionsSelected() {
      return this.option.options.length === this.selectedOptions.length;
    },
    someOptionsSelected() {
      return this.selectedOptions.length > 0 && !this.allOptionsSelected;
    },
    icon() {
      if (this.allOptionsSelected) return "mdi-close-box";
      if (this.someOptionsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    options() {
      let options = this.option.options;
      if (this.isEmbed || this.isMvp) {
        options = options.filter(option => option.value != 99);
      }
      return options;
    }
  },
  methods: {
    updated(options) {
      this.loading = true;
      let key = this.option.key;

    //   this.$gtag.event("api", {
    //     event_category: "options",
    //     event_label: "post_selected_option",
    //     value: options
    //   });

      this.$store.dispatch(POST_SELECTED_OPTION, { key, options })
      .then(() => {
        this.$emit("done");
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.desktop-only {
  flex-grow:unset;
  width: unset;
}
::v-deep .v-input__control {
  background:white;
}
.v-text-field--outlined >>> .v-select__selection {
  /*color: #196DD4;*/
  text-transform: uppercase;
}

.v-text-field--outlined >>> .v-select__selections {
  /*color: #196DD4;*/
  text-transform: uppercase;
}

.v-text-field--outlined >>> .v-label {
  /*color: #196DD4;*/

}

.v-text-field--outlined >>> fieldset {
  /*border-color: #196DD4;*/

}
</style>
