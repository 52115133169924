<template>
    <v-row class="mt-10 mb-16">
        <v-col class="text-center">
            <h2>Loading...</h2>
            <v-progress-circular
            :size="70"
            :width="7"
            color="blue"
            indeterminate
            ></v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Loading",
}
</script>