<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <component
                :is="$route.params.league.toUpperCase()"
                :league="league"
            />
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_PLAYERS, GET_TEAM_PLAYERS } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

import NFL from "../../components/Leagues/Players/NFL";
import NHL from "../../components/Leagues/Players/NHL";
import NBA from "../../components/Leagues/Players/NBA";
import MLB from "../../components/Leagues/Players/MLB";
import MLS from "../../components/Leagues/Players/MLS";

export default {
    name: "Players",
    components: {
        Loading,
        NFL,
        NHL,
        NBA,
        MLB,
        MLS,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        isTeamView() {
            return this.$route.params.team && this.$route.params.team !== '0';
        },
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
    },
    mounted() {
        if (this.isTeamView) {
            this.$store.dispatch(GET_TEAM_PLAYERS, {
                league: this.$route.params.league,
                team: this.$route.params.team
            }).then(() => {
                this.loading = false;
            });
        } else {
            this.$store.dispatch(GET_LEAGUE_PLAYERS, this.$route.params.league).then(() => {
                this.loading = false;
            });
        }

    },
};
</script>
