import Vue from 'vue';
import Vuex from 'vuex';

import error from './error.module';
import auth from './auth.module';
//import showing from './showing.module';
import game from './game.module';
import option from './option.module';
import league from './league.module';
import addons from './addons.module';
import screens from './screens.module';
import team from './team.module';
import stats from './stats.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        error,
        auth,
        //showing,
        game,
        option,
        league,
        addons,
        screens,
        team,
        stats
    }
});
