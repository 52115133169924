<template>
  <v-card>
    <v-card-title class="justify-space-between font-weight-black">
      CREATE ACCOUNT
      <v-btn icon @click="close"><v-icon>fas fa-times</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle
      v-if="error"
      :class="error ? 'red--text text-center' : 'text-center'"
      >{{ error }}</v-card-subtitle
    >

    <v-card-text>
      <v-form v-model="isFormValid" ref="registerform">
        <v-text-field
          ref="name"
          label="Name"
          name="name"
          v-model="name"
          :rules="rules.name"
          :success="!!name"
          type="text"
          @keyup.enter="$refs.last_name.focus()"
          validate-on-blur
        />

        <v-text-field
          ref="email"
          label="Email"
          name="email"
          v-model="email"
          type="email"
          :error="errors && !!errors.email"
          :error-messages="errors && errors.email ? errors.email : null"
          @focus="clearErrors()"
          @keyup.enter="$refs.password.focus()"
          :rules="rules.email"
        />

        <v-text-field
          ref="password"
          id="password"
          persistent-hint
          :hint="
            passwordStrength.strength >= 100
              ? 'Requirements met.'
              : 'Min. 8 chars. with 1 uppercase, lowercase, number, special character.'
          "
          label="Password"
          name="password"
          v-model="password"
          type="password"
          :rules="[
            passwordStrength.strength >= 100 ||
              'Min. 8 chars. with 1 uppercase, lowercase, number, special character.',
          ]"
          :success="passwordStrength.strength >= 100"
          :error="!!errors && !!errors.password"
          :error-messages="!!errors && errors.password ? errors.password : null"
          @focus="clearErrors()"
          @keyup.enter="$refs.password_confirmation.focus()"
        >
        </v-text-field>

        <!-- disabled Password Strength messages until I come up with a better UX solution -->

        <!-- <v-tooltip color="white" v-model="passwordToolTip" bottom>
          <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  ref="password"
                  id="password"
                  label="Password"
                  name="password"
                  v-model="password"
                  type="password"
                  :rules="[rules.password, passwordStrength.strength >= 100]"
                  :success="passwordStrength.strength >= 100"
                  :error="!!errors && !!errors.password"
                  :error-messages="!!errors && errors.password ? errors.password : null"
                  @focus="clearErrors()"
                  @keyup.enter="$refs.password_confirmation.focus();"
                > 
                  <template v-slot:progress="{ on, attrs}">
                    <v-progress-linear
                      v-bind="attrs"
                      v-on="on"
                      :color="passwordStrengthColor" :value="passwordStrength.strength"></v-progress-linear>
                  </template>
                </v-text-field>
          </template>
          <span>
            <v-list  color="transparent">
              <v-list-item  v-for="(test, idx) in sortedPasswordTests" :key="idx">
                <v-list-item-icon>
                  <v-icon :color="test.passed ? 'seaGreen': 'red'">{{test.passed ? 'fas fa-check-square' : 'fas fa-times'}}</v-icon>
                  </v-list-item-icon>
                <v-list-item-title class="font-weight-bold" :color="test.passed ? 'seaGreen': 'red'">{{test.message}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </span>
        </v-tooltip>

        <v-progress-linear :color="passwordStrengthColor" :value="passwordStrength.strength"></v-progress-linear> -->

        <v-text-field
          ref="password_confirmation"
          id="password_confirmation"
          label="Confirm Password"
          name="password_confirmation"
          v-model="password_confirmation"
          type="password"
          :rules="[doPasswordsMatch || 'Must match first password.']"
          :success="!!password.length && !!doPasswordsMatch"
          :error="!doPasswordsMatch"
          @focus="clearErrors()"
          @keyup.enter="signUp()"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click.native="display('login')" text>BACK TO LOGIN</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!isFormValid"
        :loading="loading"
        @click.native="signUp"
      >
        SIGN UP
      </v-btn>
    </v-card-actions>
    <!--
    <v-card-subtitle class="text-center">
      <div>By clicking SIGN UP, you agree to DirecTV's</div>
      <v-btn
        dense
        text
        class="text-underline text-none px-1"
        target="_blank"
        href="/privacy"
        >Privacy Policy</v-btn
      >
      and
      <v-btn
        dense
        text
        class="text-underline text-none px-1"
        target="_blank"
        href="/terms"
        >Terms</v-btn
      >
    </v-card-subtitle>
    -->
  </v-card>
</template>

<script>
import { REGISTER, TOGGLE_AUTH_DIALOG } from "../../store/actions.type";
import { CLEAR_ERRORS, SET_CURRENT_FORM } from "../../store/mutations.type";

export default {
  name: "RegisterForm",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      remember: true,
      loading: false,
      passwordToolTip: false,
      isFormValid: false,

      // Validation Rules
      rules: {
        name: [(v) => !!v || "Name is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Must be a valid email address",
          (v) => true || "Email already associated with another account.",
        ],
        password: (v) =>
          !!v ||
          "Min. 8 chars. with 1 uppercase, lowercase, number, special character.",
      },
    };
  },
  watch: {
    passwordStrength(val) {
      if (val.strength >= 100) {
        this.passwordToolTip = false;
      } else {
        this.passwordToolTip = true;
      }
    },
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("register");
    },
    errors() {
      return this.$store.getters.getErrors("register");
    },
    // Field specific errors
    emailErrors() {
      return this.errors.email && this.errors.email.length > 0;
    },
    passwordErrors() {
      return this.errors.password && this.errors.password.length > 0;
    },
    passwordStrength() {
      return this.checkPasswordStrength(this.password);
    },
    sortedPasswordTests() {
      return this.passwordStrength.tests.sort((currentTest, nextTest) =>
        currentTest.passed === nextTest.passed ? 0 : currentTest.passed ? 1 : -1
      );
    },
    passwordStrengthColor() {
      const { strength } = this.passwordStrength;
      if (strength >= 100) {
        return "seaGreen";
      } else if (strength > 50) {
        return "info";
      } else if (strength > 25) {
        return "gold";
      } else return "red";
    },
    doPasswordsMatch() {
      return this.password === this.password_confirmation;
    },
  },
  methods: {
    signUp() {
      this.loading = true;
      let name = this.name;
      let email = this.email;
      let password = this.password;
      let password_confirmation = this.password_confirmation;
      let remember = this.remember;
      this.$store
        .dispatch(REGISTER, {
          name,
          email,
          password,
          password_confirmation,
          remember,
        })
        .then(() => {
          this.loading = false;
          //window.location.reload();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearErrors: function () {
      this.$store.commit(CLEAR_ERRORS);
    },
    display(form) {
      this.$store.commit(SET_CURRENT_FORM, form);
    },
    close() {
      this.$store.dispatch(TOGGLE_AUTH_DIALOG);
    }
  },
};
</script>

<style>
</style>