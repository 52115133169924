import Vue from 'vue';

export default function afterEachHook(to, from, next) {
    console.log("Router after each");
    let new_title = "";
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {

        const DEFAULT_TITLE = 'Home';
        const TITLE_POSTFIX = ' | DIRECTV';

        if (to.meta.title) {
            switch (to.meta.title) {
                case "Schedule":
                    new_title = to.params.league.toUpperCase();
                    if (to.params.team && to.params.team.length > 1) {
                        let team_name_parts = to.params.team.split("-");
                        let new_team_name = team_name_parts[0];

                        for (let i = 1; i < team_name_parts.length - 1; i++) {
                            new_team_name += " " + team_name_parts[i];
                        }
                        new_title = new_team_name;
                    }
                    new_title += " Schedule & Channels";
                    break;
                case "Events":
                    let events_postfix = " Games & Times";

                    //Custom Only
                    if (
                        to.params.sport && to.params.sport.length > 1 && to.params.sport == "custom"
                        ||
                        to.params.league && to.params.league.length > 1 && to.params.league == "custom") {
                        new_title = "Custom Schedule";
                        break;
                    }

                    //Has League
                    if (to.params.league && to.params.league.length > 1) {
                        new_title += ((!~['all'].indexOf(to.params.league)) ? (to.params.league.toUpperCase() + " ") : "");
                    }
                    //Always on Events
                    new_title += capitalizeFirstLetter(to.params.sport);



                    new_title += events_postfix;
                    // if (to.params.team && to.params.team.length > 1) {
                    //     let team_name_parts = to.params.team.split("-");
                    //     let new_team_name = team_name_parts[0];

                    //     for (let i = 1; i < team_name_parts.length-1; i++) {
                    //         new_team_name += " " + team_name_parts[i];
                    //     }
                    //     new_title += new_team_name;
                    // }

                    // new_title += " " + to.meta.title;



                    break;
                default:
                    if (to.params.league && to.params.league.length > 1) {
                        new_title += ((!~['all', 'custom'].indexOf(to.params.league)) ? to.params.league.toUpperCase() : capitalizeFirstLetter(to.params.league));
                        new_title += ((!!~['all', 'custom'].indexOf(to.params.league)) ? " Leagues" : "");
                        new_title += " ";
                    }

                    if (to.params.team && to.params.team.length > 1) {
                        let team_name_parts = to.params.team.split("-");
                        let new_team_name = team_name_parts[0];

                        for (let i = 1; i < team_name_parts.length - 1; i++) {
                            new_team_name += " " + team_name_parts[i];
                        }
                        new_title += new_team_name;
                        new_title += " ";
                    }

                    new_title += to.meta.title;
                    break;
            }

        } else {
            new_title = DEFAULT_TITLE;
        }

        document.title = new_title + TITLE_POSTFIX;
        document.querySelector('meta[name="og:title"]').setAttribute("content", new_title + TITLE_POSTFIX);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", new_title + TITLE_POSTFIX);

        let description = "Sports Schedules for NFL, Soccer, NHL, NBA, World Cup, MLB, Cricket, MLS, NCAA, All Sports";

        if (to.params.league && to.params.league.length > 1) {
            let sport = to.params.sport ? to.params.sport.toUpperCase() : '';
            let league = to.params.league.toUpperCase();


            switch (league) {
                case "ALL":
                    description = `Find ${sport} TV channel and networks on DIRECTV to watch your favorite teams play.`;
                    break;
                case "NHL":
                    description = "Get NHL TV schedules and find what channel you need to watch your team live, on DIRECTV. Don't miss your favorite hockey team in action!";

                    switch (to.name) {
                        case "teams":
                            description = "Find every National Hockey League team here and get the latest NHL team rosters updated daily.";
                            break;
                        case "standings":
                            description = "Get NHL division and team standings daily . Find wins and losses for all NHL teams.";
                            break;
                        case "players":
                        case "team-players":
                            description = "Find every NHL player stats including birth date, height, weight, team, uniform number and more right here. Read on for more info!";
                            break;
                        case "coaches":
                            description = "Determine who is the head coach and the coaching staff for any National Hockey League team.";
                            break;
                        case "depth-charts":
                            description = "Find NHL depth charts and get your teams lineup of starting and secondary players, positions and backups.";
                            break;
                    }

                    break;
                case "MLB":
                    description = "Get Major League Baseball TV schedules and find which network has the game you want so you can  watch, live with DIRECTV.  Stream live baseball now.";

                    switch (to.name) {
                        case "teams":
                            description = "Find every Major League Baseball team here and get the latest MLB team rosters updated daily.";
                            break;
                        case "standings":
                            description = "Get MLB division and team standings daily . Find wins and losses for all MLB teams.";
                            break;
                        case "players":
                        case "team-players":
                            description = "Find every MLB player's stats including birth date, height, weight, team, uniform number injury status and more right here. Read on for more info!";
                            break;
                        case "coaches":
                            description = "Determine who is the head coach and who are the coaching staff for any Major League Baseball team.";
                            break;
                        case "depth-charts":
                            description = "Find MLB depth charts and get your teams lineup of starting and secondary players, positions and backups.";
                            break;
                    }

                    break;
                case "NBA":
                    description = "Get NBA TV schedules here. Find out  when & where you can watch your favorite team play live, with DIRECTV. Don't miss your favorite basketball team in action!";

                    switch (to.name) {
                        case "teams":
                            description = "Find every National Basketball Association team here and get the latest NBA team rosters, updated daily.";
                            break;
                        case "standings":
                            description = "Get NBA division and team standings daily . Find wins and losses for all NBA teams.";
                            break;
                        case "players":
                        case "team-players":
                            description = "Find every NBA player position and draft year. Read on for more info!";
                            break;
                        case "leaders":
                            description = "Find the top ten leaders for regular season play.";
                            break;
                        case "coaches":
                            description = "Determine who is the head coach and the coaching staff for any National Basketball Association team.";
                            break;
                        case "depth-charts":
                            description = "Find NBA depth charts and get your teams lineup of starting and secondary players, positions and backups.";
                            break;
                    }

                    break;
                case "NFL":
                    description = "Get NFL TV schedules and watch your favorite team in action live, with DIRECTV. Don't fumble and miss your favorite team in action!";

                    switch (to.name) {
                        case "teams":
                            description = "Find every National Football League team here and get the latest NFL team rosters, updated daily.";
                            break;
                        case "standings":
                            description = "Get NFL division and conference team standings daily . Find wins and losses for all NFL teams.";
                            break;
                        case "players":
                        case "team-players":
                            description = "Find NFL players, team name, position and draft year right here with DIRECTV.";
                            break;
                        case "leaders":
                            description = "Find the leaders in all things NFL, passing yards, rushing yard, interceptions, touchdowns and more. ";
                            break;
                        case "coaches":
                            description = "Determine the head coach and the coaching staff for any National Football League team.";
                            break;
                        case "depth-charts":
                            description = "View depth charts for every NFL team and position along with first and second string players.";
                            break;
                    }

                    break;
                case "MLS":
                    description = "Get MLS TV schedules and watch major league soccer now. Don't miss a goal or your favortie team take home the win!";

                    switch (to.name) {
                        case "teams":
                            description = "Find every Major League Soccer team here and get the latest MLS team rosters, and schedule updated daily.";
                            break;
                        case "standings":
                            description = "Get 2024 MLS team standings and win/loss record for every major league soccer team.";
                            break;
                        case "players":
                        case "team-players":
                            description = "Want to know about MLS players. Find MLS player height, weight, birth date, birth city, country postiion and more right here.";
                            break;
                        case "leaders":
                            description = "Get leaders for the number of goals made during the regular MLS season.";
                            break;
                        case "coaches":
                            description = "Find the head coach for each Major League Soccer team for the western and eastern conferences.";
                            break;
                    }

                    break;
                default:
                    description = `Find ${league} TV channel and networks on DIRECTV to watch your favorite ${sport} teams play.`;
            }
        }

        document.querySelector('meta[name="description"]').setAttribute("content", description);
        document.querySelector('meta[name="og:description"]').setAttribute("content", description);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", description);

        //Keywords
        if (to.params.team && to.params.team.length > 1) {
            document.querySelector('meta[name="keywords"]').setAttribute("content", to.params.team);
        } else if (to.params.league && to.params.league.length > 1) {
            if (to.params.sport === "all") {
                document.querySelector('meta[name="keywords"]').setAttribute("content", "all sports, all leagues");
            } else if (to.params.sport === "custom" || to.params.league === "custom") {
                document.querySelector('meta[name="keywords"]').setAttribute("content", "custom schedule");

            } else {
                let leagueToUse = to.params.league;
                if (leagueToUse === "all") {
                    leagueToUse = "all leagues";
                }
                if (to.meta.canonicalAddon && to.meta.canonicalAddon.length > 1) {
                    document.querySelector('meta[name="keywords"]').setAttribute("content", leagueToUse + " " + to.meta.canonicalAddon);
                } else {
                    document.querySelector('meta[name="keywords"]').setAttribute("content", to.params.sport + ", " + leagueToUse);
                }
            }
        }

    });
}
