<template>
  <v-col cols="auto" class="text-no-wrap bordered rounded py-0 px-2 mr-1">
    <span class="feed-text font-weight-bold">
      {{ channel.number_display }} -
      {{ channel.callsign_display }}
    </span>
    <span
      class="black-channel feed-chip mx-1"
      v-if="channel.feed"
    >
      {{ channel.feed }}
    </span>
  </v-col>
</template>

<script>
export default {
  name: "ChannelPrint",
  props: {
    channel: Object,
    index: Number,
    lit: Boolean,
  },
};
</script>

<style scoped>
.feed-text {
  font-size: 11px;
}
.feed-chip {
  padding-top: 2px;
  padding-left: 1px;
  padding-right: 1px;
  font-weight: bolder;
  font-size: 8px;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
}
.white-channel {
  border-color: white;
}
.black-channel {
  border-color: black;
}
.bordered {
  border: 1px solid black;
}
</style>