<template>
    <v-row no-gutters>
        <v-col class="text-center mx-5">
            <h2 class="red--text">Sorry, no events match your search criteria at this time.</h2>
            <p>
                Please try selecting different options at the top of this page.
            </p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "NoGames",
}
</script>