<template>
    <v-card>
        <v-card-title>
            Please select your county
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        class="input-field rounded-0"
                        block
                        hide-details="auto"
                        label="County"
                        :items="option.options"
                        outlined
                        @change="updated"
                        :loading="loading"
                        :value="selectedOptions"
                    >
                        <template v-slot:item='{ item }'>
                            <div v-html='item.city_name' />
                        </template>

                        <template v-slot:selection='{ item }'>
                            <div
                                class="pa-4"
                                v-html='item.city_name'
                            />
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { POST_SELECTED_OPTION, GET_GAMES } from "../../../store/actions.type";
import { SET_SELECTED_ADDONS, SET_COUNTY_DIALOG } from "../../../store/mutations.type";

export default {
  name: "County",
  data() {
    return {
      loading: false
    };
  },
  computed: {
    option() {
      return this.$store.getters.getSingleOption("county");
    },
    selectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
    allOptionsSelected() {
      return this.option.options.length === this.selectedOptions.length;
    },
    someOptionsSelected() {
      return this.selectedOptions.length > 0 && !this.allOptionsSelected;
    },
    icon() {
      if (this.allOptionsSelected) return "mdi-close-box";
      if (this.someOptionsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    updated(option) {
      this.loading = true;
      let key = this.option.key;
      let id = option.id;
      this.$store.dispatch(POST_SELECTED_OPTION, { key, options: id })
      .then(() => {
        this.loading = false;
        this.$store.commit(SET_COUNTY_DIALOG, false);
      });
    }
  }
};
</script>


<style scoped>
.v-text-field--outlined>>>.v-select__selection {
    /*color: #196DD4;*/
    font-size: 1.2em;
    text-transform: uppercase;
}

.v-text-field--outlined>>>.v-select__selections {
    /*color: #196DD4;*/
    font-size: 1.2em;
    text-transform: uppercase;
}

.v-text-field--outlined>>>.v-label {
    /*color: #196DD4;*/
    font-size: 1.5em;
    font-weight: bold;
}

.v-text-field--outlined>>>fieldset {
    /*border-color: #196DD4;*/
    border-width: 3px;
}
</style>
