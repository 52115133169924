<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat class="d-flex align-center" height="60">
          <div
            class="flex-grow-1 text-center"
          >Tip: Enter a ZIP Code to see your local channels and regional sports networks (RSNs).</div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Tip"
};
</script>