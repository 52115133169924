<template>
  <div
    class="fill-height"
    style="background-color: #FCFCFC;"
  >
    <loading v-if="loading"></loading>
    <v-main
      v-else
      style="background-color: #FCFCFC;"
      class="no-print-padding"
      :class="mainClass"
    >
      <v-container
        :class="isMobile ? 'px-0' : ''"
        class="py-0"
        style="max-width: 1360px"
      >
        <date-bar @done="resetPagination"/>
        <div :class="[
          isMobile ? 'mt-5' : '',
        ]">
          <v-row
            no-gutters
            align="center"
            :justify="'center'"
            class="px-0 px-sm-2 px-md-14 py-2 py-md-5 d-print-none"
          >
            <v-col v-if="!isMobile">
              <v-divider
                color="lightGrey"

              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <small-dropdown
                :option="groupOption"
                :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <small-dropdown
                :option="sportOption"
                @done="resetPagination"
                :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
              />
            </v-col>
            <v-col v-if="!isMobile">
              <v-divider
                color="lightGrey"
              />
            </v-col>
          </v-row>
          <games
            :games="games"
            :key="'home'"
          />
          <v-pagination
            :color="`#${embed.customization.primary}`"
            v-if="isEmbed"
            v-model="page"
            :length="paginationLength"
          />
        </div>
      </v-container>
    </v-main>
  </div>
</template>


<script>
import Loading from "../components/shared/Loading";
import DateBar from "../components/Home/Filters/DateBar";
import Filters from "../components/Home/Filters";
import Games from "../components/Home/Games";
import SmallDropdown from "../components/Home/Filters/SmallDropdown";

import {
  GET_OPTIONS,
  GET_SELECTED_OPTIONS,
  POST_SELECTED_OPTION,
  GET_EMBED_GAMES,
  POST_PARAM_OPTIONS,
  GET_EMBED
} from "../store/actions.type";

import { CLOSE_LEAGUES_MODAL } from "../store/mutations.type";


export default {
  name: "Embed",
  components: {
    Loading,
    DateBar,
    Filters,
    SmallDropdown,
    Games
  },
  data() {
    return {
      loading: true,
      dialog: false,
      banner: true,
      page: 1,
      paginationAmount: 5,
    };
  },
  computed: {
    storedGames() {
      return this.$store.getters.getSortedFilteredGames;
    },
    games() {
      if (this.isEmbed) {
        //Get paginationAmount of games from the games array based on the current page
        return this.storedGames.slice(
          (this.page - 1) * this.paginationAmount,
          this.page * this.paginationAmount
        );
      }
      return this.storedGames;
    },
    timezone() {
      return this.$store.getters.getCurrentSelectedOptions("timezone");
    },
    hasParams() {
      return this.$route.params.sport &&
        this.$route.params.league &&
        this.$route.params.date
        ? true
        : false;
    },
    groupOption() {
      return this.$store.getters.getSingleOption("groups");
    },
    sportOption() {
      return this.$store.getters.getSingleOption("sports");
    },
    layoutOption() {
      return this.$store.getters.getSingleOption("layout");
    },
    completedOption() {
      return this.$store.getters.getSingleOption("completed");
    },
    isFiltersMenuOpen() {
      return this.$store.getters.isFiltersMenuOpen;
    },
    isSportsMenuOpen() {
      return this.$store.getters.isSportsMenuOpen;
    },
    isAnyMenuOpen() {
      return this.isFiltersMenuOpen || this.isSportsMenuOpen;
    },
    isLeaguesModalOpen() {
      return this.$store.getters.isLeaguesModalOpen;
    },
    mainClass() {
      let finalClass = "";
      if (this.isAnyMenuOpen) finalClass = finalClass + " d-none";
      return finalClass;
    },
    paginationLength() {
      return Math.ceil(this.storedGames.length / this.paginationAmount);
    },
    embed() {
      return this.$store.getters.getCurrentEmbed;
    },
  },
  methods: {
    print() {
      window.print();
    },
    doneLeaguesModal() {
      this.loading = false;
      this.$store.commit(CLOSE_LEAGUES_MODAL);
    },
    resetPagination() {
      this.page = 1;
    }
  },
  async mounted() {

    let groupuid = this.$route.params.groupuid;

    await this.$store.dispatch(GET_EMBED, groupuid);

    this.$store
      .dispatch(GET_OPTIONS)
      .then(() => {
        // this.$gtag.event("api", {
        //   event_category: "options",
        //   event_label: "get_selected_options",
        // });
        this.$store.dispatch(GET_SELECTED_OPTIONS)
      })
      .then(() => {
          let key = "groups";
          let options = 3;
          this.$store.dispatch(POST_SELECTED_OPTION, { key, options });
      })
      .then(() => {

        this.$store
          .dispatch(GET_EMBED_GAMES, this.$route.params.groupuid)
          .then(() => {
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
      });
  },
};
</script>

<style scoped>@media print {
  .no-print-padding {
    padding: 0 !important;
  }
}

.gradient-background-flipped {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(244, 244, 244, 1) 70px,
      rgba(244, 244, 244, 1) 100%);
}</style>
