<template>
    <div>
        <v-breadcrumbs
            :large="$vuetify.breakpoint.mdAndUp"
            :items="crumbs"
        >
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                    :href="item.href"
                    :disabled="item.disabled"
                >
                    <template>
                        {{ item.text }}
                    </template>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
    </div>
</template>


<script>
export default {
    name: "Breadcrumbs",
    props: {
        custom: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        crumbs() {
            if (this.custom.length) return this.custom;
            let appUrl = this.appUrl;
            let crumbs = [
                {
                    text: "HOME",
                    href: appUrl,
                    disabled: false,
                    icon: "mdi-home",
                },
            ];

            if (this.$route.params.league) {
                crumbs.push({
                    text: this.leagueNameDisplay,
                    href: `${appUrl}/leagues/${this.$route.params.league}/schedule`,
                });
            }

            if (this.$route.params.team) {
                crumbs.push({
                    text: this.teamNameDisplay,
                    href: `${appUrl}/leagues/${this.$route.params.league}/teams/${this.$route.params.team}/schedule`,
                });
            }

            return crumbs;
        },
        leagueNameDisplay() {
            //If the league param contains NCAA, put a space after NCAA
            let league = this.$route.params.league;

            if (league.includes("NCAA")) {
                league = league.replace("NCAA", "NCAA ");
            }

            return league.toUpperCase();
        },
        teamNameDisplay() {
            //Get team name from parameter
            var team = this.$route.params.team;

            // Remove dashes and numbers
            team = team.replace(/-/g, " ").replace(/\d+$/, "");

            //Force Title Case
            team = team.toUpperCase();

            return team;
        },
    },
};
</script>
