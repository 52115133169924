<template>
    <v-form v-model="formValid">
    <v-card>
        <v-card-title class="justify-space-between font-weight-black">
            Forgot Password
            <v-btn
                icon
                @click="close"
            ><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
            <v-text-field
                v-model="inputData"
                label="Email"
                :rules="emailRules"
            />
        </v-card-text>
        <v-card-actions class="mx-5 my-4 pa-0 d-flex justify-center">
            <v-btn
                :class="buttonColor"
                :disabled="!formValid || loading"
                :loading="loading"
                color="primary"
                depressed
                @click="validEmailCheck"
            >
                Send Password Reset Link
            </v-btn>
        </v-card-actions>
        <v-card-text
            v-if="!loading && foundEmailFlag && clickedSubmit"
            class="success--text"
        >
            Email successfully sent!
        </v-card-text>
        <v-card-text
            v-else-if="!loading && !foundEmailFlag && clickedSubmit"
            class="error--text"
        >
            Email was not found in database
        </v-card-text>
        <v-card-text
            class="py-5"
            v-else
        />

    </v-card>
</v-form>
</template>


<script>
import { EMAIL_PASSWORD_RESET } from '../../store/actions.type'

export default {
    name: "ForgotPasswordModal",
    data() {
        return {
            formValid: false,
            inputData: "",
            clickedSubmit: false,
            foundEmailFlag: false,
            loading: false,
            emailRules: [
                (v) => !!v || "ex: someone@example.com",
                (v) =>
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || "ex: someone@example.com",
            ]
        }
    },
    computed: {
        buttonColor() {
            return this.inputData && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                this.inputData
            ) ? 'px-2 primaru' : 'px-2'
        }
    },
    methods: {
        close() {
            this.clickedSubmit = false;
            this.$emit('close');

        },
        validEmailCheck() {
            //send input data into database to check if email exists.
            if (this.inputData && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                this.inputData
            )) {
                this.loading = true;
                this.clickedSubmit = true;
                this.$store.dispatch(EMAIL_PASSWORD_RESET, this.inputData)
                    .then((data) => {
                        this.foundEmailFlag = true;
                        this.responseData = data;
                    })
                    .catch(err => {
                        console.log(err);
                        this.foundEmailFlag = false;
                    })
                    .finally(() => this.loading = false)
            }
        }
    },
}
</script>


<style></style>
