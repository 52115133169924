<template>
  <v-row v-if="showbar" class="d-print-none dtv-app-bar" :class="isAnyMenuOpen ? 'dtv-app-bar-gray' : ' dtv-app-bar-white'" no-gutters>
    <v-col>
      <a href="https://www.directv.com/">
        <v-img :src="`${appUrl}/img/logos/dtvlogo.svg`" max-width="105" class="mt-2 ml-6" alt="DirecTV Logo" />
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LogoBar",

  computed: {
    showbar() {
      return this.$route.meta.showbar;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isFiltersMenuOpen() {
      return this.$store.getters.isFiltersMenuOpen;
    },
    isSportsMenuOpen() {
      return this.$store.getters.isSportsMenuOpen;
    },
    isAnyMenuOpen() {
      return this.isFiltersMenuOpen || this.isSportsMenuOpen;
    },
  },
  methods: {},
};
</script>


<style scoped>
/*
@media print {
  .v-main {
    padding: 0 !important;
  }
}
*/
.dtv-app-bar {
  height: 60px;
}
.dtv-app-bar-white {

  background: rgb(255, 255, 255);
  background: linear-gradient(
    175.8deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 45px,
    rgba(0, 0, 0, 0.2) 46px,
    rgba(255, 255, 255, 1) 56px
  );
}
.dtv-app-bar-gray {
  background: rgb(244,244,244);
  background: linear-gradient(
    175.8deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 45px,
    rgba(0, 0, 0, 0.2) 46px,
    rgba(244,244,244,1) 56px
  );
}
</style>
