<template>
    <v-row
        no-gutters
        justify="center"
        align="center"
    >
        <v-col
            cols="12"
            md="4"
            class="d-print-none py-md-0"
        >
            <span class="text-body-2">
                All events are displayed in
                <a @click="zipDialog = true">
                    {{ timezone }}
                </a>
                time
            </span>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="d-none d-print-block pa-0"
        >
            <span class="text-body-2">
                All events are in
                {{ timezone }}
                time
            </span>
        </v-col>

        <v-dialog
            v-model="zipDialog"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    ZIP Code
                </v-card-title>

                <v-card-text>
                    Enter your ZIP Code to see times in your local timezone. Changes are automatically saved.

                    <Field
                        :option="zipOption"
                        class="mt-4"
                    />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="zipDialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Field from "../Home/Field";

export default {
    name: 'TimezoneDeclaration',
    components: {
        Field,
    },
    data() {
        return {
            zipDialog: false,
        }
    },
    computed: {
        timezone() {
            return this.$store.getters.getCurrentSelectedOptions("timezone");
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
    },
}
</script>
