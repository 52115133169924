<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <v-card-title class="text-center" >{{ league.season.name }}</v-card-title>
            <v-row>
                <v-col
                    v-for="conference in league.season.eventType[0].conferences"
                    :key="conference.conferenceId"
                    cols="12"
                    md="6"
                >
                    <v-row>
                        <v-col
                            v-for="division in conference.divisions"
                            :key="division.divisionId"
                            cols="12"
                            sm="6"
                        >
                            <v-card>
                                <v-card-title>{{ division.name }}</v-card-title>
                                <v-card-text>
                                    <div
                                        v-for="team in division.teams"
                                        :key="team.teamId"
                                    >
                                        <strong>{{ team.location }} {{ team.nickname }}</strong>
                                        <p>Wins: {{ team.record.wins }}, Losses: {{ team.record.losses }}</p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_STANDINGS } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

export default {
    name: "Standings",
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
    },
    mounted() {
        this.$store.dispatch(GET_LEAGUE_STANDINGS, this.$route.params.league).then(() => {
            this.loading = false;
        });
    },
};
</script>
