<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <v-data-table
                :headers="headers"
                :items="league.season.teams"
                item-key="teamId"
                :item-class="rowClass"
                show-expand
                single-expand
                :expanded.sync="expanded"
                class="elevation-1"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td>&nbsp;</td>
                        <td>{{ item.location }} {{ item.nickname }}</td>
                        <td>
                            <v-btn
                                text
                                class="pa-0"
                                color="primary"
                                @click="toggleExpansion(item)"
                            >
                                View Depth Chart
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length + 1">
                        <v-container>
                            <v-row>
                                <v-col
                                    v-for="position in item.positions"
                                    :key="position.positionId"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="3"
                                >
                                    <v-subheader>{{ positionFullName(position.name) }}</v-subheader>
                                    <v-list>
                                        <v-list-item-group>
                                            <v-list-item
                                                class="list-item"
                                                v-for="entry in position.depthChart"
                                                :key="entry.player.playerId"
                                            >
                                                <v-list-item-content class="py-1">
                                                    <v-list-item-title>
                                                        {{ entry.depth }}. {{ entry.player.firstName }} {{
                                                            entry.player.lastName }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_DEPTH_CHARTS } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

export default {
    name: "DepthCharts",
    components: {
        Loading,
    },
    data() {
        return {
            expanded: [],
            headers: [
                { text: 'Team', align: 'start', sortable: false, value: 'location' },
                { text: 'Depth Chart', value: 'actions', sortable: false },
            ],
            loading: true,
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
    },
    methods: {
        toggleExpansion(item) {
            const index = this.expanded.indexOf(item);
            if (index > -1) {
                this.expanded.splice(index, 1);
            } else {
                this.expanded = [item];
            }
        },
        positionFullName(position) {
            switch (this.$route.params.league) {
                case 'nfl':
                    return this.nflPositionFullName(position);
                default:
                    return position;
            }
        },
        nflPositionFullName(position) {
            //Convert the position abbrevation to the full position name
            switch (position) {
                case 'SLB':
                    return 'Strongside Linebacker';
                case 'RDE':
                    return 'Right Defensive End';
                case 'RDT':
                    return 'Right Defensive Tackle';
                case 'WLB':
                    return 'Weakside Linebacker';
                case 'MLB':
                    return 'Middle Linebacker';
                case 'LDE':
                    return 'Left Defensive End';
                case 'LDT':
                    return 'Left Defensive Tackle';
                case 'QB':
                    return 'Quarterback';
                case 'RB':
                    return 'Running Back';
                case 'FB':
                    return 'Fullback';
                case 'WR':
                    return 'Wide Receiver';
                case 'TE':
                    return 'Tight End';
                case 'C':
                    return 'Center';
                case 'G':
                    return 'Guard';
                case 'T':
                    return 'Tackle';
                case 'DT':
                    return 'Defensive Tackle';
                case 'DE':
                    return 'Defensive End';
                case 'LB':
                    return 'Linebacker';
                case 'CB':
                    return 'Cornerback';
                case 'S':
                    return 'Safety';
                case 'K':
                    return 'Kicker';
                case 'P':
                    return 'Punter';
                case 'LS':
                    return 'Long Snapper';
                case 'KR':
                    return 'Kick Returner';
                case 'LCB':
                    return 'Left Cornerback';
                case 'LILB':
                    return 'Left Inside Linebacker';
                case 'LOG':
                    return 'Left Offensive Guard';
                case 'LOLB':
                    return 'Left Outside Linebacker';
                case 'LOT':
                    return 'Left Offensive Tackle';
                case 'NT':
                    return 'Nose Tackle';
                case 'PR':
                    return 'Punt Returner';
                case 'FS':
                    return 'Free Safety';
                case 'H':
                    return 'Holder';
                case 'K-FG':
                    return 'Field Goal Kicker';
                case 'K-KO':
                    return 'Kickoff Kicker';
                case 'RCB':
                    return 'Right Cornerback';
                case 'RILB':
                    return 'Right Inside Linebacker';
                case 'ROG':
                    return 'Right Offensive Guard';
                case 'ROLB':
                    return 'Right Outside Linebacker';
                case 'ROT':
                    return 'Right Offensive Tackle';
                case 'SS':
                    return 'Strong Safety';
                case 'WR1':
                    return 'Wide Receiver 1';
                case 'WR2':
                    return 'Wide Receiver 2';
                case 'WR3':
                    return 'Wide Receiver 3';
                default:
                    return position;
            }
        },
    },
    mounted() {
        this.$store.dispatch(GET_LEAGUE_DEPTH_CHARTS, this.$route.params.league).then(() => {
            this.loading = false;
        });
    },
};
</script>

<style scoped>
.list-item {
    min-height: unset !important;
}
</style>
