<template>
  <v-row class="pa-2" no-gutters>
    <v-col cols="12">
      <div v-if="isMobile" class="text-center">
        <h4>{{ game.description }}</h4>
        <time-display :game="game" />
      </div>
      <div v-else class="text-center">
        <h3>{{ game.description }}</h3>
        <time-display :game="game" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TimeDisplay from "./Shared/TimeDisplay";

export default {
  name: "Description",
  components: {
    TimeDisplay,
  },
  props: {
    game: Object,
  },
};
</script>