<template>
    <v-row no-gutters class="mt-1" justify="space-around">
        <v-col cols="auto" v-for="channelPackage in packagesWithLogos" :key="channelPackage.id">

            <v-avatar size="60" class="mx-2 elevation-2" v-if="!$route.query.example">
                <v-img
                    content-class="contain-paint"
                    contain
                    class="package-image"
                    :src="`${appUrl}/img/logos/packages/${channelPackage.logo}`"
                    :alt="channelPackage.name + ' Logo'"
                />
            </v-avatar>
            <v-avatar size="80" class="mx-2 elevation-2" v-if="$route.query.example==1">
                <v-img
                    content-class="contain-paint"
                    contain
                    class="package-image"
                    :src="`${appUrl}/img/logos/packages/${channelPackage.logo}`"
                    :alt="channelPackage.name + ' Logo'"
                />
            </v-avatar>
            <v-img
                v-if="$route.query.example==2"
                content-class="contain-paint"
                contain

                height="80"
                width="80"
                :src="`${appUrl}/img/logos/packages/${channelPackage.logo}`"
                :alt="channelPackage.name + ' Logo'"
            />
            <v-img
                v-if="$route.query.example==3"
                content-class="contain-paint"
                contain

                height="100"
                width="100"
                :src="`${appUrl}/img/logos/packages/${channelPackage.logo}`"
                :alt="channelPackage.name + ' Logo'"
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Packages",
    components: {

    },
    props: {
        game: Object,
    },
    data() {
        return {

        };
    },
    computed: {
        showings() {
            return this.game.showings;
        },
        packagesWithLogos() {
            //Every showing has a channel, every channel has packages. We want to return an array of all the packages with logos, but only unique ones by id.
            let packages = [];
            this.showings.forEach((showing) => {
                showing.channel.packages.forEach((channelPackage) => {
                    if (channelPackage.logo) {
                        if (!packages.find((p) => p.id == channelPackage.id)) {
                            packages.push(channelPackage);
                        }
                    }
                });
            });
            return packages;
        },
        layout() {
            return this.$store.getters.getCurrentSelectedOptions("layout");
        },

    },
    mounted() {
        console.log("Showing Packages");
    },
};
</script>

<style scoped>
.package-image {
    max-width: 70% !important;
    border-radius: 0 !important;
}
</style>
