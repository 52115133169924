<template>
    <v-expansion-panels flat v-if="seobar">
        <v-expansion-panel
          style="background: #f4f4f4"
          class="text-center py-3 mb-10"
        >
          <v-container style="max-width: 1200px">
            <h1 class="title text-center mt-3">
              Your Official Sports Schedule of DIRECTV
            </h1>
            <div class="tagline text-center my-3 px-16">
              Whatever your passion, we’ve got your game. DIRECTV gives you the local, regional, and national coverage to satisfy your sports cravings!
            </div>
            <v-btn @click="hideSeoBar">
              Hide this message
            </v-btn>
          </v-container>
        </v-expansion-panel>
      </v-expansion-panels>
</template>

<script>
import {
  POST_SELECTED_OPTION
} from "../../store/actions.type";

export default {
  name: "SeoBar",
  computed: {
    seobar() {
      return this.$store.getters.getCurrentSelectedOptions("seobar");
    },
  },
  methods: {
    hideSeoBar() {
      let key = 'seobar';
      let option = 0;
    //   this.$gtag.event("api", {
    //     event_category: "options",
    //     event_label: "post_selected_option",
    //     value: 0
    //   });
      this.$store
      .dispatch(POST_SELECTED_OPTION, { key, option })
      .then(() => {
        this.seoBarLoading = false;
      });
    }
  },

};
</script>

<style scoped>
.tagline {
  font-weight:700;
}
</style>
