<template>
    <v-text-field
      v-mask="option.mask || null"
      class="primary--text rounded-0"
      hide-details="auto"
      :label="option.name"
      outlined
      dense
      :block="isEmbed"
      @change="updated"
      :loading="loading"
      :value="selectedOptions"
      :rules="option.rules || null"
    />
  </template>

  <script>
  import { POST_SELECTED_OPTION, GET_GAMES, GET_SEASON_GAMES } from "../../../store/actions.type";
  import { mask } from 'vue-the-mask'

  export default {
    name: "SmallTextfield",
    directives: {
      mask
    },
    props: {
      option: Object
    },
    data() {
      return {
        loading: false
      };
    },
    computed: {
      selectedOptions() {
        return this.$store.getters.getCurrentSelectedOptions(this.option.key);
      }
    },
    methods: {
      updated(options) {
        let key = this.option.key;
        // this.$gtag.event("api", {
        //   event_category: "options",
        //   event_label: "post_selected_option",
        //   value: options
        // });
        this.$store
          .dispatch(POST_SELECTED_OPTION, { key, options })
          .then(() => {
            this.loading = false;
          });
      }
    }
  };
  </script>

  <style scoped>
  .v-text-field--outlined>>>.v-label {
    /*color: #196DD4;*/

    text-transform: uppercase;
  }
  </style>
