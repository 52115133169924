<template>
  <div>
    <description :game="game" v-if="game.description" />
    
    <teams :game="game" v-else />

    <v-row no-gutters class="pa-4" v-if="game.thuuz_game">
      <v-col :class="isMobile ? 'text-center' : ''">
        <div v-html="game.thuuz_game.teaser_default"/>
      </v-col>
    </v-row>
    
    <panels :game="game" ref="panels"/>
  </div>
</template>

<script>
import Description from "./Description";
import Teams from "./Teams";
import Panels from "./Panels"

export default {
  name: "GameDetails",
  components: {
      Teams,
      Description,
      Panels
  },
  props: {
    game: Object
  },
};
</script>