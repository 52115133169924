<template>
    <!-- Player Data Table -->
    <v-data-table
        :headers="playerHeaders"
        :items="formattedPlayers"
        :item-class="rowClass"
        class="elevation-1"
        :search="search"
    >
        <template v-slot:top>
            <v-text-field
                prepend-icon="mdi-magnify"
                v-model="search"
                label="Search"
                class="mx-4 search-field"
            />
        </template>

    </v-data-table>
</template>

<script>
export default {
    name: "NHL",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            playerHeaders: [
                { text: 'Name', value: 'name' },
                { text: 'Team', value: 'team' },
                { text: 'Hand', value: 'hand' },
                { text: 'Birth Date', value: 'birthDate' },
                { text: 'Height', value: 'height' },
                { text: 'Weight', value: 'weight' },
                { text: 'Uniform Number', value: 'uniform' },
                { text: 'Positions', value: 'positions' }
            ],
        };
    },
    computed: {
        formattedPlayers() {
            return this.league.players.map(player => ({
                name: `${player.firstName} ${player.lastName}`,
                team: `${player.team.location} ${player.team.nickname}`,
                hand: player.hand.name,
                birthDate: player.birth.birthDate.full,
                height: `${player.height.centimeters} cm / ${player.height.inches} in`,
                weight: `${player.weight.kilograms} kg / ${player.weight.pounds} lbs`,
                uniform: player.uniform,
                positions: this.positionsString(player.positions),
            }));
        },
    },
    methods: {
        positionsString(positions) {
            return positions.map(p => p.abbreviation).join(', ');
        }
    },
};

</script>

<style scoped>

.search-field {
    padding-top:20px;
}

.search-field>>>.v-label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.search-field>>>input {
    font-size: 20px;
}

.search-field>>>.v-icon {
    font-size: 40px;
}

</style>
