import ApiService from "../api/api.service";
import JwtService from "../common/jwt.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    CHECK_AUTH,
    LOGIN,
    LOGOUT,
    REGISTER,
    EMAIL_PASSWORD_RESET,
    RESET_PASSWORD,
    UPDATE_FORM,
    TOGGLE_AUTH_DIALOG,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    SET_AUTH,
    SET_ERROR,
    SET_CURRENT_FORM,
    RESET_AUTH,
    CLEAR_ERRORS,
    SET_AUTH_DIALOG
} from "../store/mutations.type";



/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        authDialog: false,
        form: 'login',
        token: null,
        userId: null,
        user: null,
        roles: [],
        isAuthenticated: !!JwtService.getToken(),

    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentForm(state) {
        return state.form;
    },
    currentAuthDialogState(state) {
        return state.authDialog;
    },
    currentUserId(state) {
        return state.userId;
    },
    currentUser(state) {
        return state.user;
    },
    currentRoles(state) {
        return state.roles;
    },
    hasRole(state) {
        return keyword => state.roles.includes(keyword);
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        state.userId = data.userId;
        state.user = data.user;
        state.token = data.token;
        state.roles = data.roles;
        state.errors = getDefaultState().errors;
        JwtService.setToken(data.token);
    },
    [RESET_AUTH](state) {
        JwtService.unsetToken();
        Object.assign(state, getDefaultState());
    },
    [SET_CURRENT_FORM](state, form) {
        state.form = form;
    },
    [SET_AUTH_DIALOG](state, dialogState) {
        state.authDialog = dialogState;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [UPDATE_FORM](context, form) {
        context.commit(SET_CURRENT_FORM, form);
    },
    [TOGGLE_AUTH_DIALOG](context) {
        context.commit(SET_AUTH_DIALOG, !context.state.authDialog);
    },
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("/login", credentials)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_AUTH, {
                        userId: data.user.id,
                        user: data.user,
                        token: data.token,
                        roles: data.roles,
                    }
                    );
                    context.dispatch(TOGGLE_AUTH_DIALOG);
                    context.dispatch(CHECK_AUTH);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'login',
                        message: response.data.message,
                        errors: response.data.errors ?? []
                    }
                    );
                    reject(response);
                });
        });
    },
    [LOGOUT](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("/logout")
                .then(({
                    data
                }) => {
                    context.commit(RESET_AUTH);
                    ApiService.resetHeader();
                    localStorage.clear();
                    window.localStorage.clear();
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'logout',
                        message: response.data.message,
                        errors: response.data.errors ?? []
                    }
                    );
                    reject(response);
                });
        });
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("/register", credentials)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_AUTH, {
                        userId: data.user.id,
                        user: data.user,
                        token: data.api_token,
                        roles: data.roles,
                    });
                    context.dispatch(TOGGLE_AUTH_DIALOG);
                    context.dispatch(CHECK_AUTH);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'register',
                        message: response.data.message,
                        errors: response.data.errors ?? []
                    }
                    );
                    reject(response);
                });
        });
    },
    [EMAIL_PASSWORD_RESET](context, email) {
        return new Promise((resolve, reject) => {
            ApiService.post("password/email", {
                email: email
            })
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'reset_password',
                        message: response.data.message,
                        errors: response.data.errors ?? []
                    }
                    );
                    reject(response);
                });
        });
    },
    [RESET_PASSWORD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("password/reset", credentials)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(SET_AUTH, {
                        userId: data.user.id,
                        user: data.user,
                        token: data.api_token,
                        roles: data.roles,
                    });
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'reset_password',
                        message: response.data.message,
                        errors: response.data.errors ?? []
                    }
                    );
                    reject(response);
                });
        });
    },
    [CHECK_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("/validate")
                .then(({
                    data
                }) => {
                    context.commit(SET_AUTH, {
                        userId: data.user.id,
                        user: data.user,
                        roles: data.roles,
                    });
                })
                .catch(({ response }) => {
                    context.commit(RESET_AUTH);
                })
                .catch(({ response }) => {
                    context.commit(RESET_AUTH);

                });
        } else {
            context.commit(RESET_AUTH);
        }
    },
};

export default {
    getters,
    actions,
    mutations,
    state
}
