<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
    >
        <!-- Outer white box -->
        <rect x="0" y="16" width="100" height="70" fill="white" rx="10" ry="10" />

        <!-- Middle blue box -->
        <rect x="4" y="22" width="92" height="62" fill="#013369" rx="8" ry="8" />

        <!-- Inner white box -->
        <rect x="8" y="26" width="84" height="54" fill="white" rx="6" ry="6" />

        <!-- NFL text -->
        <text
            x="50"
            y="50"
            class="nfl-text"
            text-anchor="middle"
            dominant-baseline="central"
        >NFL</text>
    </svg>
</template>

<script>
export default {
    name: "NflPlainLogo",
};
</script>

<style scoped>
.nfl-text {
    font-family: "Arial Black", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 32px;
    fill: #D50A0A;  /* NFL red */
}
</style>
