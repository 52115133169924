<template>

    <v-row
        no-gutters
        justify="space-around"
    >

        <v-col
            cols="6"
            class="text-center"
        >
            <v-btn
                color="primary"
                @click="handleMobileSportsMenu"
            >
                CHANGE SPORT
            </v-btn>
        </v-col>

        <v-col
            cols="6"
            class="text-center"
        >
            <v-btn
                color="primary"
                @click="handlePreferencesMenu"
            >
                PREFERENCES
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

import {
    CLOSE_MOBILE_MENUS,
    OPEN_SPORTS_MENU,
    OPEN_FILTERS_MENU,
} from "../../store/mutations.type";

export default {
    name: "MobileOptions",
    computed: {
        gotOptions() {
            return this.$store.getters.checkGotOptions;
        },
        sportOptionName() {
            if (!this.gotOptions) return "Loading...";

            let currentOption = this.sportsOption.options.filter(
                (option) => option.value == this.selectedSport
            );

            return currentOption[0].text;
        },
        sportOptionIcon() {
            if (!this.gotOptions) return "";

            let currentOption = this.sportsOption.options.filter(
                (option) => option.value == this.selectedSport
            );

            return currentOption[0].icon;
        },
        sportsOption() {
            return this.$store.getters.getSingleOption("sports");
        },
        selectedSport() {
            return this.$store.getters.getCurrentSelectedOptions(this.sportsOption.key);
        },
        isFiltersMenuOpen() {
            return this.$store.getters.isFiltersMenuOpen;
        },
        isSportsMenuOpen() {
            return this.$store.getters.isSportsMenuOpen;
        },
        isAnyMenuOpen() {
            return this.isFiltersMenuOpen || this.isSportsMenuOpen;
        },
    },
    methods: {
        handleMobileSportsMenu() {
            if (this.isAnyMenuOpen) {
                this.$store.commit(CLOSE_MOBILE_MENUS);
            } else {
                this.$store.commit(CLOSE_MOBILE_MENUS);
                this.$store.commit(OPEN_SPORTS_MENU);
            }
        },
        handlePreferencesMenu() {
            if (this.isAnyMenuOpen) {
                this.$store.commit(CLOSE_MOBILE_MENUS);
            } else {
                this.$store.commit(CLOSE_MOBILE_MENUS);
                this.$store.commit(OPEN_FILTERS_MENU);
            }
        },
    },
};
</script>

<style scoped>
.filter-title {
    height: 36px;
    font-weight: bold;
    font-size: 1.2em;
    letter-spacing: 0.1em;
}
</style>
