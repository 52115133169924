<template>
  <v-select
    class="input-field"
    block
    hide-details="auto"
    :label="option.name"
    :items="option.options"
    outlined
    rounded
    :multiple="option.multiple"
    @change="updated"
    :loading="loading"
    :value="selectedOptions"
  >
    <template v-slot:item='{item}'> <div v-html='item.text'/> </template>
    <template v-slot:selection='{item}'> <div class="pa-4"  v-html='item.text'/> </template>
  </v-select>
</template>

<script>
import { POST_SELECTED_OPTION, GET_GAMES } from "../../../store/actions.type";
import { SET_SELECTED_ADDONS } from "../../../store/mutations.type";

export default {
  name: "Dropdown",
  props: {
    option: Object
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    selectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
    allOptionsSelected() {
      return this.option.options.length === this.selectedOptions.length;
    },
    someOptionsSelected() {
      return this.selectedOptions.length > 0 && !this.allOptionsSelected;
    },
    icon() {
      if (this.allOptionsSelected) return "mdi-close-box";
      if (this.someOptionsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    updated(options) {
      this.loading = true;
      let key = this.option.key;

    //   this.$gtag.event("api", {
    //     event_category: "options",
    //     event_label: "post_selected_option",
    //     value: options
    //   });

      if (this.option.key == "bundles") {
        console.log("Clearing ADDONS");
        this.$store.commit(SET_SELECTED_ADDONS, []);
      }

      this.$store.dispatch(POST_SELECTED_OPTION, { key, options })
      .then(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.v-text-field--outlined >>> .v-select__selection {
  /*color: #196DD4;*/
  font-size:1.2em;
  text-transform: uppercase;
}

.v-text-field--outlined >>> .v-select__selections {
  /*color: #196DD4;*/
  font-size:1.2em;
  text-transform: uppercase;
}

.v-text-field--outlined >>> .v-label {
  /*color: #196DD4;*/
  font-size:1.5em;
  font-weight:bold;
}

.v-text-field--outlined >>> fieldset {
  /*border-color: #196DD4;*/
  border-width: 3px;
}
</style>
