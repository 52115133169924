<template>
    <!-- Player Data Table -->
    <v-data-table
        :headers="playerHeaders"
        :items="league.players"
        :item-class="rowClass"
        class="elevation-1"
        :search="search"
    >
        <template v-slot:top>
            <v-text-field
                prepend-icon="mdi-magnify"
                v-model="search"
                label="Search"
                class="mx-4"
            />
        </template>
        <template v-slot:item.positions="{ item }">
            <span
                v-for="(position, index) in item.positions"
                :key="index"
            >
                {{ position.abbreviation }}
            </span>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "NBA",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            playerHeaders: [
                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' },
                { text: 'Team', value: 'team.nickname' },
                { text: 'Position', value: 'positions[0].abbreviation' },
                { text: 'Draft Year', value: 'draft.year' },
            ],
        };
    },
    methods: {
        positionsString(positions) {
            return positions.map(p => p.abbreviation).join(', ');
        }
    },
};

</script>
