<template>
  <v-card flat outlined>
    <v-row  class="mx-5">
      <v-col cols="11" sm="5">
        <v-menu
          ref="start_menu"
          v-model="start_menu"
          transition="scale-transition"
          offset-y
          :close-on-content-click="false"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="displayTime(start_time)"
              label="Opening Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="ampm"
            elevation="3"
            v-if="start_menu"
            v-model="start_time"
            full-width
            @click:minute="updated()"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="11" sm="5">
        <v-menu
          ref="end_menu"
          v-model="end_menu"
          transition="scale-transition"
          offset-y
          :close-on-content-click="false"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="displayTime(end_time)"
              label="Closing Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="ampm"
            elevation="3"
            v-if="end_menu"
            v-model="end_time"
            full-width
            @click:minute="updated()"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { POST_SELECTED_OPTION, GET_GAMES } from "../../../store/actions.type";

export default {
  name: "BusinessHours",
  props: {
    option: Object,
  },
  data() {
    return {
      loading: false,
      start_menu: false,
      start_time: null,
      end_time: null,
      end_menu: false,
    };
  },
  computed: {
    selectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
  },
  methods: {
    displayTime(time) {
      return new Date('1970-01-01T' + time + 'Z')
        .toLocaleTimeString({},
          {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        );
    },
    splitTime() {
      if (this.selectedOptions) {
        var times = this.selectedOptions.split("-");
        if (times.length > 0) {
          this.start_time = times[0];
        }
        if (times.length > 1) {
          this.end_time = times[1];
        }
      }
    },
    updated() {
      if (!this.start_time && !this.end_time) {
        return;
      }
      this.loading = true;
      let key = this.option.key;

      let new_time = this.start_time + "-" + this.end_time;

    //   this.$gtag.event("api", {
    //     event_category: "options",
    //     event_label: "post_selected_option",
    //     value: new_time,
    //   });

      this.$store
        .dispatch(POST_SELECTED_OPTION, { key, options: new_time })
        .then(() => {
          this.loading = false;

        //   this.$gtag.event("api", {
        //     event_category: "options",
        //     event_label: "get_events",
        //   });

          this.$store.dispatch(GET_GAMES);
        });
    },
  },
  mounted() {
    this.splitTime();
  },
  watch: {
    start_menu: function () {
      this.updated();
    },
    end_menu: function () {
      this.updated();
    },
    selectedOptions: function () {
      this.splitTime();
    },
  },
};
</script>

<style scoped>


.v-text-field--outlined >>> .v-label {

  font-weight: bold;
}

.v-card {
  border-width: 3px;
  border-radius: 0px;
  border-color: #9E9E9E;
}
</style>
