import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_TEAMS,
    GET_TEAMS_FOR_LEAGUE,
    GET_SELECTED_TEAMS,
    POST_SELECTED_TEAMS,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_TEAMS,
    SET_SELECTED_TEAMS
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        teams: {},
        selectedTeams: [],
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getCurrentTeams(state) {
        return state.teams;
    },
    getCurrentSelectedTeams(state) {
        return state.selectedTeams;
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_TEAMS](state, data) {
        console.log("SET_TEAMS");
        state.teams = data.sort((a, b) => (a.display_name_short > b.display_name_short) ? 1 : -1);
    },
    [SET_SELECTED_TEAMS](state, data) {
        console.log("SET_SELECTED_TEAMS");
        for (const property in data) {
            state.selectedTeams[property] = data[property];
        }
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_TEAMS](context) {
        console.log("GET_TEAMS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/teams")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_TEAMS, data.teams
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'teams',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_TEAMS_FOR_LEAGUE](context, league) {
        console.log("GET_TEAMS_FOR_LEAGUE");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/teams`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_TEAMS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'teams',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_SELECTED_TEAMS](context) {
        console.log("GET_SELECTED_TEAMS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/teams/selected")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SELECTED_TEAMS, data.teams
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'teams',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [POST_SELECTED_TEAMS](context, details) {
        console.log("POST_SELECTED_TEAMS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.post("/teams/selected", {'teams': details})
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SELECTED_TEAMS, data.teams
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'teams',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },

};

export default {
    getters,
    actions,
    mutations,
    state
}
