<template>
  <v-card>
    <v-card-title>Choose your teams</v-card-title>
    <v-card-subtitle>Choose which teams you want to include on your personalized schedule</v-card-subtitle>
    <v-card-text style="height: 400px">
      <loading v-if="loading"></loading>
      <v-row v-else>
        <v-col
          cols="12"
          sm="8"
          md="6"
          v-for="league in leagues"
          v-bind:key="league.ID"
        >
          <h3>
            <v-checkbox
              :label="league.Title"
              @change="toggleLeague(league.ID)"
              :input-value="sportSelected(league.ID)"
              :value="league.ID"
              hide-details
            ></v-checkbox>
          </h3>
          <v-row no-gutters>
            <v-col
              class="py-0"
              cols="12"
              v-for="schedule in league.schedules"
              v-bind:key="schedule.ID"
            >
              <v-checkbox
                :label="schedule.Title"
                @change="toggleLeague(league.ID, schedule.ID)"
                :input-value="leagueSelected(league.ID, schedule.ID)"
                :value="schedule.ID"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" large @click="$emit('done')">Cancel</v-btn>
      <v-btn color="primary" large @click="finish" :disabled="isDisabled"
        >Filter</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import Loading from "../../shared/Loading";

import {
  GET_GAMES,
  GET_SELECTED_OPTIONS,
  GET_SPORTS,
  GET_SELECTED_LEAGUES,
  SET_SELECTED_LEAGUE,
  POST_SELECTED_LEAGUES,
} from "../../../store/actions.type";

export default {
  name: "TeamsModal",
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      selectedLeagues: {},
    };
  },
  computed: {
    isDisabled() {
      return Object.keys(this.selectedLeagues).length === 0;
    },
    leagues() {
      return this.$store.getters.getCurrentSports;
    },
  },
  methods: {
    sportSelected(id) {
      return id in this.selectedLeagues ? id : null;
    },
    toggleSport(id) {
      let self = this;
      if (id in this.selectedLeagues) {
        Vue.delete(this.selectedLeagues, id);
      } else {
        let tempSchedule = Object.keys(this.leagues[id].schedules).reduce(
          function (r, k) {
            return r.concat(self.leagues[id].schedules[k].ID);
          },
          []
        );
        Vue.set(this.selectedLeagues, id, tempSchedule);
      }
    },
    leagueSelected(sport_id, league_id) {
      if (!Object.keys(this.selectedLeagues).length) {
        return null;
      }

      if (!(sport_id in this.selectedLeagues)) {
        return null;
      }

      return this.selectedLeagues[sport_id].indexOf(league_id) >= 0
        ? league_id
        : null;
    },
    toggleLeague(sport_id, league_id) {
      var index = this.selectedLeagues[sport_id].indexOf(league_id);
      if (index === -1) {
        this.selectedLeagues[sport_id].push(league_id);
      } else {
        this.selectedLeagues[sport_id].splice(index, 1);
      }
    },
    finish() {
    //   this.$gtag.event("api", {
    //     event_category: "leagues",
    //     event_label: "set_selected_leagues",
    //   });
      this.$store
        .dispatch(POST_SELECTED_LEAGUES, this.selectedLeagues)
        .then(() => {
        //   this.$gtag.event("api", {
        //     event_category: "options",
        //     event_label: "get_selected_options",
        //   });
          this.$store.dispatch(GET_SELECTED_OPTIONS);
        //   this.$gtag.event("api", {
        //     event_category: "events",
        //     event_label: "get_events",
        //   });
          this.$store.dispatch(GET_GAMES);
          this.$emit("done");
        });
    },
  },
  mounted() {
    let self = this;
    this.$nextTick(function () {
    //   this.$gtag.event("api", {
    //     event_category: "leagues",
    //     event_label: "GET_SPORTS",
    //   });
      this.$store.dispatch(GET_SPORTS).then(() => {
        // this.$gtag.event("api", {
        //   event_category: "leagues",
        //   event_label: "get_selected_leagues",
        // });
        this.$store.dispatch(GET_SELECTED_LEAGUES).then(() => {
          this.$forceUpdate();
          Object.keys(this.$store.getters.getCurrentSelectedLeagues).forEach(
            (key) => {
              Vue.set(
                this.selectedLeagues,
                key,
                this.$store.getters.getCurrentSelectedLeagues[key]
              );
            }
          );
          this.selectedLeagues = this.$store.getters.getCurrentSelectedLeagues;
          Vue.nextTick().then(function () {
            self.loading = false;
          });
        });
      });
    });
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
