import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import JwtService from "../common/jwt.service";
import {API_URL} from "../common/config";

const ApiService = {
    init(){
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        this.setHeader();
    },

    setHeader(){
        Vue.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        };
    },

    resetHeader() {
        Vue.axios.defaults.headers.common = {
        };
    },

    get(resource, params) {
        return Vue.axios.get(`${resource}`, {params: params}).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    patch(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    delete(resource, params) {
        return Vue.axios.delete(`${resource}`, {params: params});
    },
    uploadImage(image, onUploadProgress) {
        let formData = new FormData();
        formData.append("image", image);
        return Vue.axios.post("/uploads", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    },
};

export default ApiService;
