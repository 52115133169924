import ApiService from "../api/api.service";
import router from "../router";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_GAMES,
    GET_EMBED_GAMES,
    GET_SEASON_GAMES,
    POST_BULLETIN_READ
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    CLEAR_GAMES,
    CLEAR_BULLETINS,
    SET_GAMES,
    SET_BULLETINS,
    SET_BULLETIN_READ,
    SET_GAMES_LOADING
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        games: [],
        bulletins: [],
        loading: true,
        errors: {
            games: [],
        },
        gamesInfiniteLoader: new Date(),
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getGames(state) {
        //Sorted by date
        return state.games.sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));
    },
    getGamesInfiniteLoader() {
        return state.gamesInfiniteLoader;
    },
    getSortedFilteredGames(state, getters, rootState, rootGetters) {
        let games = JSON.parse(JSON.stringify(state.games));
        let filteringByAddons = false;

        //console.log('Starting Games Count: ' + games.length);
        const specialFilters = rootGetters.getSpecialFilters;
        const selectedOptions = rootGetters.getAllSelectedOptions;
        const selectedAddons = rootGetters.getSelectedAddons;

        /*
        ╔═╗┌┬┐┌┬┐┌─┐┌┐┌┌─┐  ╔═╗╔╗╔╦ ╦ ╦
        ╠═╣ ││ │││ ││││└─┐  ║ ║║║║║ ╚╦╝
        ╩ ╩─┴┘─┴┘└─┘┘└┘└─┘  ╚═╝╝╚╝╩═╝╩
        */
        //Addons Specific Filter
        if (selectedAddons && selectedAddons.length > 0) {

            filteringByAddons = true;

            //Remove games that don't have any showings
            games = games.filter(g => g.showings && g.showings.length > 0);


            games = games.map((game) => {
                if (!game.showings || game.showings.length == 0) {
                    return game;
                }

                game.showings = game.showings.filter(s => s.live && s.channel.packages.filter(a => selectedAddons.includes(a.id)).length > 0);

                if (!game.showings || game.showings.length == 0) {
                    return game;
                }

                game.showings = game.showings.sort((a, b) => a.channel.number_major - b.channel.number_major);

                return game;
            });

            games = games.filter(g => g.showings && g.showings.length > 0);
        }




        /*
        ███████╗██╗██╗  ████████╗███████╗██████╗ ██╗███╗   ██╗ ██████╗
        ██╔════╝██║██║  ╚══██╔══╝██╔════╝██╔══██╗██║████╗  ██║██╔════╝
        █████╗  ██║██║     ██║   █████╗  ██████╔╝██║██╔██╗ ██║██║  ███╗
        ██╔══╝  ██║██║     ██║   ██╔══╝  ██╔══██╗██║██║╚██╗██║██║   ██║
        ██║     ██║███████╗██║   ███████╗██║  ██║██║██║ ╚████║╚██████╔╝
        ╚═╝     ╚═╝╚══════╝╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝
        */



        /*
        ╔═╗┌─┐┌┬┐┌─┐┌─┐
        ║ ╦├─┤│││├┤ └─┐
        ╚═╝┴ ┴┴ ┴└─┘└─┘
        */

        //Sports
        if (!filteringByAddons && ['home', 'home_params', 'embed', 'mvp'].includes(router.currentRoute.name)) {
            if (specialFilters.length > 0) {
                //Filter games by the key and value of the special filters
                specialFilters.forEach(specialFilter => {
                    games = games.filter(g => g[specialFilter.key] == specialFilter.value);
                });
            }
            if (selectedOptions.sports && selectedOptions.sports < 99) {
                games = games.filter(g => g.sport_id == selectedOptions.sports || g.league.sport_id == selectedOptions.sports);
            }
            if (selectedOptions.leagues && selectedOptions.sports == 99) {
                games = games.filter(g => selectedOptions.leagues.includes(g.league_id));
            }
        }

        //console.log('Sports Removed: ' + games.length);


        //Leagues


        /*
        ╔═╗┬ ┬┌─┐┬ ┬┬┌┐┌┌─┐┌─┐
        ╚═╗├─┤│ │││││││││ ┬└─┐
        ╚═╝┴ ┴└─┘└┴┘┴┘└┘└─┘└─┘
        */
        games = games.map((game, game_index) => {
            let hasRsn = false;
            let hasLocal = false;
            //console.log(`Game ${game_index} Showing Count: ${game.showings.length}`)


            if (!game.showings || game.showings.length == 0) return game;
            //Replays
            game.showings = game.showings.filter(s => s.live);


            if (!game.showings || game.showings.length == 0) return game;
            //RSNs Only
            //Check if any showings have an RSN
            game.showings.forEach(s => {
                if (s.channel.rsn) hasRsn = true;
            });

            //Local Only
            //Check if any showings have a local dma_id
            game.showings.forEach(s => {
                if (s.channel.number_major < 100) hasLocal = true;
            });

            // console.log(`Game ${game.id} RSN: ${hasRsn} Local: ${hasLocal}`);

            //Remove package showings if there are already RSN
            if (hasRsn) {
                game.showings = game.showings.filter(s => !s.channel.package);
            }

            //Remove Youtube showings if there are already local (dma_id>0)
            if (hasLocal) {
                game.showings = game.showings.filter(s => !s.channel.package);
                game.showings = game.showings.filter(s => s.channel_id != 13084);
            }


            if (!game.showings || game.showings.length == 0) return game;
            //Language
            if (selectedOptions.languages != 99) {
                game.showings = game.showings.filter(s => s.channel.language_id == selectedOptions.languages);
                //console.log(`Game ${game_index} Showing Count after Language: ${game.showings.length}`);
            }

            /*
            switch (selectedOptions.replays) {
                case 0:
                    //Live Only, so remove non-live
                    game.showings = game.showings.filter(s => s.live == true);
                    break;
                case 1:
                    //Live and Replays only, so remove highlights
                    game.showings = game.showings.filter(s => s.highlight == false);
                    break;
            }
            */

            //console.log(`Game ${game_index} Showing Count after Replays: ${game.showings.length}`);

            //Bundles (Package Group)
            if (selectedOptions.bundles) {
                //If on Commercial, remove residential showings from games
                // OR
                //If Residential, remove commercial only showings from games
                if (router.currentRoute.name == 'embed' || router.currentRoute.name == 'mvp' || selectedOptions.bundles >= 20) {
                    game.showings = game.showings.filter(s => !s.channel['residential_only']);
                } else {
                    game.showings = game.showings.filter(s => !s.channel['commercial_only']);
                }
            };

            if (!game.showings || game.showings.length == 0) return game;

            game.showings = game.showings.sort((a, b) => a.channel.number_major - b.channel.number_major);

            return game;
        });

        //Hide games with no showings after filtering.
        games = games.filter(g => (!filteringByAddons && g.league.show_all_games) || g.showings.length > 0);
        //console.log('Ending Games Count: ' + games.length);

        /*
        ███████╗ ██████╗ ██████╗ ████████╗██╗███╗   ██╗ ██████╗
        ██╔════╝██╔═══██╗██╔══██╗╚══██╔══╝██║████╗  ██║██╔════╝
        ███████╗██║   ██║██████╔╝   ██║   ██║██╔██╗ ██║██║  ███╗
        ╚════██║██║   ██║██╔══██╗   ██║   ██║██║╚██╗██║██║   ██║
        ███████║╚██████╔╝██║  ██║   ██║   ██║██║ ╚████║╚██████╔╝
        ╚══════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝
        */
        const groupBy = (router.currentRoute.name == 'team-schedule' || router.currentRoute.name == 'league-schedule') ? 0 : selectedOptions.groups;

        if (['home', 'home_params', 'embed', 'mvp', 'team-schedule', 'league-schedule'].includes(router.currentRoute.name)) {
            games = games.sort((a, b) => {
                //Get Times
                let aTime = null;
                let bTime = null;
                if (a.showings && a.showings.length) {
                    if (a.showings[0].live == 1) {
                        aTime = a.starts_at;
                    } else {
                        aTime = a.showings[0].display_at;
                    }
                } else {
                    aTime = a.starts_at;
                }

                if (b.showings && b.showings.length) {
                    if (b.showings[0].live == 1) {
                        bTime = b.starts_at;
                    } else {
                        bTime = b.showings[0].display_at;
                    }
                } else {
                    bTime = b.starts_at;
                }

                aTime = new Date(aTime).getTime();
                bTime = new Date(bTime).getTime();

                switch (groupBy) {
                    case 0:
                        return aTime - bTime;
                    case 1:
                        // Sport
                        if (a.sport_id !== b.sport_id) {
                            return a.sport_id - b.sport_id;
                        }
                        if (a.league.rank !== b.league.rank) {
                            return a.league.rank - b.league.rank;
                        }
                        if (a.league.title.localeCompare(b.league.title) !== 0) {
                            return a.league.title.localeCompare(b.league.title);
                        }
                        return aTime - bTime;
                    case 2:
                        // League
                        if (a.league.rank !== b.league.rank) {
                            return a.league.rank - b.league.rank;
                        }
                        if (a.league.title.localeCompare(b.league.title) !== 0) {
                            return a.league.title.localeCompare(b.league.title);
                        }
                        if (a.sport_id !== b.sport_id) {
                            return a.sport_id - b.sport_id;
                        }
                        return aTime - bTime;
                    case 3:
                        //Hype
                        if (a.thuuz_game && b.thuuz_game) {
                            return b.thuuz_game.gex_default - a.thuuz_game.gex_default;
                        }
                        if (a.thuuz_game || b.thuuz_game) {
                            return a.thuuz_game ? -1 : 1;
                        }
                        return aTime - bTime;
                }
            });
        }

        //Hide completed games if completed filter is on
        if (!filteringByAddons && (router.currentRoute.name == "embed" || router.currentRoute.name == "mvp" || (router.currentRoute.name != "team-schedule" && router.currentRoute.name != "league-schedule" && selectedOptions.completed == false))) {

            games = games.filter(
                g => {
                    //Check if the game status is 1 or 5 or greater and the start time is in the past
                    if ((g.game_status_id == 1 || g.game_status_id >= 5) && moment.utc(g.starts_at) < moment.utc().subtract(2, 'hours')) {
                        /*
                            Game Status is not started or is cancelled, delayed, postponed etc...and start time has passed
                        */
                        return false;
                    }
                    //Game status is finished
                    if (g.game_status_id == 4) {
                        //Game Status is completed
                        return false;
                    }

                    return true;
                }
            );

        }

        return games;
    },
    getGamesLoading(state) {
        return state.loading;
    },
    getBulletins(state, getters, rootState, rootGetters) {
        const selectedOptions = rootGetters.getAllSelectedOptions;
        if (selectedOptions.bundles && selectedOptions.bundles >= 20) {
            return state.bulletins.filter(b => b.commercial);
        } else {
            return state.bulletins.filter(b => b.residential);
        }
    },
    getMainBulletin(state, getters, rootState, rootGetters) {
        const selectedOptions = rootGetters.getAllSelectedOptions;

        let bulletins = state.bulletins;

        if (selectedOptions.bundles && selectedOptions.bundles >= 20) {
            bulletins = bulletins.filter(b => b.commercial);
        } else {
            bulletins = bulletins.filter(b => b.residential);
        }

        //Get the first bulletin where only the dma_id or zip is set or return null
        const bulletin = bulletins.find(b =>
            b.team_id == null &&
            b.league_id == null &&
            b.channel_number_major == null &&
            b.channel_number_minor == null &&
            b.game_id == null
        );
        if (bulletin) {
            return bulletin;
        }
        return null;
    },
    getTeamBulletin: (state, getters, rootState, rootGetters) => (ids) => {
        const selectedOptions = rootGetters.getAllSelectedOptions;

        let bulletins = state.bulletins;

        if (selectedOptions.bundles && selectedOptions.bundles >= 20) {
            bulletins = bulletins.filter(b => b.commercial);
        } else {
            bulletins = bulletins.filter(b => b.residential);
        }

        //Get the bulletins where the team_id matches either from the ids array or return null
        bulletins = bulletins.filter(b => ids.includes(b.team_id));
        if (bulletins.length > 0) {
            return bulletins;
        }

        return null;
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [CLEAR_GAMES](state) {
        console.log("CLEAR_GAMES");
        state.loading = true;
        state.games = [];
    },
    [SET_GAMES](state, data) {
        console.log("SET_GAMES");
        state.loading = false;
        state.games = data;
    },
    [CLEAR_BULLETINS](state) {
        console.log("CLEAR_BULLETINS");
        state.bulletins = [];
    },
    [SET_BULLETINS](state, data) {
        console.log("SET_BULLETINS");
        state.bulletins = data;
    },
    [SET_BULLETIN_READ](state, bulletin_id) {
        console.log("SET_BULLETIN_READ");
        let bulletin = state.bulletins.find(b => b.id == bulletin_id);
        if (bulletin) {
            bulletin.is_read = true;
        }
    },
    [SET_GAMES_LOADING](state, setting) {
        console.log("SET_GAMES_LOADING");
        state.loading = setting;
        state.gamesInfiniteLoader = new Date();
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_GAMES](context, params) {
        console.log("GET_GAMES", params);
        context.commit(CLEAR_GAMES);
        context.commit(CLEAR_BULLETINS);
        context.commit(SET_GAMES_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.get("/games", params)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);

                    context.commit(
                        SET_GAMES, data.games
                    );
                    context.commit(
                        SET_BULLETINS, data.bulletins
                    );
                    context.commit(
                        SET_GAMES_LOADING, false
                    );

                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_GAMES_LOADING, false
                    );
                    context.commit(
                        SET_ERROR, {
                        target: 'games',
                        message: response
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_EMBED_GAMES](context, groupuid) {
        console.log("GET_EMBED_GAMES");
        context.commit(CLEAR_GAMES);
        context.commit(SET_GAMES_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.get(`/embed/api/${groupuid}/games`)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);

                    context.commit(
                        SET_GAMES, data
                    );
                    context.commit(
                        SET_GAMES_LOADING, false
                    );

                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_GAMES_LOADING, false
                    );
                    context.commit(
                        SET_ERROR, {
                        target: 'games',
                        message: response
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_SEASON_GAMES](context, params) {
        console.log("GET_SEASON_GAMES");
        context.commit(CLEAR_GAMES);
        context.commit(CLEAR_BULLETINS);
        context.commit(SET_GAMES_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.get("/season", params)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_GAMES, data.games
                        );
                        context.commit(
                            SET_BULLETINS, data.bulletins
                        );
                        context.commit(
                            SET_GAMES_LOADING, false
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_GAMES_LOADING, false
                    );
                    context.commit(
                        SET_ERROR, {
                        target: 'games',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [POST_BULLETIN_READ](context, bulletin_id) {
        console.log("POST_BULLETIN_READ");
        return new Promise((resolve, reject) => {
            ApiService.post(`/bulletins/${bulletin_id}/read`)
                .then(({
                    data
                }) => {
                    context.commit(SET_BULLETIN_READ, bulletin_id);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    }
};

export default {
    getters,
    actions,
    mutations,
    state
}
