<template>
    <v-row
        justify="center"
        class="mb-2 d-print-none"
        v-if="bulletin"
    >
        <v-col
            cols="12"
        >
            <v-expansion-panels
                focusable
                popout
                multiple
                v-model="isRead"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header :class="bulletin.is_read ? 'py-0 shrink' : ''">
                        <span :class="bulletin.is_read ? '' : 'text-h6'">Service Update</span>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-html="bulletin.body" />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
import { POST_BULLETIN_READ } from "../../../store/actions.type";

export default {
    name: "MainBulletin",
    computed: {
        bulletin() {
            return this.$store.getters.getMainBulletin;
        },
        isRead: {
            get() {
                if (!this.bulletin) return [];
                return this.bulletin.is_read ? [] : [0];
            },
            set(value) {
                this.$store.dispatch(POST_BULLETIN_READ, this.bulletin.id);
            },
        },
    },
};
</script>

<style scoped>
.shrink {
    min-height:24px !important;
}
</style>
