<template>
    <v-row
        no-gutters
        justify="center"
        align="center"
    >
        <v-col
            v-if="!isCommercial"
            cols="12"
            md="4"
            class="py-4 py-md-0  text-h6 text-center"
        >
            <sportsbar />
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="py-0  text-center"
        >
            <span class="text-h6">
                Shop for:
                <a @click="residentialClickEvent">Residential</a>
                or
                <a @click="businessClickEvent">Business TV</a>
            </span>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="py-4 py-md-0 text-center"
        >
            <span class="text-h6">
                <a @click="sundayTicketClickEvent">NFL SUNDAY TICKET for Business</a>
            </span>
        </v-col>

    </v-row>
</template>

<script>
import Sportsbar from '../Home/Games/Game/Sportsbar';

export default {
    name: 'DtvAds',
    components: {
        Sportsbar,
    },
    computed: {
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
    },
    methods: {
        residentialClickEvent() {
            dataLayer.push({
                'event' : 'trackEvent',
                'event_name' : 'custom_link_click',
                'linkName' : 'residential',
                'linkPosition' : 'body',
                'linkDestination' : 'https://www.directv.com/sports/',
            });
            window.open("https://www.directv.com/sports/", "_blank");
        },
        businessClickEvent() {
            dataLayer.push({
                'event' : 'trackEvent',
                'event_name' : 'custom_link_click',
                'linkName' : 'business',
                'linkPosition' : 'body',
                'linkDestination' : 'https://www.directv.com/forbusiness/sports/',
            });
            window.open("https://www.directv.com/forbusiness/sports/", "_blank");
        },
        sundayTicketClickEvent() {
            dataLayer.push({
                'event' : 'trackEvent',
                'event_name' : 'custom_link_click',
                'linkName' : 'sunday_ticket',
                'linkPosition' : 'body',
                'linkDestination' : 'https://www.directv.com/forbusiness/nfl-sunday-ticket/',
            });
            window.open("https://www.directv.com/forbusiness/nfl-sunday-ticket/", "_blank");
        }
    }
}
</script>
