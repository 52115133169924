<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <rect width="100" height="100" :fill="color" rx="10" ry="10" />
      <text
        x="50"
        y="55"
        font-family="Roboto, sans-serif"
        fill="black"
        stroke="white"
        stroke-width="6"
      >
        {{ teamName.charAt(0) }}
      </text>
    </svg>
  </template>

  <script>
  export default {
    name: "NflWinProbLogo",
    props: {
      teamName: String,
      color: String,
    },
  };
  </script>

  <style scoped>
  text {
    font-size: 60px;
    text-anchor: middle;
    dominant-baseline: middle;
    paint-order: stroke fill;
  }
  </style>
