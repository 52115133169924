<template>
    <v-card>
        <v-card-title>What are Thuuz Ratings?</v-card-title>
        <v-card-text>
            Thuuz uses ratings to make it easy for you to tell which games are most exciting. Using algorithms and social
            signals, Thuuz rates all live (and upcoming) games on a scale of 0-100.

            <v-row dense v-for="thuuzRating in thuuzRatings" :key="thuuzRating.end" align="center" class="mt-4">
                <v-col cols="auto">
                    <v-btn fab :color="thuuzRating.color" class="font-weight-bold text-h5">
                        {{ thuuzRating.start }}
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="text-h6">
                    to
                </v-col>
                <v-col cols="auto">
                    <v-btn fab :color="thuuzRating.color" class="font-weight-bold text-h5">
                        {{ thuuzRating.end }}
                    </v-btn>
                </v-col>
                <v-col class="text-h6">
                    {{ thuuzRating.text }}
                </v-col>
            </v-row>

        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn @click="$emit('done')">
                Close
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "HypeExplainer",
    data() {
        return {
            thuuzRatings: [
                {
                    start: 85,
                    end: 100,
                    color: 'thuuzGreat',
                    text: 'Great Game'
                },
                {
                    start: 65,
                    end: 84,
                    color: 'thuuzGood',
                    text: 'Good Game'
                },
                {
                    start: 40,
                    end: 64,
                    color: 'thuuzOk',
                    text: 'OK Game'
                },
                {
                    start: 0,
                    end: 39,
                    color: 'thuuzDull',
                    text: 'Dull Game'
                },
            ]
        }
    },
}
</script>
