<template>
  <v-row no-gutters style="flex-wrap: nowrap;">
    <v-col class="flex-grow-1 flex-shrink-1">
        <component :is="option.component" :option="option" />
    </v-col>
    <v-col v-if="option.configurable" class="flex-grow-0 flex-shrink-1">
        <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn class="ma-2" fab dark small color="blue" v-on="on" @click="dialog=true">
              <v-icon dark>{{ option.icon ? option.icon : 'mdi-wrench' }}</v-icon>
            </v-btn>
        </template>
        <span>{{ option.config_tooltip ? option.config_tooltip : 'Customize...' }}</span>
        </v-tooltip>
        <v-dialog v-model="dialog" scrollable max-width="600">
          <component :is="option.modal" @done="dialog = false" />
        </v-dialog>
    </v-col>
  </v-row>

</template>

<script>
import LeagueOrder from "./LeagueOrder";
import Dropdown from "./Dropdown";
import Textfield from "./Textfield";
import Toggle from "./Toggle";
import Timezone from "./Timezone";

export default {
  name: "Field",
  props: {
      option: Object
  },
  components: {
    LeagueOrder,
    Dropdown,
    Textfield,
    Toggle,
    Timezone
  },
  data() {
    return {
      dialog: false
    }
  },
};
</script>
