<template>
  <v-row no-gutters class="pt-3">
    <v-col>
      <v-expansion-panels v-model="openPanels" accordion multiple>
        <v-expansion-panel v-if="isMobile">
          <v-expansion-panel-header> MORE GAME INFO </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-for="(panel, panel_index) in panels"
              :key="panel_index"
            >
              <component v-if="checkHas(panel.component)" :ref="panel.component.toLowerCase()" :is="panel.component" :game="game" />
              <v-row no-gutters v-if="checkHas(panel.component) && panel_index != panels.length-1">
                <v-col class="mx-5 py-1"><v-divider /></v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-else
          v-for="(panel, panel_index) in panels"
          :key="panel_index"
          :class="panel.class"
          :disabled="!checkHas(panel.component)"
        >
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col>
                <span class="panel-text">
                  {{ panel.name }}
                </span>
                <template v-if="!checkHas(panel.component)">
                  <br />
                  <span class="caption">
                    {{ panel.name }} information not available.
                  </span>
                </template>
              </v-col>
              <v-col class="text-center" v-if="checkHas(panel.component)">
                <v-avatar
                  size="18"
                  :color="thuuzColor(game.thuuz_game.gex_default)"
                  v-if="panel.component == 'Hype'"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <component :ref="panel.component.toLowerCase()" :is="panel.component" :game="game" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import Hype from "./Hype";
import BettingOdds from "./BettingOdds";
import Showings from "./Showings";

export default {
  name: "Panels",
  components: {
    Hype,
    BettingOdds,
    Showings,
  },
  props: {
    game: Object,
  },
  computed: {
    hasHype() {
      return !!this.game.thuuz_game;
    },
    hasBettingOdds() {
      return this.game.betting_odds && this.game.betting_odds;
    },
    hasShowings() {
      return !!this.game.showings && this.game.showings.length > 0;
    },
  },
  data() {
    return {
      openPanels: [],
      panels: [
        {
          name: "GAME HYPE",
          component: "Hype",
          class: "lightGray",
        },
        {
          name: "BETTING ODDS",
          component: "BettingOdds",
          class: "",
        },
        {
          name: "PROGRAMMING INFO",
          component: "Showings",
          class: "",
        },
      ],
    };
  },
  methods: {
    checkHas(component) {
      let method = "has" + component;
      return this[method];
    },
  },
};
</script>
<style lang="scss" scoped>
.panel-text {
  font-size: 1.2em;
  font-weight: 100;
}
</style>
