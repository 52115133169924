import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_SPORTS,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_SPORTS,
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        sports: [],
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getCurrentSports(state) {
        return state.sports;
    },
    getCurrentLeagues(state) {
        return state.sports.map(sport => sport.leagues).flat();
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SPORTS](state, data) {
        console.log("SET_SPORTS");
        state.sports = data;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_SPORTS](context) {
        console.log("GET_SPORTS");
        if(context.getters.getCurrentSports.length > 0) {
            return;
        }
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/options/leagues")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SPORTS, data.sports
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'leagues',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    

};

export default {
    getters,
    actions,
    mutations,
    state
}
