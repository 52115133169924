<template>
    <div>
        <v-row>
            <v-col cols="12">
                Positions:
                <v-tooltip
                    v-for="(position, index) in positions"
                    :key="index"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                            @click="selectedPosition = position.abbreviation"
                            mandatory
                            class="ma-2"
                            :color="position.abbreviation === selectedPosition ? 'primary' : ''"
                        >
                            {{ position.abbreviation }}
                        </v-chip>
                    </template>
                    <span>{{ position.name }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- Player Data Table -->
        <v-data-table
            :headers="playerHeaders"
            :items="filteredPlayers"
            :item-class="rowClass"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="search"
                    label="Search"
                    class="mx-4"
                />
            </template>
            <template v-slot:item.positions="{ item }">
                <span
                    v-for="(position, index) in item.positions"
                    :key="index"
                >
                    {{ position.abbreviation }}
                </span>
            </template>
        </v-data-table>
    </div>

</template>

<script>
export default {
    name: "NFL",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            selectedPosition: "All",
            playerHeaders: [
                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' },
                { text: 'Team', value: 'team.nickname' },
                { text: 'Position', value: 'positions[0].abbreviation' },
                { text: 'Draft Year', value: 'draft.year' },
            ],
        };
    },
    computed: {
        positions() {
            const uniqueAbbreviations = new Set(this.league.players.flatMap(p => p.positions.map(pos => pos.abbreviation)));
            const positions = Array.from(uniqueAbbreviations).map(abbreviation => {
                return this.league.players.flatMap(p => p.positions).find(pos => pos.abbreviation === abbreviation);
            });
            positions.unshift({ abbreviation: 'All', name: 'All Positions' });
            return positions;
        },
        filteredPlayers() {
            if (this.selectedPosition === 'All') {
                return this.league.players;
            }
            return this.league.players.filter(player => player.positions.some(pos => pos.abbreviation === this.selectedPosition));

        }
    },
    methods: {
        positionsString(positions) {
            return positions.map(p => p.abbreviation).join(', ');
        }
    },
};

</script>
