import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_LEAGUE_STANDINGS,
    GET_LEAGUE_PLAYERS,
    GET_TEAM_PLAYERS,
    GET_LEAGUE_COACHES,
    GET_LEAGUE_DEPTH_CHARTS,
    GET_LEAGUE_FANTASY_PROJECTIONS,
    GET_LEAGUE_LEADERS,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    CLEAR_STATS_DATA,
    SET_STATS_DATA
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        statsData: null,
        loading: true,
        errors: {
            statsdata: [],
        }
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    currentStatsData(state) {
        return state.statsData;
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [CLEAR_STATS_DATA](state) {
        state.statsData = null;
    },
    [SET_STATS_DATA](state, data) {
        state.statsData = data;
    },
    [SET_ERROR](state, {
        target,
        message
    }) {
        state.errors[target].push(message);
    },
    [CLEAR_ERRORS](state) {
        state.errors = {
            statsData: [],
        };
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_LEAGUE_STANDINGS](context, league) {
        console.log("GET_LEAGUE_STANDINGS", league);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/standings`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_LEAGUE_PLAYERS](context, league) {
        console.log("GET_LEAGUE_PLAYERS", league);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/players`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_TEAM_PLAYERS](context, { league, team }) {
        console.log("GET_TEAM_PLAYERS", league, team);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/team/${team}/players`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_LEAGUE_COACHES](context, league) {
        console.log("GET_LEAGUE_COACHES", league);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/coaches`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_LEAGUE_DEPTH_CHARTS](context, league) {
        console.log("GET_LEAGUE_DEPTH_CHARTS", league);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/depth-charts`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_LEAGUE_FANTASY_PROJECTIONS](context, league) {
        console.log("GET_LEAGUE_FANTASY_PROJECTIONS", league);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/fantasy-projections`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_LEAGUE_LEADERS](context, league) {
        console.log("GET_LEAGUE_LEADERS", league);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/league/${league}/leaders`)
                .then(({
                    data
                }) => {
                    if (true) {
                        context.commit(CLEAR_ERRORS);
                        context.commit(
                            SET_STATS_DATA, data
                        );
                    }
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    console.log(response);
                    context.commit(
                        SET_ERROR, {
                        target: 'stats',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    }
};

export default {
    getters,
    actions,
    mutations,
    state
}
