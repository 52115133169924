<template>
    <div>
        Timezone: {{ timezoneString }}
        <br />
        Timezones are set by the zipcode option and may require a browser refresh to update.
    </div>
</template>

<script>
export default {
    name: "Timezone",
    props: {
        option: Object
    },
    computed: {
        timezoneString() {
            //Clean up the timezone string
            //Example string: "America/New_York"
            //Desired output: "New York"
            return this.option.selectedOptions.split("/")[1].replace("_", " ");
        },
    },
};
</script>

<style scoped></style>
