<template>
  <v-app>
    <component :is="currentLayout" v-if="hasLayout">
        <router-view></router-view>
    </component>
    <router-view v-else />
    <dtv-footer class="d-print-none" v-if="(!isFramed && !isAnyMenuOpen && $route.meta.showfooter)" />
    <v-dialog v-model="authDialog" width="500" scrollable content-class="top-align-dialog">
      <login-form v-if="currentForm === 'login'" />
      <register-form v-if="currentForm === 'register'" />
    </v-dialog>
    <v-dialog v-model="countyDialog" width="500" content-class="top-align-dialog" persistent>
        <county />
    </v-dialog>
  </v-app>
</template>

<script>
import Base from './Layouts/Base';
import Details from './Layouts/Details';

import DtvFooter from './shared/DtvFooter';
import LoginForm from "./shared/LoginForm";
import RegisterForm from "./shared/RegisterForm";
import County from "./Home/Filters/County";

import { UPDATE_FORM, TOGGLE_AUTH_DIALOG, TOGGLE_COUNTY_DIALOG, LOGOUT } from "../store/actions.type";

export default {
  name: "App",
  components: {
    Base,
    Details,
    LoginForm,
    RegisterForm,
    DtvFooter,
    County
  },
  computed: {
    /* auth */
    currentForm() {
      return this.$store.getters.currentForm;
    },
    authDialog: {
      get: function () {
        return this.$store.getters.currentAuthDialogState;
      },
      // setter
      set: function () {
        this.$store.dispatch(TOGGLE_AUTH_DIALOG);
      },
    },
    countyDialog: {
      get: function () {
        return this.$store.getters.isCountyDialogOpen;
      },
      // setter
      set: function () {
        this.$store.dispatch(TOGGLE_COUNTY_DIALOG);
      },
    },
    /* end auth */
    isFiltersMenuOpen() {
      return this.$store.getters.isFiltersMenuOpen;
    },
    isSportsMenuOpen() {
      return this.$store.getters.isSportsMenuOpen;
    },
    isAnyMenuOpen() {
      return this.isFiltersMenuOpen || this.isSportsMenuOpen || this.isLeaguesModalOpen;
    },
    isLeaguesModalOpen() {
      return this.$store.getters.isLeaguesModalOpen;
    },
    currentLayout() {
      return this.$route.meta.layout;
    },
    hasLayout() {
      return this.currentLayout !== undefined;
    },
  },
  methods: {
    login() {
      this.$store.dispatch(UPDATE_FORM, "login").then(() => {
        this.$store.dispatch(TOGGLE_AUTH_DIALOG);
      });
    },
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "home" }));
    },
  }
};
</script>

<style scoped>
.v-application {
  background-color: #FFFFFF !important;
}
</style>
