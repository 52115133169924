<template>
    <v-row justify="center">
        <v-col cols="auto" class="text-center text-h5 px-0">
            <strong>{{ game.away_score }}</strong>
        </v-col>
        <v-col cols="auto" class="text-center mt-1">
            -
        </v-col>
        <v-col cols="auto" class="text-center text-h5 px-0">
            <strong>{{ game.home_score }}</strong>
        </v-col>
    </v-row>
</template>

<script>
export default {
  name: "Score",
  props: {
    game: Object,
  },
};
</script>

<style scoped>
.details {
  font-size: 0.7rem;
  height: 10px;
  font-weight: bold;
}
</style>
