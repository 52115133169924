<template>
    <div>
        <v-autocomplete
            dense
            v-model="selectedAddons"
            class="input-field"
            hide-details="auto"
            :label="option.name"
            :items="addons"
            item-text="title"
            item-value="id"
            outlined
            rounded
            :loading="loading"
            :multiple="true"
        >
            <template v-slot:selection="{ index }">
                <template v-if="selectedAddons.length == 1">
                    <span v-if="index === 0">
                        {{ searchArrayByKey(selectedAddons[index], addons, 'id', 'object').title }}
                    </span>
                </template>
                <template v-else-if="selectedAddons.length > 1">
                    <span v-if="index === 0">
                        {{ selectedAddons.length }} selected
                    </span>
                </template>
                <template v-else>
                    <span>No addons selected.</span>
                </template>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import Loading from "../../shared/Loading";
import Subfield from "../Subfield";

import { SET_SELECTED_ADDONS } from "../../../store/mutations.type";

export default {
    name: "Addons",
    components: {
        Loading,
        Subfield,
    },
    data() {
        return {
            loading: false,
        };
    },
    props: {
        option: Object
    },
    computed: {
        selectedAddons: {
            get() {
                return this.$store.getters.getSelectedAddons;
            },
            set(value) {
                this.$store.commit(SET_SELECTED_ADDONS, value);
            },
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        addons() {

            //Addons ordered by title
            if (this.$store.getters.getAddons.length == 0) return [];

            let addons = this.$store.getters.getAddons.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });

            //Filter the addons array if isCommercial is true, and the addons property "commercial" is true
            if (this.isCommercial) {
                addons = addons.filter((addon) => addon.commercial);
            } else {
                addons = addons.filter((addon) => addon.residential);
            }



            return addons;
        },
    },
};
</script>

<style scoped>
.v-text-field--outlined>>>.v-select__selection {
    /*color: #196DD4;*/
    padding: 16px !important;
    font-size: 1.2em;
    text-transform: uppercase;
}
.v-text-field--outlined>>>.v-select__slot {
    height:66px;
    align-items: unset;
}

.v-text-field--outlined>>>.v-select__selections {
    /*color: #196DD4;*/
    padding: 16px !important;
    font-size: 1.2em;
    height:66px;
    text-transform: uppercase;
}

.v-text-field--outlined>>>.v-label {
    /*color: #196DD4;*/
    font-size: 1.5em;
    font-weight: bold;
}

.v-text-field--outlined>>>.v-label:not(.v-label--active) {
    height: 32px;
    padding: 12px;
}

.v-text-field--outlined>>>fieldset {
    /*border-color: #196DD4;*/
    border-width: 3px;
}
</style>
