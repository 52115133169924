<template>
  <v-row>
    <v-col class="py-1">
      <div class="text-subtitle-1">Chosen League Order</div>
      <div class="text-caption">
        <span v-if="option.selectedOptions.length > 0">
          Drag and drop leagues to choose your order of priority
        </span>
        <span v-else>
          Choose leagues from the menu on the right
        </span>
      </div>
      <v-list dense>
        <draggable v-model="option.selectedOptions" group="leagues">
          <v-list-item
            v-for="selectedOption in option.selectedOptions"
            :key="selectedOption"
          >
            <v-list-item-icon>
              <v-icon>mdi-drag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="getLeagueName(selectedOption)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </draggable>
      </v-list>
    </v-col>
    <v-col>
      <v-autocomplete
        dense
        v-model="option.selectedOptions"
        class="input-field"
        hide-details="auto"
        label="LEAGUES"
        :items="leagues"
        item-text="title"
        item-value="id"
        outlined
        :multiple="true"
      >
        <template v-slot:selection="{ index }">
          <span v-if="index === 0">
            {{ option.selectedOptions.length }} league{{
              option.selectedOptions.length > 1 ? "s" : ""
            }}
            selected
          </span>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "LeagueOrder",
  props: {
    option: Object,
  },

  components: {
    draggable,
  },
  computed: {
    leagues() {
      let leagueGroups = this.$store.getters.getCurrentSports;
      let leagues = [];
      for (const [key, value] of Object.entries(leagueGroups)) {
        leagues.push({ header: value.title });
        leagues = leagues.concat(value.leagues);
      }
      return leagues;
    },
  },
  methods: {
    getLeagueName(id) {
      return this.searchArrayByKey(id, this.leagues, "id").title;
    },
  },
};
</script>


<style scoped>
.v-text-field--outlined >>> .v-select__selection {
  color: #196DD4;
}

.v-text-field--outlined >>> .v-label {
  color: #196DD4;
  font-weight: bold;
}

.v-text-field--outlined >>> fieldset {
  border-color: #196DD4;
}
</style>
