<template>
  <div>
    <sport-bar v-if="!isMobile">
        <v-col class="text-center sport-header mt-3">
            LIVE SPORTS SCHEDULE MANAGEMENT
            <br />
            {{  user.name }}
        </v-col>
    </sport-bar>
    <template v-else>
        <logo-bar />
        <v-row>
            <v-col class="text-center">
                <h2>LIVE SPORTS SCHEDULE MANAGEMENT</h2>
            </v-col>
        </v-row>

    </template>

    <v-container>
      <v-row class="fill-height d-print-none mt-16">
        <v-col class="mt-16">
          <v-overlay :value="loading">
            <v-progress-circular
              :size="70"
              :width="7"
              indeterminate
            />
          </v-overlay>
          <div v-if="!loading && groups.length">
            <v-row class="mb-4">
              <v-col cols="4">
                <v-btn
                  large
                  color="success"
                  to="/"
                >
                  <v-icon left>mdi-home</v-icon> Home
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  large
                  color="success"
                  @click="addGroupDialog = true"
                >
                  <v-icon left>mdi-plus</v-icon> Create New Location
                </v-btn>
              </v-col>
              <v-spacer />

              <v-col
                cols="4"
                id="help_columns"
                class="text-right"
              >
                <v-btn
                  large
                  color="success"
                  @click="embedModal = true"
                >
                  <v-icon left>mdi-cog</v-icon> Configure Embed
                </v-btn>


              </v-col>

            </v-row>
            <v-card
              class="blue mb-5"
              v-for="(group, group_index) in groups"
              v-bind:key="group.id"
            >
              <v-row v-if="group.screens && group.screens.length">
                <v-col>
                  <v-sheet height="64">
                    <v-row
                      id="help_date"
                      class="blue ma-0"
                      align="center"
                      justify="space-between"
                    >
                      <v-col cols="2">
                        <v-btn
                          dark
                          outlined
                          class="mr-4"
                          @click="setToday"
                          v-if="!isTodaysDate"
                        >
                          Go to Today
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          fab
                          small
                          color="primary"
                          class="mr-3"
                          v-if="!isTodaysDate"
                          @click="prev"
                        >
                          <v-icon small> mdi-chevron-left </v-icon>
                        </v-btn>
                        <span class="text-h5 white--text">
                          {{ currentDate }}
                        </span>
                        <v-btn
                          fab
                          small
                          color="primary"
                          class="ml-3"
                          v-if="!isLastDate"
                          @click="next"
                        >
                          <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="2"> </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="center"
                class="white--text"
              >
                <v-spacer />
                <v-col
                  cols="auto"
                  class="text-h5"
                > Location: </v-col>
                <v-col
                  cols="auto"
                  id="help_group_name"
                >
                  <v-text-field
                    dark
                    class="centered-input"
                    single-line
                    v-model="group.name"
                    label="Group Name"
                    :error="groupErrors.length"
                    :error-messages="groupErrors && groupErrors.name ? groupErrors.name : null
                      "
                    @change="updateGroup(group)"
                  >
                    <template v-slot:append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            id="help_group_config"
                            depressed
                            tile
                            v-bind="attrs"
                            v-on="on"
                            :color="group.settings && group.settings.length
                              ? 'warning'
                              : 'info'
                              "
                            class="ma-0"
                            @click="configureGroup(group)"
                          >
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <span>Configure Entire Location</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            id="help_group_embed"
                            depressed
                            tile
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                            class="ma-0"
                            @click="copyEmbedCode(group)"
                          >
                            <v-icon>mdi-xml</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy Embed Code</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>

                <v-spacer />
              </v-row>
            </v-card>
            <v-row>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  large
                  color="success"
                  @click="addGroupDialog = true"
                >
                  <v-icon left>mdi-plus</v-icon> Create New Location
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </div>
          <div v-if="!loading && !!!groups.length">
            <v-row>
              <v-col class="text-center text-h5">
                No screen group has been added yet. Please
                <v-btn
                  large
                  color="success"
                  @click="addGroupDialog = true"
                >
                  <v-icon left>mdi-plus</v-icon> Add a Location
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <print />


      <v-dialog
        v-model="addGroupDialog"
        max-width="560"
      >
        <add-group-modal @done="
          resetData();
          scrollToBottom();
        " />
      </v-dialog>

      <v-dialog
        v-model="addScreenDialog"
        max-width="560"
      >
        <add-screen-modal
          :group="currentGroup"
          @done="resetData()"
        />
      </v-dialog>

      <v-dialog
        max-width="800"
        v-model="configureDialog"
        scrollable
        persistent
      >
        <configure-modal
          :object="currentObject"
          :type="currentType"
          @delete="deleteObject(type, object)"
          @done="resetData()"
        />
      </v-dialog>

      <v-dialog
        v-model="priorityDialog"
        max-width="800"
        @keydown.esc="cancelPriority"
      >
        <v-card>
          <v-toolbar
            dark
            dense
            flat
          >
            <v-toolbar-title class="white--text">Choose Showing Priority</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 text-center">
            These showings overlap. Please select your priority showing.
            <br />
            Preferred showings will be played in entirety, inturrupting other
            marked showings.
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >mdi-help-circle</v-icon>
              </template>
              <v-card color="primary">
                <v-card-text class="white--text">
                  If a preferred showing ends after another marked showing,
                  the preferred showing will play to the end, then switch to the
                  non-preferred marked showing.
                  <br /><br />
                  If a preferred showing starts before another marked showing
                  ends, the perferred showing will inturrupt the marked
                  showing once it begins.
                  <br />
                  <br />
                  Generally, you will want to choose the showing that starts
                  first as priority, so the end of that showing is seen.
                </v-card-text>
              </v-card>
            </v-tooltip>
          </v-card-text>
          <v-row class="mx-3">
            <v-col v-if="favoritedGame">
              <div class="text-center text-h5">To be preferred</div>
              <v-card
                @click.native="agreePriority(favoritedGame.id)"
                :color="getGameColor(favoritedGame)"
                :class="getGameTextColor(favoritedGame) + '--text pa-2'"
              >
                <v-icon
                  :color="getGameTextColor(favoritedGame)"
                  small
                >
                  {{ favoritedGame.sportIcon }}
                </v-icon>
                {{ favoritedGame.leagueTitle }}
                <br />
                {{ favoritedGame.name }}
                <br />
                {{ getDisplayTimePlain(favoritedGame) }}
              </v-card>
            </v-col>
            <v-col v-if="alreadyFavorited">
              <div class="text-center text-h5">Already favorited</div>
              <v-card
                @click.native="agreePriority(alreadyFavorited.id)"
                :color="getGameColor(alreadyFavorited)"
                :class="getGameTextColor(alreadyFavorited) + '--text pa-2'"
              >
                <v-icon
                  :color="getGameTextColor(alreadyFavorited)"
                  small
                >
                  {{ alreadyFavorited.sportIcon }}
                </v-icon>
                {{ alreadyFavorited.leagueTitle }}
                <br />
                {{ alreadyFavorited.name }}
                <br />
                {{ getDisplayTimePlain(alreadyFavorited) }}
              </v-card>
            </v-col>
          </v-row>

          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              color="darkGray"
              text
              @click.native="cancelPriority"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="embedModal"
        max-width="800"
      >
        <embed-modal @done="embedModal = false" />
      </v-dialog>
      <help
        ref="help"
        @action="helpAction"
      />
    </v-container>
  </div>
</template>

<script>
import LogoBar from "../components/Home/LogoBar";

import SportBar from "../components/Home/Filters/SportBar";

import ConfigureModal from "../components/Scheduler/ConfigureModal";

import AddGroupModal from "../components/Scheduler/AddGroupModal";

import AddScreenModal from "../components/Scheduler/AddScreenModal";

import EmbedModal from "../components/Scheduler/EmbedModal";

import Help from "../components/Scheduler/Help";

import Print from "../components/Scheduler/Print";

import {
  GET_GROUPS,
  GET_SCREEN_GAMES,
  GET_OPTIONS,
  UPDATE_GROUP,
  UPDATE_SCREEN,
  POST_SELECTED_OPTION,
  FAVORITE_GAME,
  PREFER_GAME,
  UNFAVORITE_GAME,
  GET_SPORTS
} from "../store/actions.type";

export default {
  name: "Planner",
  components: {
    LogoBar,
    SportBar,
    ConfigureModal,
    AddGroupModal,
    AddScreenModal,
    EmbedModal,
    Help,
    Print,
  },
  data: () => ({
    //Config
    addGroupDialog: false,
    configureDialog: false,
    addScreenDialog: false,
    embedModal: false,
    currentGroup: null,
    currentScreen: null,
    currentType: null,
    view: "all",
    emojiEnabled: true,
    //Calendar
    calcols: 1,
    columns: true,
    focus: null,
    //Priority
    priorityDialog: false,
    resolve: null,
    reject: null,
    favoritedGame: null,
    alreadyFavorited: null,
    chosenGame: null,
  }),
  async mounted() {
    this.focus = this.todaysDate;
    await this.$store.dispatch(GET_GROUPS, this.focus);
    await this.$store.dispatch(GET_OPTIONS);
    await this.$store.dispatch(GET_SPORTS);
    await this.updateGames();
  },
  computed: {
    user() {
        return this.$store.getters.currentUser;

    },
    groupErrors() {
      return this.$store.getters.getErrors("groups");
    },
    loading() {
      return this.$store.getters.getGroupsLoading;
    },
    groups() {
      return this.$store.getters.getGroups;
    },
    allGames() {
      return this.$store.getters.getScreenGames.filter(
        (game) => game.showings.length
      );
    },
    todaysDate() {
      return moment().format("YYYY-MM-DD");
    },
    isTodaysDate() {
      return this.focus == this.todaysDate;
    },
    isLastDate() {
      return moment(this.focus).isSame(moment(this.todaysDate).add(7, "day"));
    },
    currentBusinessHours() {
      return this.$store.getters.getCurrentSelectedOptions('hours');
    },
    firstInterval() {
      if (this.currentBusinessHours) {
        return parseInt(this.currentBusinessHours.split(":")[0]);
      }
      //Default 9AM-9PM
      return 9;
    },
    intervalCount() {
      if (this.currentBusinessHours) {
        return parseInt(this.currentBusinessHours.split("-")[1].split(":")[0]) - parseInt(this.currentBusinessHours.split(":")[0]);
      }
      //Default 9AM-9PM
      return 12;
    },
    currentDate() {
      return moment(this.focus).format("dddd, MMMM Do YYYY");
    },
    currentObject() {
      if (this.currentType) {
        return this["current" + this.currentType.capitalize()];
      }
      return null;
    },
    calcolsValue() {
      switch (this.calcols) {
        case 1:
          return 12;
        case 2:
          return 6;
        case 3:
          return 4;
        case 4:
          return 3;
        case 5:
          return 2;
      }
    },
    firstGame() {
      return this.allGames(0, this.groups[0].screens[0], 0)[0];
    },
    sports() {
      return this.$store.getters.getCurrentSports;
    },
    leagues() {
      return this.$store.getters.getCurrentLeagues;
    },
  },
  methods: {
    //Help
    helpAction(type) {
      switch (type) {
        case 'scheduled':
          this.view = "schedule";
          this.columns = false;
          break;
        case 'all':
          this.view = "all";
          this.columns = true;
          break;
        case 'suggested':
          this.view = "suggest";
          this.columns = false;
          break;

        case 'overlap_on':
          this.columns = false;
          break;

        case 'favorite_game':
          this.groups[0].screens[0].favorites.push(this.firstGame.id);
          break;

        case 'prefer_game':
          this.groups[0].screens[0].preferred.push(this.firstGame.id);
          break;

        case 'clear_favorites':
          this.groups[0].screens[0].favorites = [];
          this.groups[0].screens[0].preferred = [];
          break;

      }
    },

    //Config
    updateGroup(group) {
      this.$store.dispatch(UPDATE_GROUP, group);
    },

    addScreen(group) {
      this.currentGroup = group;
      this.addScreenDialog = true;
    },
    updateScreen(id, screens, name) {
      let screen = null;
      screen = this.searchArrayByKey(id, screens);

      screen.name = name;

      this.$store.dispatch(UPDATE_SCREEN, screen);
    },
    configureGroup(group) {
      this.currentGroup = structuredClone(group);
      this.currentType = "group";
      this.configureDialog = true;
    },
    configureScreen(id, screens) {
      let screen = null;
      screen = this.searchArrayByKey(id, screens);

      this.currentScreen = structuredClone(screen);
      this.currentType = "screen";
      this.configureDialog = true;
    },
    resetData() {
      this.addGroupDialog = false;
      this.deleteGroupDialog = false;

      this.addScreenDialog = false;
      this.deleteScreenDialog = false;

      this.configureDialog = false;

      this.currentGroup = null;
      this.currentScreen = null;
      this.currentType = null;

      this.updateGames();
    },
    categoryNameFromScreenID(id, screens) {
      return this.searchArrayByKey(id, screens, "id", "name");
    },
    screenConfigButtonColor(id, screens) {
      let screen = null;
      screen = this.searchArrayByKey(id, screens);
      return screen.settings && screen.settings.length ? "warning" : "info";
    },
    screenGames(group_index, screen, screen_index) {
      const forcedPreferred = [];
      const forcedFavorite = [];
      const regular = [];

      var screenGames = [];


      this.allGames.forEach((allGame) => {

        const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
        const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End

        //First we push preferred games
        if (
          screen.preferred &&
          screen.preferred.indexOf(allGame.id) >= 0
        ) {
          forcedPreferred.push(this.getCalendarFormattedGame(allGame, false, true, first, second, screen, group_index, screen_index));
          return;
        }

        //Then we push favorite games
        if (
          screen.favorites &&
          screen.favorites.indexOf(allGame.id) >= 0
        ) {
          forcedFavorite.push(this.getCalendarFormattedGame(allGame, true, false, first, second, screen, group_index, screen_index));
          return;
        }



        if (this.view == "all") {
          regular.push(this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index));
          return;
        }
      }); // End of first showing Loop (Forced and All)

      if (this.view == "schedule") {
        screenGames = forcedPreferred.concat(forcedFavorite);
        return screenGames;
      }

      //Make favorite games show up first, then regular
      if (this.view == "all") {
        screenGames = forcedPreferred.concat(forcedFavorite).concat(regular);
        return screenGames;
      }

      //Show suggested fill in
      if (this.view == "suggest") {
        this.allGames.forEach((allGame) => {

          const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
          const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End
          let ignore = false;

          //Ignore already preferred
          if (
            screen.preferred &&
            screen.preferred.indexOf(allGame.id) >= 0
          ) {
            return;
          }

          //Ignore already favorited
          if (
            screen.favorites &&
            screen.favorites.indexOf(allGame.id) >= 0
          ) {
            return;
          }

          //Check if conflicts with forced, ignore
          if (forcedPreferred.length) {

            forcedPreferred.forEach((forced_showing) => {
              const forced_start = moment(forced_showing.start); //Start
              const forced_end = moment(forced_showing.end); //End

              //Check overlap of forced games and ignore
              let range1 = moment.range(forced_start, forced_end);
              let range2 = moment.range(first, second);
              if (range1.overlaps(range2)) {
                ignore = true;
              }
            });

            if (ignore) return;
          }

          if (forcedFavorite.length) {
            forcedFavorite.forEach((forced_showing) => {
              const forced_start = moment(forced_showing.start); //Start
              const forced_end = moment(forced_showing.end); //End

              //Check overlap of forced games and ignore
              let range1 = moment.range(forced_start, forced_end);
              let range2 = moment.range(first, second);
              if (range1.overlaps(range2)) {
                ignore = true;
              }
            });

            if (ignore) return;
          }

          //Check if overlap of regular games and compare
          if (regular.length) {
            regular.forEach((regular_game, regular_index) => {
              const regular_start = moment(regular_game.start); //Start
              const regular_end = moment(regular_game.end); //End

              let range1 = moment.range(regular_start, regular_end);
              let range2 = moment.range(first, second);

              //If overlap, compare
              if (range1.overlaps(range2)) {
                var leaguesIndex = screen.settings
                  ? screen.settings.findIndex(
                    (setting) => setting.key === "leagues"
                  )
                  : null;
                //Does screen have leagues preferred?
                if (screen.settings && leaguesIndex >= 0) {

                  var gamePriority = screen.settings[leaguesIndex].selectedOptions.indexOf(allGame.league.id);
                  var regularPriority = screen.settings[leaguesIndex].selectedOptions.indexOf(regular_game.league_id);

                  if (
                    (regularPriority < 0 && gamePriority >= 0)
                    ||
                    (
                      gamePriority >= 0 &&
                      (gamePriority < regularPriority)
                    )
                  ) {
                    regular[regular_index] = this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index);
                    //Already pushed, so ignore rest
                    ignore = true;
                  } else {
                    ignore = true;
                  }
                } else {
                  //Overlaps for no reason, ignore
                  ignore = true;
                }
              }
            });
            if (ignore) return;
          }

          regular.push(
            this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
          );
          return;
        }); //End of suggested Loop

        screenGames = forcedPreferred.concat(forcedFavorite).concat(regular);
        screenGames.sort((a, b) => a.start.getTime() - b.start.getTime());

        //Fill in remaining gaps
        screenGames.some((listed_game, listed_game_index) => {

          if ((screenGames.length - 1) > listed_game_index && listed_game.end < screenGames[listed_game_index + 1].start) {
            var gap_start = listed_game.end;
            var gap_end = screenGames[listed_game_index + 1].start;

            console.log(gap_start, gap_end);


            //Fill with best possible match
            this.allGames.forEach((allGame) => {
              if (allGame.showings.length == 0) return;



              if (!!searchArrayByKey(allGame.id, screenGames, 'id')) {
                //Skip already added showings
                return;
              }

              const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
              const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End

              let gap_range = moment.range(gap_start, gap_end);
              let game_range = moment.range(first, second);

              //If overlap, compare
              if (game_range.overlaps(gap_range) && (first.isSameOrBefore(gap_start) && second.isSameOrAfter(gap_end))) {
                screenGames.splice(
                  listed_game_index + 1,
                  0,
                  this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
                );
                return;
              }
            });
            return;
          };
        });

        //Check for any more gaps
        screenGames.some((listed_game, listed_game_index) => {
          if ((screenGames.length - 1) > listed_game_index && listed_game.end < screenGames[listed_game_index + 1].start) {
            var gap_start = listed_game.end;
            var gap_end = screenGames[listed_game_index + 1].start;

            //Fill with best possible match
            this.allGames.forEach((allGame) => {
              if (allGame.showings.length == 0) return;

              if (!!searchArrayByKey(allGame.id, screenGames, 'id')) {
                //Skip already added games
                return;
              }

              const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
              const second = moment.tz(allGame.showings[0].ends_at, "UTC");  //End

              let gap_range = moment.range(gap_start, gap_end);
              let game_range = moment.range(first, second);

              //If overlap, compare, less restrictive
              if (game_range.overlaps(gap_range)) {
                screenGames.splice(
                  listed_game_index + 1,
                  0,
                  this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
                );
                return;
              }
            });

          }
        });

        //Last for any more gaps
        screenGames.some((listed_game, listed_game_index) => {
          if ((screenGames.length - 1) > listed_game_index && listed_game.end < screenGames[listed_game_index + 1].start) {
            var gap_start = listed_game.end;
            var gap_end = screenGames[listed_game_index + 1].start;


            //Fill with best possible match
            this.allGames.forEach((allGame) => {

              if (allGame.showings.length == 0) return;

              if (!!searchArrayByKey(allGame.id, screenGames, 'id')) {
                //Skip already added showings
                return;
              }

              const first = moment.tz(allGame.showings[0].starts_at, "UTC"); //Start
              const second = moment.tz(allGame.showings[0].ends_at, "UTC"); //End

              let gap_range = moment.range(gap_start, gap_end);
              let game_range = moment.range(first, second);

              //If overlap, compare, less restrictive
              if (game_range.overlaps(gap_range) && (second.isSameOrAfter(gap_end))) {
                screenGames.splice(
                  listed_game_index + 1,
                  0,
                  this.getCalendarFormattedGame(allGame, false, false, first, second, screen, group_index, screen_index)
                );
                return;
              }
            });
          }
        });



        return screenGames;
      }
    },
    getGameColor(game) {
      if (game.favorite || game.preferred) {
        return "success";
      }
      if (!game.sportId) {
        return "indego";
      }
      return searchArrayByKey(game.sportId, this.sports, 'id').background_color;
    },
    getGameTextColor(game) {
      if (game.favorite || game.preferred) {
        return "white";
      }
      if (!game.sportId) {
        return "black";
      }
      return searchArrayByKey(game.sportId, this.sports, 'id').text_color;
    },
    getDisplayTime(gameParsed) {
      return (
        moment(gameParsed.start.time, "hh:mm").format("h:mma") +
        "-" +
        moment(gameParsed.end.time, "hh:mm").format("h:mma")
      );
    },
    getDisplayTimePlain(game) {
      return (
        moment(game.start, "hh:mm").format("h:mma") +
        "-" +
        moment(game.end, "hh:mm").format("h:mma")
      );
    },
    updateGames() {
      this.$store.dispatch(GET_SCREEN_GAMES, this.focus);
    },
    setToday() {
      this.focus = this.todaysDate;
      this.$nextTick(function () {
        this.updateGames();
      });
    },
    prev() {
      this.focus = moment(this.focus).add(-1, "day").format("YYYY-MM-DD");
      this.$nextTick(function () {
        this.updateGames();
      });
    },
    next() {
      this.focus = moment(this.focus).add(1, "day").format("YYYY-MM-DD");
      this.$nextTick(function () {
        this.updateGames();
      });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async favoriteGame({ event }) {
      let gameToFavorite = event;
      var screen_id = parseInt(gameToFavorite.category);
      var gameToFavorite_id = parseInt(gameToFavorite.id);
      var date = this.focus;

      var group_index = gameToFavorite.group_index;
      var screen_index = gameToFavorite.screen_index;

      var screen = this.groups[group_index].screens[screen_index];

      var add_favorite = true;

      //If no favorites, just add it (nothing to compare)
      if (!screen.favorites) {
        //console.log("No favorites exist for this screen yet, so add it.");
        await this.$store.dispatch(FAVORITE_GAME, {
          screen_id,
          date,
          game_id:gameToFavorite_id,
        });
        return;
      }

      //If screen has favorites, and it is already favorited, just remove it.
      if (screen.favorites && screen.favorites.indexOf(gameToFavorite_id) >= 0) {
        //console.log("Favorite already exists, remove it.");
        await this.$store.dispatch(FAVORITE_GAME, {
          screen_id,
          date,
          game_id:gameToFavorite_id,
        });
        return;
      }

      //If screen has preferred, and it is already preferred, just remove it.
      if (screen.preferred && screen.preferred.indexOf(gameToFavorite_id) >= 0) {
        //console.log("Favorite already exists, remove it.");
        await this.$store.dispatch(PREFER_GAME, {
          screen_id,
          date,
          game_id:gameToFavorite_id,
        });
        return;
      }

      //Screen has favorites, and it is NOT already favorited, time for some magic.
      this.allGames.forEach(async (allGame) => {
        if (allGame.id == gameToFavorite_id) {
          //Skip allGame thats same as clicked gameToFavorite...
          return;
        }

        //Check if this all game is already a favorite or preferred
        if (screen.favorites.indexOf(allGame.id) >= 0 || screen.preferred.indexOf(allGame.id) >= 0) {
          //If so we have to do some compares
          const game_start = moment.tz(allGame.showings[0].starts_at, "UTC");
          const game_end = moment.tz(allGame.showings[0].ends_at, "UTC");

          const favorite_start = moment(gameToFavorite.start);
          const favorite_end = moment(gameToFavorite.end);

          //Check if same start/end
          if (
            favorite_start.isSame(game_end) || favorite_end.isSame(game_start)
          ) {
            console.log("Is same start = end, continue");
            return;
          }

          //Check if within 5 minutes, if so force removal of other games
          var startDifferenceDuration = moment.duration(game_start.diff(favorite_start));
          var startDifferenceDurationInMinutes = Math.abs(startDifferenceDuration.asMinutes());

          var endDifferenceDuration = moment.duration(game_end.diff(favorite_end));
          var endDifferenceDurationInMinutes = Math.abs(endDifferenceDuration.asMinutes());

          if (startDifferenceDurationInMinutes <= 5 && endDifferenceDurationInMinutes <= 5) {
            //console.log("Start and end time within 5 minutes, remove other");
            //Remove and add new favorite and finish (TODO: Remove any other overlaps?)
            await this.$store.dispatch(UNFAVORITE_GAME, {
              screen_id,
              date,
              game_id: allGame.id,
            });

            //Wait for nextTick to finish
            await this.$nextTick();

            return;
          }

          //Check which starts first

          if (favorite_start.isBefore(game_start)) {
            //Favorite Starts First
            //Does favorite end before showing? ignore
            if (favorite_end.isBefore(game_start)) {
              return;
            }
          } else {
            //Showing Starts First
            //Does showing end before favorite starts? ignore
            if (game_end.isBefore(favorite_start)) {
              return;
            }
          }

          //Favorite and showing overlap
          //console.log("Showings overlap after 30 minutes start.");
          this.favoritedGame = gameToFavorite;

          this.alreadyFavorited = this.getCalendarFormattedGame(allGame, true, false, game_start, game_end, screen, group_index, screen_index);

          //DIALOG HERE
          if (await this.openPriorityDialog()) {
            //Already preferred
            if (screen.preferred && screen.preferred.indexOf(this.chosenGame) >= 0) {
              return;
            }
            //prefer chosen showing
            await this.$store.dispatch(PREFER_GAME, {
              screen_id,
              date,
              game_id: this.chosenGame,
            });
          } else {
            //console.log("User Cancelled.");
            return;
          }

          this.favoritedGame = null;
          this.alreadyFavorited = null;
          this.chosenGame = null;
          return;
        } // End of checking if showing in loop is already favorited.
      }); // End Showing For Loop

      if (add_favorite) {
        await this.$store.dispatch(FAVORITE_GAME, {
          screen_id,
          date,
          game_id: gameToFavorite_id,
        });
      }

      //console.log("Finished Loop");
      return;
    },
    //Priority
    openPriorityDialog(title, message, options) {
      this.priorityDialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agreePriority(id) {
      this.chosenGame = id;
      this.resolve(true);
      this.priorityDialog = false;
    },
    cancelPriority() {
      this.resolve(false);
      this.priorityDialog = false;
    },
    copyEmbedCode(group) {
      let embedCode = `${location.protocol + '//' + location.host}/embed/${group.uid}`;
      navigator.clipboard.writeText(embedCode);
    },
    getCalendarFormattedGame(game, favorite, preferred, start, end, screen, group_index, screen_index) {
      let description = null;

      if (game.description) {
        description = game.description;
      } else {
        description = game.away_team.display_name_short + " @ " + game.home_team.display_name_short;
      }

      return {
        id: game.id,
        name: description,
        sportId: game.sport.id,
        sportTitle: game.sport.title,
        sportIcon: game.sport.icon,
        sportEmoji: game.sport.emoji,
        leagueId: game.league_id,
        favorite: favorite,
        preferred: preferred,
        start: start.toDate(),
        end: end.toDate(),
        leagueTitle: game.league.title,
        timed: true,
        category: screen.id.toString(),
        group_index,
        screen_index,
      };
    }
  },
  watch: {
    showAllGames: function () {
      let key = "days";
      let options = this.focus;

      //Set day to todays date
      this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
        // this.$gtag.game("api", {
        //   game_category: "options",
        //   game_label: "get_games",
        // });

        //this.$store.dispatch(GET_GAMES);
      });
    },
  },
};
</script>

<style scoped>
.centered-input>>>input {
  text-align: center;
  font-size: 1.4em;
}

.v-calendar>>>.v-calendar-daily_head-weekday {
  display: none !important;
}

.v-tooltip__content {
  border: 1px solid black !important;
  background: black;
  padding: 2px;
}

@media print {
  .v-tooltip {
    display: none;
  }
}
</style>
