<template>
  <v-switch inset :input-value="selectedOptions" :label="option.name" :loading="loading" @change="updated" hide-details dense></v-switch>
</template>

<script>
import { POST_SELECTED_OPTION, GET_GAMES } from "../../../store/actions.type";

export default {
  name: "Toggle",
  props: {
    option: Object
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    selectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
  },
  methods: {
    updated(options) {
      let key = this.option.key;
    //   this.$gtag.event("api", {
    //     event_category: "options",
    //     event_label: "post_selected_option",
    //     value: options
    //   });
      this.$store
      .dispatch(POST_SELECTED_OPTION, { key, options })
      .then(() => {
        this.loading = false;

      });
    }
  }
};
</script>
