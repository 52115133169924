<template>
  <div>
    <span
      :style="$vuetify.breakpoint.smAndUp ? 'font-size:1.2em;' : ''"
      v-if="game.game_status_id == 1"
    >
      {{ gameTime }}
    </span>
    <template v-if="!isMobile || layout == 1">
      <span
        v-if="game.game_status_id !== 1"
        :style="$vuetify.breakpoint.smAndUp ? 'font-size:1.2em;' : ''"
        :class="[
          layout == 1 ? 'details-compact' : 'details',
          `${game.game_status.color}--text`
        ]"
      >
        {{ game.game_status_id == 2 && game.game_status_reason ? game.game_status_reason : game.game_status.name }}
      </span>
      <span
        v-if="game.showings.length > 0 && !game.showings[0].live"
        class="details"
      >
        {{ game.showings[0].highlight ? ' highlights from ' : ' replay from ' }}
        {{ gameDate }}
      </span>

    </template>
  </div>
</template>

<script>
export default {
  name: "TimeDisplay",
  props: {
    game: Object,
  },
  computed: {
    timezone() {
      return this.$store.getters.getCurrentSelectedOptions('timezoneString');
    },
    gameDate() {
      let timeToUse = null;
      if (!!this.game.showings && this.game.showings.length > 0) {
        if (this.game.showings[0].live == 1) {
          timeToUse = this.game.starts_at;
        } else {
          timeToUse = this.game.showings[0].display_at;
        }
      } else {
        timeToUse = this.game.starts_at;
      }
      return moment(timeToUse).tz(this.timezone).format("l");
    },
    gameTime() {
      let timeToUse = null;
      if (!!this.game.showings && this.game.showings.length > 0) {
        if (this.game.showings[0].live == 1) {
          timeToUse = this.game.starts_at;
        } else {
          timeToUse = this.game.showings[0].display_at;
        }
      } else {
        timeToUse = this.game.starts_at;
      }
      return moment(timeToUse).tz(this.timezone).format("h:mm A");
    },
    layout() {
      return this.$store.getters.getCurrentSelectedOptions("layout");
    },
  },
};
</script>

<style scoped>
.details {
  font-size: 0.7rem;
  height: 10px;
}

.details-compact {
  font-size: 1rem;
}</style>