export const CLEAR_ERRORS_ACTION = 'clearErrorsAction';

export const UPDATE_FORM = 'updateForm';

export const CHECK_AUTH = 'checkAuth';
export const LOGIN = 'login';
export const REGISTER = 'register';
export const LOGOUT = 'logout';
export const EMAIL_PASSWORD_RESET = 'emailPasswordReset';
export const RESET_PASSWORD = 'resetPassword';

export const TOGGLE_AUTH_DIALOG = 'toggleAuthDialog';


export const GET_GAMES = 'getGames';
export const GET_EMBED_GAMES = 'getEmbedGames';
export const GET_SEASON_GAMES = 'getSeasonGames';

export const GET_SHOWINGS = 'getShowings';
export const GET_SEASON_SHOWINGS = 'getSeasonShowings';

export const GET_OPTIONS = 'getOptions';
export const GET_MONTHS = 'getMonths';
export const GET_SELECTED_OPTIONS = 'getSelectedOptions';
export const POST_SELECTED_OPTION = 'postSelectedOption';
export const POST_PARAM_OPTIONS = 'postParamOptions';
export const TOGGLE_COUNTY_DIALOG = 'toggleCountyDialog';

export const GET_SPORTS = 'getSports';
export const GET_SELECTED_LEAGUES = 'getSelectedLeagues';
export const POST_SELECTED_LEAGUES = 'postSelectedLeagues';

export const GET_ADDONS = 'getAddons';
export const GET_SELECTED_ADDONS = 'getSelectedAddons';
export const POST_SELECTED_ADDONS = 'postSelectedAddons';

export const GET_TEAMS = 'getTeams';
export const GET_TEAMS_FOR_LEAGUE = 'getTeamsForLeague';
export const GET_SELECTED_TEAMS = 'getSelectedTeams';
export const POST_SELECTED_TEAMS = 'postSelectedTeams';

export const GET_GROUPS = 'getGroups';

export const POST_GROUP = 'postGroup';
export const UPDATE_GROUP = 'updateGroup';
export const DELETE_GROUP = 'deleteGroup';

export const POST_SCREEN = 'postScreen';
export const UPDATE_SCREEN = 'updateScreen';
export const DELETE_SCREEN = 'deleteScreen';

export const GET_SCREEN_GAMES = 'getScreenGames';
export const FAVORITE_GAME = 'favoriteGame';
export const PREFER_GAME = 'preferGame';
export const UNFAVORITE_GAME = 'unfavoriteGame';

export const GET_CUSTOMIZATION = 'getCustomization';
export const POST_CUSTOMIZATION = 'postCustomization';
export const GET_EMBED = 'getEmbed';

export const POST_BULLETIN_READ = 'postBulletinRead';

export const GET_LEAGUE_STANDINGS = 'getLeagueStandings';
export const GET_LEAGUE_PLAYERS = 'getLeaguePlayers';
export const GET_TEAM_PLAYERS = 'getTeamPlayers';
export const GET_LEAGUE_COACHES = 'getLeagueCoaches';
export const GET_LEAGUE_DEPTH_CHARTS = 'getLeagueDepthCharts';
export const GET_LEAGUE_FANTASY_PROJECTIONS = 'getLeagueFantasyProjections';
export const GET_LEAGUE_LEADERS = 'getLeagueLeaders';
