<template>
  <v-avatar :color="outerColor" :size="size" class="elevation-3" dark>
    <v-avatar :color="color" :size="innerSize" dark>
      <span
        :class="textColor + '--text'"
        class="font-weight-bold"
        :style="'letter-spacing:0px;font-size:' + fontSize + 'px'"
        >{{ value }}</span
      >
    </v-avatar>
  </v-avatar>
</template>


<script>
export default {
  name: "BorderedAvatar",
  props: {
    outerColor: {
      type: String,
      default: "white",
    },
    color: {
      type: String,
      default: "primary",
    },
    textColor: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 82,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    reductionAmount: {
      type: Number,
      default: 0.16,
    },
  },
  computed: {
    innerSize() {
      let newSize = this.size - this.size * this.reductionAmount;
      return newSize;
    },
    fontSize() {
      return this.innerSize - this.innerSize * 0.6;
    },
  },
};
</script>
