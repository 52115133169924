<template>
    <v-row
        no-gutters
        class="lightGray pb-16 mb-16 px-4 mt-16"
    >
        <v-col
            :class="option.class ? option.class : 'pa-5'"
            v-for="option in options"
            v-bind:key="option.key"
            cols="12"
        >
            <field :option="option" />
        </v-col>

        <v-col
            cols="12"
            class="px-5"
        >
            <v-btn
                @click="openDialog"
                elevation="0"
                color="darkGray"
                dark
                block
                outlined
                class="rounded-0 thick-border py-10"
            >MY LEAGUES</v-btn>
        </v-col>
        <v-col
            cols="12"
            class="px-5 mt-5"
        >
            <v-btn
                block
                class="mobile-footer-fab"
                color="success"
                @click="donePreferences"
            >

                    <v-icon
                        left
                        color="white"
                        size="30"
                    >mdi-check</v-icon>
                    Done
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {
    OPEN_LEAGUES_MODAL,
    CLOSE_MOBILE_MENUS,
} from '../../store/mutations.type';

import Field from "./Field";
import LeaguesModal from "./Filters/LeaguesModal";

export default {
    name: "MobileFilters",
    components: {
        Field,
        LeaguesModal,
    },
    computed: {
        options() {
            return this.$store.getters.getCurrentOptions;
        },
    },
    methods: {
        openDialog() {
            return this.$store.commit(OPEN_LEAGUES_MODAL);
        },
        donePreferences() {
                this.$store.commit(CLOSE_MOBILE_MENUS);
        },
    }
};
</script>

<style scoped>
.v-btn {
    font-size: 1.2em;
    border-color: #979797;
    border-width: 3px;
}
</style>
