<template>
    <div>
        <loading v-if="loading" />
        <template v-else-if="noData">
            <v-alert
                value="true"
                type="error"
                dismissible
            >
                No fantasy projections available for this league at this time.
            </v-alert>
        </template>
        <template v-else>
            <component :is="$route.params.league.toUpperCase()" />
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_FANTASY_PROJECTIONS } from "../../store/actions.type";

import Loading from "../../components/shared/Loading";
import NFL from "../../components/Leagues/FantasyProjections/NFL";
import MLB from "../../components/Leagues/FantasyProjections/MLB";

export default {
    name: "FantasyProjections",
    components: {
        Loading,
        NFL,
        MLB,
    },
    data() {
        return {
            loading: true,
            noData: false,
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
    },
    mounted() {
        this.$store.dispatch(GET_LEAGUE_FANTASY_PROJECTIONS, this.$route.params.league).then((data) => {
            if (!data) {
                this.noData = true;
            }
            this.loading = false;
        });
    },
};
</script>
