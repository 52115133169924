<template>
    <v-avatar
        color="white"
        :size="58 * smallScreenScale"
        @click="openHypePanel"
        :class="hasHype ? 'action-link' : ''"
    >
        <v-avatar
            :color="game.thuuz_game ? thuuzColor(game.thuuz_game.gex_default) : 'primary'
                "
            class="sport-badge-avatar"
        >
            <v-icon
                color="white"
                :size="30 * smallScreenScale"
            >
                {{ game.sport.icon }}
            </v-icon>
        </v-avatar>
        <v-dialog v-model="hypeDialogOpen" width="500">
          <hype-explainer @done="hypeDialogOpen=false"/>
        </v-dialog>
    </v-avatar>
</template>

<script>
import HypeExplainer from "./Hype/HypeExplainer";

export default {
    name: "FavoriteHypeBadge",
    components: {
        HypeExplainer,
    },
    props: {
        game: Object,
    },
    data: () => ({
        windowWidth: null,
        hypeDialogOpen: false,
    }),
    computed: {
        leagues() {
            return this.$store.getters.getCurrentSelectedOptions('leagues');
        },
        teams() {
            return this.$store.getters.getCurrentSelectedTeams;
        },
        hasHype() {
            return !!this.game.thuuz_game;
        },
        isFavorite() {
            if (
                this.teams.includes(this.game.home_team_id) ||
                this.teams.includes(this.game.away_team_id)
            )
                return true;

            return false;
        },
        smallScreenScale() {
            if (this.windowWidth > 412) return 1;
            return this.windowWidth / 412;
        },
    },
    methods: {
        openHypePanel() {
            if (this.hasHype) this.hypeDialogOpen = true;
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        this.onResize();
    },
};
</script>

<style scoped>
::v-deep i {
    font-style: normal;
}

::v-deep .v-badge__badge {
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.v-badge--avatar .v-badge__badge .v-avatar {
    margin-top: 7px;
    height: 61px !important;
    width: 61px !important;
    max-width: unset !important;
}

@media screen and (max-width: 412px) {
    ::v-deep .v-badge__badge {
        width: 18.2vw;
        height: 18.2vw;
    }

    .v-badge--avatar .v-badge__badge .v-avatar {
        margin-top: calc(7px * 100vw / 412);
        height: 14.8vw !important;
        width: 14.8vw !important;
    }
}</style>
