<template>
    <v-container
        class="pa-0"
        style="max-width: 1360px"
    >
        <slot>
            <v-row
                class="fill-height"
                no-gutters
            >
                <v-col
                    class="overflow-hidden"
                    style="max-height: 78px"
                >
                    <nav>
                        <v-row
                            no-gutters
                            justify="space-between"
                        >

                            <v-col
                                cols="auto"
                                v-for="item in items"
                                :key="item.text"
                            >
                                <a
                                    :href="item.url"
                                    @click.prevent="updated(item.value)"
                                    :class="[
                                        'text-uppercase my-4 font-weight-bold sport-button v-btn v-btn--text theme--light v-size--default',
                                        item.value == selectedOptions ? 'active-website-button' : null
                                    ]"
                                >
                                    <span class="v-btn__content">
                                        {{ item.text }}
                                        {{
                                            item.value == 99 && selectedLeagues && selectedLeagues.length > 0
                                                ? ` (${selectedLeagues.length})`
                                                : ''
                                        }}
                                        <v-icon
                                            right
                                            small
                                            v-if="
                                                selectedOptions == 99 &&
                                                item.value == 99 &&
                                                selectedLeagues &&
                                                selectedLeagues.length > 0
                                            "
                                        >
                                            mdi-cog
                                        </v-icon>

                                    </span>
                                </a>
                            </v-col>

                        </v-row>
                    </nav>
                </v-col>

                <v-col cols="auto">
                    <v-menu
                        rounded="0"
                        offset-y
                        v-model="isSportsTooltipOpen"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                text
                                exact
                                class="text-uppercase my-4 font-weight-bold sport-button"
                                v-bind="attrs"
                                v-on="on"
                            ><v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-card
                            color="white"
                            tile
                            class="pa-6"
                        >
                            <v-row class="flex-nowrap">
                                <v-col cols="auto">
                                    <span
                                        v-for="(item, index) in items"
                                        :key="item.text"
                                    >
                                        <v-btn
                                            color="black"
                                            text
                                            tile
                                            small
                                            class="
                            mx-5
                            sport-tooltip-button
                            justify-start
                            font-weight-thin
                            "
                                            @click="updated(item.value)"
                                        >
                                            {{ item.text }}
                                        </v-btn>
                                        <br v-if="index % 2" />
                                    </span>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    class="py-4"
                                >
                                    <v-divider
                                        vertical
                                        color="lightGray"
                                    />
                                </v-col>
                                <v-col cols="auto">
                                    <!--
                        <v-btn
                            text
                            tile
                            small
                            color="black"
                            class="font-weight-thin sport-tooltip-button justify-start"

                      >
                        My Teams
                      </v-btn>
                      <br />
                    -->
                                    <v-btn
                                        text
                                        tile
                                        small
                                        color="black"
                                        class="
                        font-weight-thin
                        sport-tooltip-button
                        justify-start
                      "
                                        @click="updated(99)"
                                    >
                                        My Leagues
                                    </v-btn>
                                    <br />
                                    <v-btn
                                        v-if="authenticated"
                                        text
                                        tile
                                        small
                                        color="black"
                                        to="/planner"
                                        class="
                        font-weight-thin
                        sport-tooltip-button
                        justify-start
                      "
                                    >
                                        Planner
                                    </v-btn>
                                    <v-btn
                                        v-if="false"
                                        text
                                        tile
                                        small
                                        color="black"
                                        to="/embededit"
                                        class="
                        font-weight-thin
                        sport-tooltip-button
                        justify-start
                      "
                                    >
                                        Embed Editor
                                    </v-btn>
                                    <br />
                                    <v-btn
                                        v-if="!authenticated && $route.query.login"
                                        text
                                        tile
                                        small
                                        color="black"
                                        @click="login"
                                        class="
                        font-weight-thin
                        sport-tooltip-button
                        justify-start
                      "
                                    >
                                        Login
                                    </v-btn>
                                    <v-btn
                                        v-if="authenticated"
                                        text
                                        tile
                                        small
                                        color="black"
                                        @click="logout"
                                        class="
                        font-weight-thin
                        sport-tooltip-button
                        justify-start
                      "
                                    >
                                        Logout
                                    </v-btn>
                                    <br />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
        </slot>
    </v-container>
</template>

<script>
import { POST_SELECTED_OPTION, UPDATE_FORM, TOGGLE_AUTH_DIALOG, LOGOUT } from "../../../store/actions.type";

import { OPEN_LEAGUES_MODAL } from "../../../store/mutations.type";

export default {
    name: "SportBar",
    data() {
        return {
            isDrawerOpen: false,
            isSportsTooltipOpen: false,
            dialog: false,
        };
    },
    computed: {
        hasParams() {
            return this.$route.params.sport &&
                this.$route.params.league &&
                this.$route.params.date
                ? true
                : false;
        },
        option() {
            return this.$store.getters.getSingleOption("sports");
        },

        showbar() {
            return this.$route.meta.showbar;
        },
        items: function () {
            return this.option.options;
        },
        selectedOptions() {
            return this.$store.getters.getCurrentSelectedOptions(this.option.key);
        },
        selectedLeagues() {
            return this.$store.getters.getCurrentSelectedOptions("leagues");
        },
        authenticated() {
            return this.$store.getters.isAuthenticated;
        },
        currentForm() {
            return this.$store.getters.currentForm;
        },
        authenticated() {
            return this.$store.getters.isAuthenticated;
        },
    },
    methods: {
        login() {
            this.$store.dispatch(UPDATE_FORM, "login").then(() => {
                this.$store.dispatch(TOGGLE_AUTH_DIALOG);
            });
        },
        logout() {
            this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "home" }));
        },
        hasRole(role) {
            return this.$store.getters.hasRole(role);
        },
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        },
        toggleSportsTooltip() {
            this.isSportsTooltipOpen = !this.isSportsTooltipOpen;
        },

        updated(options) {
            this.loading = true;

            let key = this.option.key;

            if (
                options == 99 &&
                (this.selectedOptions == 99 || this.selectedLeagues.length == 0)
            ) {
                this.$store.commit(OPEN_LEAGUES_MODAL);
            }

            //   this.$gtag.event("api", {
            //     event_category: "options",
            //     event_label: "post_selected_option",
            //     value: options,
            //   });
            this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
                this.loading = false;
            });
        },
    },
};
</script>


<style scoped>
.sport-button {
    font-size: 1.2rem;
    letter-spacing: 0.02em;
}

.active-website-button {
    color: #196DD4;
}

.active-website-button:after,
.sport-button:hover::after {
    content: "";
    background: black;
    position: absolute;
    bottom: 0;
    margin-left: auto;
    height: 2px;
    width: 40px;
}

.active-website-button:after {
    background: #196DD4;
}

.sport-tooltip-button {
    font-size: 1.1em;
    width: 120px;
    letter-spacing: 0.02em;
}


@media print {
    .v-main {
        padding: 0 !important;
    }
}
</style>
