<template>
    <!-- Player Data Table -->
    <v-data-table
        :headers="playerHeaders"
        :items="formattedPlayers"
        :item-class="rowClass"
        class="elevation-1"
        :search="search"
    >
        <template v-slot:top>
            <v-text-field
                prepend-icon="mdi-magnify"
                v-model="search"
                label="Search"
                class="mx-4"
            />
        </template>
        <template v-slot:item.positions="{ item }">
            <span
                v-for="(position, index) in item.positions"
                :key="index"
            >
                {{ position.abbreviation }}
            </span>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "MLS",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            playerHeaders: [
                { text: 'Name', value: 'fullName' },
                // { text: 'First Name', value: 'fullFirst' },
                // { text: 'Last Name', value: 'fullLast' },
                // { text: 'Display Name', value: 'displayName' },
                { text: 'Team', value: 'team' },
                { text: 'Height', value: 'height' },
                { text: 'Weight', value: 'weight' },
                { text: 'Birth Date', value: 'birthDate' },
                { text: 'Birth City', value: 'birthCity' },
                { text: 'Birth Country', value: 'birthCountry' },
                { text: 'Nationality', value: 'nationality' },
                { text: 'Position', value: 'position' },
                { text: 'Experience', value: 'experience' },
                // { text: 'Hometown', value: 'hometown' },
            ],
        };
    },
    computed: {
        formattedPlayers() {
            return this.league.players.map(player => ({
                playerId: player.playerId,
                fullName: `${player.firstName} ${player.lastName}`,
                // fullFirst: player.fullFirst,
                // fullLast: player.fullLast,
                // displayName: player.displayName,
                team: player.team ? `${player.team.location} ${player.team.nickname}` : '',
                height: player.height ? `${player.height.inches}in` : '',
                weight: player.weight ? (player.weight.pounds ? `${player.weight.pounds}lbs` : '') : '',
                birthDate: (player.birth && player.birth.birthDate) ? player.birth.birthDate.full : '',
                birthCity: player.birth.city,
                birthCountry: player.birth && player.birth.country ? player.birth.country.name : '',
                nationality: player.nationality.name,
                position: player.positions.map(p => p.name).join(', '),
                experience: player.experience.yearFirst > 0 ? `${player.experience.yearFirst} - ${player.experience.yearLast}` : '',
                // hometown: player.hometown.city || '',
            }));
        },
    },
    methods: {
        positionsString(positions) {
            return positions.map(p => p.abbreviation).join(', ');
        }
    },
};

</script>
