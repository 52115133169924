<template>
    <v-app-bar
        app
        :height="isDtvMobile ? 60 : 100"
        clipped-right
        fixed
        absolute
        elevation="0"
        color="dtv-app-bar"
        v-if="showbar"
        class="d-print-none"
        max-width="2000"
    >
        <template v-if="isDtvMobile">

            <v-btn
                text
                tile
                class="dtv-button"
                @click="drawer = !drawer"
                x-large
            >
                <v-icon size="32">
                    $menu
                </v-icon>
            </v-btn>

            <v-spacer />

            <v-col cols="auto">
                <a
                    href="https://www.directv.com/"
                >
                    <v-img
                        :src="`${appUrl}/img/brand/donly.svg`"
                        min-height="24"
                        height="24"
                        contain
                        alt="DirecTV Icon Logo"
                    />

                </a>
            </v-col>

            <v-spacer />

            <v-spacer />

            <v-navigation-drawer
                app
                v-model="drawer"
                absolute
                temporary
            >
                <v-list>
                    <!-- Close button -->
                    <v-list-item class="drawer-close">
                        <v-btn
                            tile
                            text
                            x-large
                            class="mb-1"
                            @click="drawer = false"
                        >
                            <v-icon size="36">mdi-close</v-icon>
                        </v-btn>
                    </v-list-item>


                    <template v-for="(menuItem, menuItemIndex) in menuItems">
                        <v-divider
                            v-if="menuItem.spacer"
                            :key="`divider-${menuItemIndex}`"
                        ></v-divider>

                        <template v-else-if="menuItem.subMenuItems">
                            <v-list-group
                                :key="`group-${menuItemIndex}`"
                                v-model="menuItem.active"
                                :prepend-icon="menuItem.icon"
                                no-action
                            >
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <!-- Custom expand/collapse icon -->
                                <template v-slot:append>
                                    <v-icon>{{ menuItem.active ? '$chevronup' : '$chevrondown' }}</v-icon>
                                </template>

                                <v-list-item
                                    v-for="(subMenuItem, subIndex) in menuItem.subMenuItems"
                                    :key="`sub-${menuItemIndex}-${subIndex}`"
                                    :href="subMenuItem.href"
                                    link
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                        </template>

                        <v-list-item
                            v-else
                            :key="`item-${menuItemIndex}`"
                            :href="menuItem.href"
                            link
                        >
                            <v-list-item-icon v-if="menuItem.icon">
                                <v-icon>{{ menuItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-navigation-drawer>

        </template>
        <template v-else>


            <v-col
                cols="auto"
                class="mx-6"
            >
                <!-- <a
                    style="width: 120px;"
                    href="https://www.directv.com/?source=IDdB000000000002L&utm_medium=referral&utm_source=sportsdirectv&utm_campaign=cross-domain&utm_audience=agnostic&utm_creative=logo"
                > -->
                <a
                    style="width: 120px;"
                    href="https://www.directv.com/"
                >
                    <v-img
                        :src="`${appUrl}/img/logos/dtvlogo.svg`"
                        min-width="120"
                        width="120"
                        contain
                        class="mt-1"
                        alt="DirecTV Logo"
                    />

                </a>
            </v-col>
            <template v-for="(menuItem, menuItemIndex) in menuItems">
                <v-spacer
                    v-if="menuItem.spacer"
                    :key="menuItemIndex"
                ></v-spacer>

                <template v-else-if="menuItem.subMenuItems">
                    <v-menu
                        :key="menuItemIndex"
                        v-model="menuItem.active"
                        min-width="290px"
                        transition="scale-transition"
                        offset-y
                        class="dtv-menu"
                        open-on-hover
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                text
                                tile
                                class="dtv-button  px-4"
                                x-large
                            >
                                {{ menuItem.title }}
                                <v-icon right>
                                    {{ menuItem.active ? '$chevronup' : '$chevrondown' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list class="py-0">
                            <v-list-item
                                class="dtv-button py-1"
                                v-for="subMenuItem in menuItem.subMenuItems"
                                :key="subMenuItem.title"
                                link
                                :href="subMenuItem.href"
                                x-large
                            >
                                <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-else>
                    <v-btn
                        :key="menuItemIndex"
                        text
                        tile
                        class="dtv-button  px-4"
                        :href="menuItem.href"
                        x-large
                    >
                        {{ menuItem.title }}
                    </v-btn>
                </template>
            </template>




        </template>
    </v-app-bar>

</template>

<script>
export default {
    name: "DtvHeader",
    data() {
        return {
            drawer: false,
            logo: "icon",
            menuItems: [
                {
                    title: "BUSINESS",
                    subMenuItems: [
                        {
                            title: "Home",
                            href: "https://www.directv.com/forbusiness/",
                        },
                        {
                            title: "Channel Lineup",
                            href: "https://www.directv.com/forbusiness/channels/",
                        },
                        {
                            title: "Sports",
                            href: "https://www.directv.com/forbusiness/sports/",
                        },
                        {
                            title: "NFL SUNDAY TICKET",
                            href: "https://www.directv.com/forbusiness/nfl-sunday-ticket/",
                        },
                        {
                            title: "Support",
                            href: "https://www.directv.com/forbusiness/customer-support/",
                        },
                    ]
                },
                {
                    title: "RESIDENTIAL",
                    subMenuItems: [
                        {
                            title: "Shop",
                            href: "https://www.directv.com/packages/",
                        },
                        {
                            title: "Channel Liuneup",
                            href: "https://www.directv.com/channel-lineup/",
                        },
                        {
                            title: "Sports",
                            href: "https://www.directv.com/sports/",
                        },
                        {
                            title: "Account Overview",
                            href: "https://oidc.idp.clogin.att.com/mga/sps/oauth/oauth20/authorize?response_type=id_token&client_id=m45917&scope=openid&response_mode=form_post&state=HALODTV&nonce=sdfdds8&redirect_uri=https%3A%2F%2Fwww.directv.com%2Fvpmapi%2Fdtvloginms%2Fv1%2Fauthenticate%3Fenv%3Ddcp%26nextUrl%3Doverview",
                        },
                        {
                            title: "Support",
                            href: "https://www.directv.com/support",
                        },
                    ]
                },


            ]
        };
    },
    computed: {
        showbar() {
            return this.$route.meta.showbar;
        },
        isFiltersMenuOpen() {
            return this.$store.getters.isFiltersMenuOpen;
        },
        isSportsMenuOpen() {
            return this.$store.getters.isSportsMenuOpen;
        },
        isAnyMenuOpen() {
            return this.isFiltersMenuOpen || this.isSportsMenuOpen;
        },
    },
    methods: {},
};
</script>


<style scoped>
/*
@media print {
  .v-main {
    padding: 0 !important;
  }
}
*/
.dtv-app-bar {
    background-color: white !important;
}

.dtv-button,
.v-list-item__title {
    font-size: 20px;
    letter-spacing: -0.4px;
    font-weight: 400;
}

.v-menu__content {
    border: 1px solid #8D8D8D;
}

/* Responsive font sizes */
@media (min-width: 1700px) {
    .dtv-button,
    .v-list-item__title,
    .v-list-group__header .v-list-item__title {
        font-size: 20px !important;
    }
}

@media (max-width: 1699px) {
    .dtv-button,
    .v-list-item__title,
    .v-list-group__header .v-list-item__title {
        font-size: 16px !important;
    }
}
</style>
