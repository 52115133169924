<template>
    <v-text-field
        v-mask="option.mask || null"
        class="primary--text"
        rounded
        hide-details="auto"
        :label="option.name"
        outlined
        :hint="option.hint"
        persistent-hint
        @change="updated"
        :loading="loading"
        :value="selectedOptions"
        :error="hasError"
        :error-messages="errorMessage"
        :rules="option.rules || null"
    />
</template>

<script>
import { POST_SELECTED_OPTION } from "../../../store/actions.type";
import { mask } from 'vue-the-mask'

export default {
    name: "Zipcode",
    directives: {
        mask
    },
    props: {
        option: Object
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        selectedOptions() {
            return this.$store.getters.getCurrentSelectedOptions(this.option.key);
        },
        errors() {
            return this.$store.getters.getErrors("options");
        },
        hasError() {
            return this.errors && this.errors[this.option.key];
        },
        errorMessage() {
            return this.hasError ? this.errors[this.option.key] : null;
        }
    },
    methods: {
        updated(options) {
            let key = this.option.key;
            this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>
.v-text-field>>>.v-text-field__slot {
    padding-top: 4px !important;
    height: 66px;
}

.v-text-field--outlined>>>.v-label {
    /*color: #196DD4;*/
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}

.v-text-field--outlined>>>input {
    /*color: #196DD4;*/
    font-size: 1.2em;
}


.v-text-field--outlined>>>fieldset {
    /*border-color: #196DD4;*/
    border-width: 3px;
}
</style>
