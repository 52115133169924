<template>
    <div>
        <v-switch
            :label="option.name"
            :loading="loading"
            v-model="option.selectedOptions"
        />
        <div
            v-if="option.hint"
            class="text-muted"
        >{{ option.hint }}</div>
    </div>
</template>

<script>
export default {
    name: "Toggle",
    props: {
        option: Object
    },
    data() {
        return {
            loading: false
        }
    },
};
</script>
