<template>
  <v-text-field
    v-model="option.selectedOptions"
    class="primary--text"
    hide-details="auto"
    :label="option.name"
    outlined
    :loading="loading"
  />
</template>

<script>
export default {
  name: "Textfield",
  props: {
    option: Object
  },
  data() {
    return {
      loading: false
    };
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> .v-label {
  color: #196DD4;
  font-weight:bold;
}

.v-text-field--outlined >>> input {
  color: #196DD4;
}

.v-text-field--outlined >>> fieldset {
  border-color: #196DD4;
}
</style>
