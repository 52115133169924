import ApiService from "../api/api.service";
import router from "../router";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_OPTIONS,
    GET_MONTHS,
    GET_SELECTED_OPTIONS,
    POST_SELECTED_OPTION,
    POST_PARAM_OPTIONS,
    TOGGLE_COUNTY_DIALOG,

    // Games
    GET_GAMES,
    GET_SEASON_GAMES,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_SPECIAL_FILTERS,
    SET_OPTIONS,
    SET_SELECTED_OPTIONS,
    CLOSE_MOBILE_MENUS,
    CLOSE_LEAGUES_MODAL,
    OPEN_FILTERS_MENU,
    OPEN_SPORTS_MENU,
    OPEN_LEAGUES_MODAL,
    SET_LEAGUES_MODEL,
    SET_COUNTY_DIALOG,

} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        specialFilters: [],
        gotOptions: false,
        filtersMenuOpen: false,
        sportsMenuOpen: false,
        leaguesModalOpen: false,
        countyDialogOpen: false,
        options: {
            sports: {
                name: "SPORTS",
                key: 'sports',
                section: 'none',
                cols: 12,
                sm: 12,
                class: 'pa-0',
                configurable: false,
                modal: 'LeaguesModal',
                component: "SportBar",
                multiple: false,
                options: [],
            },

            zip: {
                name: "ZIP CODE",
                key: 'zip',
                section: 'normal',
                cols: 12,
                sm: 6,
                md: 2,
                hint: null,
                setting: true,
                reget: true,
                component: "Zipcode",
                mask: '#####-####',
                rules: [
                    (v) =>
                        /^0|[0-9]{5}(?:-[0-9]{4})?|null$/.test(
                            v
                        ) || "Please enter a valid ZIP Code",
                ]
            },

            county: {
                name: "COUNTY",
                key: 'county',
                section: 'none',
                cols: 12,
                sm: 6,
                md: 2,
                setting: true,
                component: "dropdown",
                reget: true,
                multiple: false,
                options: [],
            },

            languages: {
                name: "LANGUAGE",
                key: 'languages',
                section: 'normal',
                cols: 12,
                sm: 6,
                md: 3,
                component: "dropdown",
                multiple: false,
                setting: true,
                options: [],
            },

            bundles: {
                name: "PACKAGE TYPE",
                key: 'bundles',
                section: 'normal',
                cols: 12,
                sm: 6,
                md: 4,
                component: "dropdown",
                multiple: false,
                setting: true,
                options: []
            },

            addons: {
                name: "ADD-ONS",
                key: 'addons',
                //ONly show if there are addons in the route query
                section: 'normal',
                cols: 12,
                sm: 6,
                md: 3,
                component: "addons",
                configurable: false,
                multiple: false,
                setting: false,
                options: [],
            },

            replays: {
                name: "REPLAYS",
                key: 'replays',
                section: 'none',
                cols: 6,
                sm: 6,
                md: 4,
                component: "dropdown",
                multiple: false,
                options: [],
            },

            leagues: {
                name: "LEAGUES",
                key: 'leagues',
                section: 'none',
                cols: 12,
                sm: 6,
                md: 4,
                class: 'pa-0',
                configurable: true,
                multiple: true,
                options: [],
            },

            days: {
                name: "DAY",
                key: 'days',
                section: 'bar',
                cols: 12,
                sm: 12,
                component: "DateBar",
                reget: true,
                multiple: false,
                options: [],
                print: true
            },

            teams: {
                name: "HIGHLIGHT MY TEAMS",
                key: 'teams',
                section: 'none',
                cols: 12,
                sm: 6,
                component: "dropdown",
                configurable: true,
                multiple: false,
                options: [],
            },

            groups: {
                name: "GROUP",
                key: 'groups',
                section: 'static',
                cols: 6,
                sm: 6,
                md: 4,
                component: "dropdown",
                multiple: false,
                options: []
            },

            completed: {
                name: "SHOW PREVIOUS",
                key: "completed",
                section: 'static',
                cols: 6,
                sm: 6,
                md: 4,
                component: "toggle",
                multiple: false,
            },

            months: {
                name: "MONTHS",
                key: 'months',
                section: 'schedule',
                component: "buttons",
                multiple: false,
                reget: true,
                options: [],
            },

            hours: {
                name: "BUSINESS HOURS",
                key: 'hours',
                section: 'settings',
                component: 'BusinessHours',
                setting: true,
                multiple: false,
                options: [],
            },

            packs: {
                name: "PACKAGE",
                key: 'packs',
                section: 'none',
                cols: 12,
                sm: 6,
                md: 4,
                component: "dropdown",
                multiple: false,
                setting: false,
                options: []
            },

            sources: {
                name: "SOURCES",
                key: 'sources',
                section: 'none',
                cols: 6,
                sm: 6,
                md: 4,
                component: "dropdown",
                multiple: false,
                commercial: true,
                options: [],
            },

            timezone: {
                name: "TIMEZONE",
                key: 'timezone',
                section: 'none',
                multiple: false,
            },

            layout: {
                name: "LAYOUT",
                key: "layout",
                section: 'none',
                options: [],
                multiple: false,
            },

            seobar: {
                name: "SEOBAR",
                key: "seobar",
                section: 'none',
                options: [],
                multiple: false,
            },

            search: {
                name: "SEARCH",
                key: "search",
                section: 'none',
            },

            results: {
                name: "RESULTS",
                key: 'results',
                component: "dropdown",
                removable: true,
                section: 'none',
                selectedOptions: 0,
                multiple: false,
                hint: "Decide how the API results will be returned.",
                options: [
                    {
                        value: 0,
                        text: "With Suggested (AI Assisted)",
                    },
                    {
                        value: 1,
                        text: "Only Selected (Manual)",
                    },
                    // {
                    //     value: 2,
                    //     text: "All",
                    // }
                ]
            }

        },
        screenoptions: {
            zip: {
                name: "ZIP CODE",
                key: 'zip',
                component: "textfield",
                mask: '#####-####',
                rules: [
                    (v) =>
                        /^0|[0-9]{5}(?:-[0-9]{4})?|null$/.test(
                            v
                        ) || "Please enter a valid ZIP Code",
                ],
                removable: true,
                forGroup: true,
                forScreen: false
            },

            completed: {
                name: "SHOW PREVIOUS",
                component: "toggle",
                key: "completed",
                removable: true,
                forGroup: false,
                forScreen: false
            },

            leagues: {
                name: "LEAGUES",
                key: 'leagues',
                component: "LeagueOrder",
                multiple: true,
                selectedOptions: [],
                removable: true,
                forGroup: false,
                forScreen: true
            },

            timezone: {
                name: "TIMEZONE",
                key: 'timezone',
                component: "Timezone",
                selectedOptions: [],
                removable: false,
                forGroup: true,
                forScreen: true
            },

            results: {
                name: "RESULTS",
                key: 'results',
                component: "dropdown",
                removable: true,
                multiple: false,
                selectedOptions: 0,
                hint: "Decide how the API results will be returned.",
                options: [
                    {
                        value: 0,
                        text: "With Suggested",
                    },
                    {
                        value: 1,
                        text: "Only Selected",
                    },
                    // {
                    //     value: 2,
                    //     text: "All",
                    // }
                ],
                forGroup: false,
                forScreen: true
            }

        },
        selectedOptions: {
            sports: 0,
            days: "",
            zip: "",
            county: 0,
            bundles: 15,
            packs: [],
            addons: [],
            teams: 0,
            replays: 0,
            languages: 99,
            displays: 0,
            groups: 0,
            timezone: null,
            timezoneString: null,
            sources: 0,
            layout: 0,
            seobar: 1,
            completed: false,
            search: "",
        },

    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    checkGotOptions(state) {
        return state.gotOptions;
    },
    getSpecialFilters(state) {
        return state.specialFilters;
    },
    getSingleOption: (state) => (key) => {
        return Object.filter(state.options, option => {
            return option.key == key;
        })[key];
    },
    getCurrentOptions(state) {
        return Object.filter(state.options, option => {
            if (option.commercial && state.selectedOptions.bundles < 20) {
                return false;
            }
            return option.section == 'normal';
        });
    },
    getOptionList: (state) => (key) => {
        return state.options[key].options;
    },
    getScreenOptions(state) {
        return state.screenoptions;
    },
    getSettingOptions(state) {
        return Object.filter(state.options, option => option.setting == true);
    },
    getCurrentAdvanced(state) {
        return Object.filter(state.options, option => option.section == 'advanced');
    },
    getCurrentBars(state) {
        return Object.filter(state.options, option => option.section == 'bar');
    },
    getCurrentOptionsBySection: (state) => (key) => {
        return Object.filter(state.options, option => option.section == key);
    },
    getCurrentMonths(state) {
        return Object.filter(state.options, option => option.section == 'schedule');
    },
    getAllSelectedOptions(state) {
        let selectedOptions = state.selectedOptions;

        if (router.currentRoute.name == 'embed') {
            selectedOptions.bundles = 20;
        }

        return selectedOptions;
    },
    getCurrentSelectedOptions: (state) => (type) => {

        if (type == 'layout' && (router.currentRoute.name == 'embed' || router.currentRoute.name == 'mvp')) return 1;
        return state.selectedOptions[type];
    },
    getIsCommercial(state) {
        return state.selectedOptions.bundles >= 20;
    },
    isFiltersMenuOpen(state) {
        return state.filtersMenuOpen;
    },
    isSportsMenuOpen(state) {
        return state.sportsMenuOpen;
    },
    isLeaguesModalOpen(state) {
        return state.leaguesModalOpen;
    },
    isCountyDialogOpen(state) {
        return state.countyDialogOpen;
    }
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_SPECIAL_FILTERS](state, data) {
        console.log("SET_SPECIAL_FILTERS");
        if (state.specialFilters.length == 0) {
            state.specialFilters = data;
        } else {
            state.specialFilters = [];
        }

    },
    [SET_OPTIONS](state, data) {
        console.log("SET_OPTIONS");
        for (const property in data) {
            if (state.options.hasOwnProperty(property)) {
                state.options[property].options = data[property];
            }
        }
        state.gotOptions = true;
    },
    [SET_SELECTED_OPTIONS](state, data) {
        console.log("SET_SELECTED_OPTIONS", data);
        var params = "";
        for (const property in data) {
            if (state.options.hasOwnProperty(property)) {
                if (property == "sports" || property == "leagues") {
                    if (params != "") {
                        params += "/";
                    }

                    if (state.options[property].multiple && state.selectedOptions[property] && state.selectedOptions[property].length > 1) {
                        if (property == "leagues" && state.selectedOptions.sports != 99) {
                            params += 'all';
                        } else {
                            params += 'custom';
                        }
                    } else {
                        params += encodeURIComponent(data[property].slug);
                    }
                }

                state.selectedOptions[property] = data[property].value;
                state.selectedOptions = JSON.parse(JSON.stringify(state.selectedOptions));

                if (data[property].hint) {
                    //console.log("hint", data[property].hint);
                    state.options[property].hint = data[property].hint;
                }

                if (property == "timezone") {
                    state.selectedOptions.timezoneString = data[property].string;
                }
            }
        }

        if (router.currentRoute.meta && router.currentRoute.meta.reroute) {
            let currentUrlParams = router.currentRoute.params.sport + "/" + router.currentRoute.params.league;
            let currentUrlQueries = router.currentRoute.query;
            let currentUrlQuery = "";

            if (Object.keys(currentUrlQueries).length) {
                currentUrlQuery = "?";
                for (const key in currentUrlQueries) {
                    currentUrlQuery += key + "=" + currentUrlQueries[key] + "&";
                }
                currentUrlQuery = currentUrlQuery.slice(0, -1);
            }
            if (currentUrlParams != params) router.replace(`/${params}${currentUrlQuery}`);
        }
    },
    [CLOSE_MOBILE_MENUS](state) {
        console.log("CLOSE_MOBILE_MENUS");
        state.filtersMenuOpen = false;
        state.sportsMenuOpen = false;

    },
    [OPEN_FILTERS_MENU](state) {
        console.log("OPEN_FILTERS_MENU");
        state.sportsMenuOpen = false;
        state.filtersMenuOpen = true;
    },
    [OPEN_SPORTS_MENU](state) {
        console.log("OPEN_FILTER_MENU");
        state.filtersMenuOpen = false;
        state.sportsMenuOpen = true;

    },
    [OPEN_LEAGUES_MODAL](state) {
        console.log("OPEN_LEAGUES_MODAL");
        state.leaguesModalOpen = true;
    },
    [CLOSE_LEAGUES_MODAL](state) {
        console.log("CLOSE_LEAGUES_MODAL");
        state.leaguesModalOpen = false;
    },
    [SET_LEAGUES_MODEL](state, value) {
        console.log("SET_LEAGUES_MODEL");
        state.leaguesModalOpen = value;
    },
    [SET_COUNTY_DIALOG](state, value) {
        console.log("SET_COUNTY_DIALOG");
        state.countyDialogOpen = value;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_OPTIONS](context) {
        console.log("GET_OPTIONS");
        /*
        if(context.getters.checkGotOptions) {
            return;
        }
        */
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/options")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_OPTIONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'options',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_MONTHS](context, params) {
        console.log("GET_MONTHS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/options/months", params)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_OPTIONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'options',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [GET_SELECTED_OPTIONS](context) {
        console.log("GET_SELECTED_OPTIONS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/options/selected")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SELECTED_OPTIONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'options',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [POST_SELECTED_OPTION](context, details) {
        if (details.key == 'sports') {
            context.commit(SET_SPECIAL_FILTERS, []);
        }
        console.log("POST_SELECTED_OPTION", details);
        context.commit(
            SET_SELECTED_OPTIONS, details
        );
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.post("/options/selected", details)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);

                    if (data.counties) {
                        context.commit(
                            SET_OPTIONS, {
                            county: data.counties
                        });
                        context.commit(SET_COUNTY_DIALOG, true);
                    } else {
                        context.commit(
                            SET_SELECTED_OPTIONS, data
                        );
                        if (state.options[details.key].reget) {
                            console.log("option reget ⭐");
                            if (router.currentRoute.name === "season" || router.currentRoute.name === "league-schedule" || router.currentRoute.name === "team-schedule") {
                                // Get the games for the selected month
                                context.dispatch(GET_SEASON_GAMES, {
                                    league_text_id: router.currentRoute.params.league,
                                    team_text_id: router.currentRoute.params.team,
                                    year: router.currentRoute.params.year,
                                    month: router.currentRoute.params.month,
                                })
                            } else {
                                context.dispatch(GET_GAMES);
                            }
                        }
                    }
                    resolve(data);
                })
                .catch((e) => {
                    console.log(e);
                    if (e.response) {
                        context.commit(
                            SET_ERROR, {
                            target: 'options',
                            message: e.response.data.error,
                            errors: {
                                [details.key]: e.response.data.error,
                            }
                        });
                    }
                    reject(e.response);
                });
        });
    },
    [POST_PARAM_OPTIONS](context, params) {
        console.log("POST_PARAM_OPTIONS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.post("/options/params", params)
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SELECTED_OPTIONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                        target: 'options',
                        message: response.data.error
                    }
                    );
                    reject(response);
                });
        });
    },
    [TOGGLE_COUNTY_DIALOG](context, value) {
        context.commit(SET_COUNTY_DIALOG, !context.state.isCountyDialogOpen);
    },
};

export default {
    getters,
    actions,
    mutations,
    state
}
