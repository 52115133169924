<template>
  <v-container>
    <v-row>
        <v-col>
            <h3 class="pb-5">Customize your Profile</h3>

            <v-row
                class="pr-3 pb-5"
                v-for="(option) in settings"
                v-bind:key="option.key"
            >
                <v-col cols="3" md="2">
                    {{ option.name }}
                </v-col>
                <v-col
                    cols="9" md="8"
                    class="py-0 px-0"
                >
                    <Field :option="option" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Field from "../components/Home/Field";

import { GET_OPTIONS, GET_SELECTED_OPTIONS } from "../store/actions.type";


export default {
  name: "Settings",
  components: {
      Field
  },
  computed: {
    settings() {
      return this.$store.getters.getSettingOptions;
    },
  },
  mounted() {
    // this.$gtag.event("api", {
    //   event_category: "options",
    //   event_label: "get_options"
    // });

    this.$store
      .dispatch(GET_OPTIONS)
      .then(() => {
        // this.$gtag.event("api", {
        //   event_category: "options",
        //   event_label: "get_selected_options"
        // });
        this.$store.dispatch(GET_SELECTED_OPTIONS);
      });

  }
};
</script>
