export const SET_ERROR = 'setError';
export const CLEAR_ERRORS = 'clearErrors';

export const SET_AUTH = "setUser";
export const RESET_AUTH = "logout";

export const SET_AUTH_DIALOG = 'setAuthDialog';
export const SET_CURRENT_FORM = 'setCurrentForm';

export const CLEAR_GAMES = 'clearGames';
export const CLEAR_BULLETINS = 'clearBulletins';
export const SET_GAMES = 'setGames';
export const SET_BULLETINS = 'setBulletins';
export const SET_GAMES_LOADING = 'setGamesLoading';

export const CLEAR_SHOWINGS = 'clearShowings';
export const SET_SHOWINGS = 'setShowings';

export const RESET_OPTIONS = 'resetOptions';
export const SET_SPECIAL_FILTERS = 'setSpecialFilters';
export const SET_OPTIONS = 'setOption';
export const SET_SELECTED_OPTIONS = 'setSelectedOptions';
export const CLOSE_MOBILE_MENUS = 'closeMobileMenus';
export const CLOSE_LEAGUES_MODAL = "closeLeaguesModal";
export const OPEN_FILTERS_MENU = 'openFiltersMenu';
export const OPEN_SPORTS_MENU = 'openSportsMenu';
export const OPEN_LEAGUES_MODAL = "openLeaguesModal";
export const SET_LEAGUES_MODEL = "setLeaguesModal";
export const SET_COUNTY_DIALOG = "setCountryDialog";

export const SET_SPORTS = 'setSports';
export const SET_SELECTED_LEAGUES = 'setSelectedLeagues';

export const SET_ADDONS = 'setAddons';
export const SET_SELECTED_ADDONS = 'setSelectedAddons';

export const SET_TEAMS = 'setTeams';
export const SET_SELECTED_TEAMS = 'setSelectedTeams';

export const CLEAR_GROUPS = 'clearGroups';
export const SET_GROUPS = 'setGroups';

export const APPEND_GROUP = 'appendGroup';
export const SET_GROUP = 'setGroup';
export const REMOVE_GROUP = 'removeGroup';

export const APPEND_SCREEN = 'appendScreen';
export const SET_SCREEN = 'setScreen';
export const REMOVE_SCREEN = 'removeScreen';

export const CLEAR_SCREEN_GAMES = 'clearScreenGames';
export const SET_SCREEN_GAMES = 'setScreenGames';

export const SET_FAVORITES = 'setFavorites';
export const SET_PREFERRED = 'setPreferred';

export const SET_CUSTOMIZATION = 'setCustomization';
export const SET_EMBED = 'setEmbed';

export const SET_BULLETIN_READ = 'setBulletinRead';

export const CLEAR_STATS_DATA = 'clearStatsData';
export const SET_STATS_DATA = 'setStatsData';
