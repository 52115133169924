<template>
  <v-card>
    <v-card-title class="headline">
      What is the name of your new location?
    </v-card-title>
    <v-card-text>
      <v-text-field
        label="Location Name*"
        required
        v-model="name"
        :error="errors.length"
        :error-messages="errors && errors.name ? errors.name : null"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('done')">
        Close
      </v-btn>
      <v-btn color="blue darken-1" text @click="addGroup" :disabled="!name">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { POST_GROUP } from "../../store/actions.type";

export default {
  name: "AddGroupModal",
  data() {
    return {
      name: null,
    };
  },
  computed: {
      errors() {
          return this.$store.getters.getErrors('groups');
      }
  },
  methods: {
      addGroup() {
          this.$store
            .dispatch(POST_GROUP, { name: this.name } )
            .then(() => this.$emit("done"));
          this.name = null;
      }
  }
};
</script>
