<template>
  <v-container>
    <v-card>
      <v-card-title class="justify-space-between font-weight-black">
        LOGIN
        <v-spacer/>
        <v-btn icon @click="close"><v-icon>fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="error" :class="error ? 'red--text text-center' : 'text-center'">{{error}}</v-card-subtitle>

      <v-card-text>
        <v-row v-if="false">
          <v-col>
            This tool is provided as a service for commercial DIRECTV customers to help plan live sports broadcasts on multiple screens. Please login or create an account to begin creating custom broadcast plans.
          </v-col>
        </v-row>
        <v-form v-model="isFormValid" ref="loginForm">
          <v-text-field
            ref="email"
            label="Email"
            name="email"
            v-model="email"
            :rules="rules.email"
            prepend-icon="mdi-account"
            type="text"
            :error="errors && errors.email"
            :error-messages="errors && errors.email ? errors.email : null"
            @focus="clearErrors()"
            @keyup.enter="$refs.password.focus();"
          />

          <v-text-field
            ref="password"
            label="Password"
            name="password"
            v-model="password"
            prepend-icon="mdi-lock"
            type="password"
            :rules="rules.password"
            :error="errors && errors.password"
            :error-messages="errors && errors.password ? errors.password : null"
            @focus="clearErrors()"
            @keyup.enter="login()"
          />
          <v-row align="center">
            <v-btn
              block
              x-small
              text
              @click="forgotEmailFlag = true"
            >
              Forgot Your Password?
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click.native="display('register')" text>REGISTER</v-btn>
        <v-spacer />
        <v-btn
          :disabled="!isFormValid"
          :loading="loading"
          @click.native="login"
        >
          LOGIN
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Forgotten Password Modal-->
      <v-dialog
        v-model="forgotEmailFlag"
        content-class="top-align-dialog"
        width="500"
        scrollable>
          <ForgotPasswordModal class="ma-3" @close="forgotEmailFlag = false"/>
      </v-dialog>
  </v-container>
</template>

<script>
import ForgotPasswordModal from "./ForgotPasswordModal";
import { LOGIN, TOGGLE_AUTH_DIALOG } from "../../store/actions.type";
import { CLEAR_ERRORS, SET_CURRENT_FORM } from "../../store/mutations.type";

export default {
  name: "LoginForm",
  components: {
    ForgotPasswordModal,
  },
  data() {
    return {
      email: "",
      password: "",
      remember: false,
      loading: false,
      forgotEmailFlag: false,
      isFormValid: false,
      rules: {
        email: [
          (v) => !!v || "ex: someone@example.com",
          (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "ex: someone@example.com",
        ],
        password: [(v) => (!!v && v.length >= 6) || "Password is required"],
      },
    };
  },
  computed: {
    error() {
      return this.$store.getters.getMessage("login");
    },
    errors() {
      return this.$store.getters.getErrors("login");
    },
  },
  methods: {
    login: function () {
      this.loading = true;
      let email = this.email;
      let password = this.password;
      let remember = this.remember;
      this.$store
        .dispatch(LOGIN, { email, password, remember })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearErrors: function () {
      this.$store.commit(CLEAR_ERRORS);
    },
    display(form) {
      this.$store.commit(SET_CURRENT_FORM, form);
    },
    close() {
      this.$store.dispatch(TOGGLE_AUTH_DIALOG);
    }
  },
};
</script>

<style>
</style>
