<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 100"
    >
        <text
            x="100"
            y="55"
            font-family="Arial"
            fill="black"
            stroke="white"
            stroke-width="4"

        >
            {{ teamName }}
        </text>
    </svg>
</template>

<script>
export default {
    name: "NflTeamName",
    props: {
        teamName: String,
    },
};

</script>

<style scoped>
text {
    font-family: "Roboto", sans-serif !important;
    font-size: 1.6em;
    text-anchor: middle;
    dominant-baseline: middle;
    paint-order: stroke fill;
}
</style>
