<template>
  <v-card>
    <v-card-title class="headline">
      Are you sure you want to delete the `{{ screen.name }}` screen?
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('done')">
        Cancel
      </v-btn>
      <v-btn color="warning" text @click="deleteScreen">DELETE</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DELETE_SCREEN } from "../../store/actions.type";

export default {
  name: "DeleteScreenModal",
  props: {
      screen: {
          type: Object,
          default: null
      }
  },
  computed: {
      errors() {
          return this.$store.getters.getErrors('groups');
      }
  },
  methods: {
      deleteScreen() {
          this.$store
            .dispatch(DELETE_SCREEN, this.screen )
            .then(() => this.$emit("done"));
      }
  }
};
</script>