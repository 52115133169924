<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <div v-if="!league">
                <v-alert
                    value="true"
                    type="error"
                    dismissible
                >
                    No leaders available for this league at this time.
                </v-alert>
            </div>
            <div
                v-else
                v-for="season in league.seasons"
                :key="season.season"
            >
                <v-card class="mb-4">
                    <v-card-title>{{ season.name }}</v-card-title>
                    <v-card-text>
                        <!-- Display event types for each season -->
                        <div
                            v-for="eventType in season.eventType"
                            :key="eventType.eventTypeId"
                        >
                            <v-subheader>{{ eventType.name }}</v-subheader>
                            <!-- Display leader categories -->
                            <v-list dense>
                                <v-list-item
                                    v-for="category in eventType.leaderCategory"
                                    :key="category.leaderCategoryId"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>{{ category.name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ category.heading }}</v-list-item-subtitle>

                                        <v-list-item
                                            v-for="ranking in category.ranking"
                                            :key="ranking.rank"
                                        >
                                            <v-list-item-content>
                                                <div>{{ ranking.player.firstName }} {{ ranking.player.lastName }} - {{
                                                    ranking.stat }}</div>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_LEADERS } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";


export default {
    name: "Leaders",
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,

        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
    },
    methods: {

    },
    mounted() {
        this.$store.dispatch(GET_LEAGUE_LEADERS, this.$route.params.league).then(() => {
            this.loading = false;
        });
    },
};
</script>
