<template>
  <v-card>
    <v-card-title>Choose your leagues</v-card-title>
    <v-card-subtitle
      >Filtering by leagues will only show games for your chosen leagues.</v-card-subtitle
    >
    <v-card-text style="height: 400px">
      <loading v-if="loading"></loading>
      <v-row v-else>
        <v-col
          cols="12"
          sm="8"
          md="6"
          v-for="sport in sports"
          v-bind:key="sport.id"
        >
          <h3>
            <v-checkbox
              :key="forceRerender"
              @change="toggleSport(sport.id)"
              :input-value="sportSelected(sport.id)"
              :indeterminate="sportIntermediate(sport.id)"
              hide-details
            >
              <template v-slot:label>
                {{ sport.title }}
                <v-icon small class="pl-2">{{ sport.icon }}</v-icon>
              </template>
            </v-checkbox>
          </h3>
          <v-row no-gutters>
            <v-col
              class="py-0"
              cols="12"
              v-for="league in sport.leagues"
              v-bind:key="league.id"
            >
              <v-checkbox
                class="larger-checkbox"
                :label="league.title"
                v-model="selectedLeagues"
                :value="league.id"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn color="warning" large @click="clear">Clear</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" large @click="$emit('done')">Cancel</v-btn>
      <v-btn color="primary" large @click="finish">Finished</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import Loading from "../../shared/Loading";

import { GET_SPORTS, POST_SELECTED_OPTION } from "../../../store/actions.type";

export default {
  name: "LeaguesModal",
  components: {
    Loading,
  },
  data() {
    return {
      forceRerender: 1,
      loading: true,
      selectedLeagues: [],
    };
  },
  computed: {
    sports() {
      return this.$store.getters.getCurrentSports;
    },
    option() {
      return this.$store.getters.getSingleOption("leagues");
    },
    savedSelectedOptions() {
      return this.$store.getters.getCurrentSelectedOptions(this.option.key);
    },
  },
  methods: {
    simpleLeagues(id) {
      return searchArrayByKey(id, this.sports).leagues.flatMap((l) => l.id);
    },
    sportSelected(id) {
      const leagues = this.simpleLeagues(id);

      const containsAll = leagues.every((league) => {
        return this.selectedLeagues.includes(league);
      });

      return containsAll;
    },
    sportIntermediate(id) {
      if (this.sportSelected(id)) return false;

      const leagues = this.simpleLeagues(id);

      const containsSome = leagues.some((league) => {
        return this.selectedLeagues.includes(league);
      });

      return containsSome;
    },
    toggleSport(sport_id) {
      const leagues = searchArrayByKey(sport_id, this.sports).leagues.flatMap(
        (l) => l.id
      );
      if (this.sportSelected(sport_id)) {
        this.selectedLeagues = this.selectedLeagues.filter(function (sl) {
          return !leagues.includes(sl);
        });
      } else {
        this.selectedLeagues = this.selectedLeagues.concat(leagues);
      }
    },
    clear() {
      this.selectedLeagues = [];
    },
    finish() {
      this.loading = true;
      let key = this.option.key;
      let options = this.selectedLeagues;
    //   this.$gtag.event("api", {
    //     event_category: "leagues",
    //     event_label: "post_selected_option",
    //   });
      this.$store.dispatch(POST_SELECTED_OPTION, { key, options }).then(() => {
        this.loading = false;
        this.$emit("done");
      });
    },
  },
  mounted() {
    let self = this;
    this.$nextTick(function () {
    //   this.$gtag.event("api", {
    //     event_category: "leagues",
    //     event_label: "GET_SPORTS",
    //   });
      this.$store.dispatch(GET_SPORTS).then(() => {
        self.selectedLeagues = self.savedSelectedOptions;
        self.loading = false;
      });
    });
  },
  watch: {
    selectedLeagues: {
      handler: function (val, oldVal) {
        let forceRerender = this.forceRerender;
        Vue.nextTick().then(function () {
          forceRerender += 1;
          console.log("changed");
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
}
.icon-shadow {
  text-shadow: 2px 2px 2px rgb(0 0 0 / 70%);
}
</style>
