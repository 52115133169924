<template>
    <div>
        <v-card>
            <v-list
                flat
                v-if="Object.keys(options).length > 0"
            >
                <v-list-item-group>
                    <v-list-item
                        v-bind:key="option.key"
                        v-for="option in options"
                        @click="$emit('add-option', option)"
                    >
                        <v-list-item-title>{{ option.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-card-text
                v-else
                class="text-center"
            >
                <p>No more options to add.</p>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="$emit('done')"
                >
                    Close
                </v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "AddCustomizationModal",
    props: {
        object: Object,
    },
    computed: {
        settings() {
            return this.object.settings;
        },
        isScreen() {
            return !!this.object.group_id;
        },
        options() {
            let screenOptions = this.$store.getters.getScreenOptions;
            let filteredOptions = Object.entries(screenOptions).reduce((obj, [key, option]) => {
                // Check if the option is not in settings
                if (!this.settings || !this.settings.some(setting => setting.key === key)) {
                    // Apply the isScreen logic
                    if ((this.isScreen && option.forScreen) || (!this.isScreen && option.forGroup)) {
                        obj[key] = option;
                    }
                }
                return obj;
            }, {});
            return filteredOptions;
        },
    },
};
</script>
