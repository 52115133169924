<template>
    <div class="fill-height">
        <loading v-if="loading"></loading>
        <v-main
            v-else
            class="no-print-padding"
            :class="mainClass"
        >
            <v-container
                :class="isMobile ? 'px-0' : ''"
                class="py-0"
                style="max-width: 1360px"
            >
                <date-bar />
                <v-row
                    no-gutters
                    align="center"
                    :justify="'center'"
                    class="px-0 px-sm-2 px-md-14 py-2 py-md-5 d-print-none"
                >
                    <v-col v-if="!isMobile">
                        <v-divider color="lightGrey" />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="5"
                        md="3"
                    >
                        <small-textfield
                            :option="zipOption"
                            :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="5"
                        md="3"
                    >
                        <small-dropdown
                            :option="groupOption"
                            :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="5"
                        md="3"
                    >
                        <small-dropdown
                            :option="sportOption"
                            :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="2"
                        md="1"
                        class="d-print-none"
                        :class="$vuetify.breakpoint.xs ? 'text-center' : ''"
                    >
                        <v-btn
                            color="primary"
                            @click="print"
                        >
                            Print
                        </v-btn>
                    </v-col>
                    <v-col v-if="!isMobile">
                        <v-divider color="lightGrey" />
                    </v-col>
                </v-row>
                <div :class="[
                    isMobile ? 'mt-5' : '',
                ]">
                    <v-row
                        v-if="false"
                        no-gutters
                        align="center"
                        :justify="'center'"
                        class="px-0 px-sm-2 px-md-14 py-2 py-md-5 d-print-none"
                    >

                        <v-col v-if="!isMobile">
                            <v-divider color="lightGrey" />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                        >
                            <small-dropdown
                                :option="groupOption"
                                :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="3"
                        >
                            <small-dropdown
                                :option="sportOption"
                                :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : 'my-2'"
                            />
                        </v-col>
                        <v-col v-if="!isMobile">
                            <v-divider color="lightGrey" />
                        </v-col>
                    </v-row>
                    <games
                        :games="games"
                        :key="'home'"
                    />

                </div>
            </v-container>
        </v-main>
    </div>
</template>


<script>
import Loading from "../components/shared/Loading";
import DateBar from "../components/Home/Filters/DateBar";
import Filters from "../components/Home/Filters";
import Games from "../components/Home/Games";
import SmallDropdown from "../components/Home/Filters/SmallDropdown";
import SmallTextfield from "../components/Home/Filters/SmallTextfield";

import {
    GET_OPTIONS,
    GET_SELECTED_OPTIONS,
    POST_SELECTED_OPTION,
    GET_GAMES,
    POST_PARAM_OPTIONS
} from "../store/actions.type";

import { CLOSE_LEAGUES_MODAL } from "../store/mutations.type";


export default {
    name: "Mvp",
    components: {
        Loading,
        DateBar,
        Filters,
        SmallDropdown,
        SmallTextfield,
        Games
    },
    data() {
        return {
            loading: true,
            dialog: false,
            banner: true,
        };
    },
    computed: {
        storedGames() {
            return this.$store.getters.getSortedFilteredGames;
        },
        games() {
            return this.storedGames;
        },
        timezone() {
            return this.$store.getters.getCurrentSelectedOptions("timezone");
        },
        hasParams() {
            return this.$route.params.sport &&
                this.$route.params.league &&
                this.$route.params.date
                ? true
                : false;
        },
        zipOption() {
            return this.$store.getters.getSingleOption("zip");
        },
        groupOption() {
            return this.$store.getters.getSingleOption("groups");
        },
        sportOption() {
            return this.$store.getters.getSingleOption("sports");
        },
        layoutOption() {
            return this.$store.getters.getSingleOption("layout");
        },
        completedOption() {
            return this.$store.getters.getSingleOption("completed");
        },
        isFiltersMenuOpen() {
            return this.$store.getters.isFiltersMenuOpen;
        },
        isSportsMenuOpen() {
            return this.$store.getters.isSportsMenuOpen;
        },
        isAnyMenuOpen() {
            return this.isFiltersMenuOpen || this.isSportsMenuOpen;
        },
        isLeaguesModalOpen() {
            return this.$store.getters.isLeaguesModalOpen;
        },
        mainClass() {
            let finalClass = "";
            if (this.isAnyMenuOpen) finalClass = finalClass + " d-none";
            return finalClass;
        },
    },
    methods: {
        print() {
            window.print();
        },
        doneLeaguesModal() {
            this.loading = false;
            this.$store.commit(CLOSE_LEAGUES_MODAL);
        },
    },
    async mounted() {
        this.$store
            .dispatch(GET_OPTIONS)
            .then(() => {
                // this.$gtag.event("api", {
                //     event_category: "options",
                //     event_label: "get_selected_options",
                // });
                this.$store.dispatch(GET_SELECTED_OPTIONS)
            })
            .then(() => {
                let key = "groups";
                let options = 3;
                this.$store.dispatch(POST_SELECTED_OPTION, { key, options });
            })
            .then(() => {

                this.$store
                    .dispatch(GET_GAMES, this.$route.params.groupuid)
                    .then(() => {
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.loading = false;
                    });
            });
    },
};
</script>

<style scoped>
@media print {
    .no-print-padding {
        padding: 0 !important;
    }
}

.gradient-background-flipped {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(244, 244, 244, 1) 70px,
            rgba(244, 244, 244, 1) 100%);
}
</style>
