<template>
    <tr>
        <td>
            <v-avatar
                size="60"
                class="channel-logo-shadow my-3"
            >
                <v-img
                    width="40"
                    height="40"
                    contain
                    class="channel-image"
                    @error="onImgError"
                    placeholder="https://www.directv.com/img/ent/global/missing_channel_logo.png"
                    :src="channelLogo"
                    :alt="channel.title_display + ' Logo'"
                />
            </v-avatar>
        </td>
        <td>
            <h3 class="jet">{{ channel.title_display }}</h3>
            <div class="jet">{{ channel.callsign_display }}</div>
            <div class="jet">Channel {{ channel.number_display }}</div>
        </td>
        <td>
            <template
                v-if="channel.has_matching_rsn"
                v-for="rsn_channel in channel.rsn_channels"
            >
                <div
                    v-for="team in rsn_channel.teams"
                    :key="team.id"
                    class="team-name jet"
                >
                    <v-icon
                        size="16"
                        class="check-mark-color"
                    >mdi-check</v-icon>
                    {{ team.display_name_full }}
                </div>
            </template>
        </td>
        <td class="packages-column">
            <div class="mt-3">
                <template v-if="channel.has_matching_rsn">
                    <div class="jet font-weight-bold mb-2">
                        Available on Satellite{{ channel.rsn_channels[0].stream ? ' and Stream' : '' }}
                    </div>
                    <div class="jet">Commercial Packages:</div>
                    <ul class="package-list mt-1">
                        <li
                            v-if="channel.rsn_channels[0].bxp || channel.rsn_channels[0].choice || channel.rsn_channels[0].sports_pack || channel.rsn_channels[0].ultimate">
                            Business XTRA Pack
                        </li>
                        <li v-if="channel.rsn_channels[0].choice || channel.rsn_channels[0].ultimate">
                            Commercial Choice Plus
                        </li>
                        <li
                            v-if="channel.rsn_channels[0].cxp || channel.rsn_channels[0].choice || channel.rsn_channels[0].sports_pack || channel.rsn_channels[0].ultimate">
                            Commercial Xtra Pack
                        </li>
                        <li v-if="channel.rsn_channels[0].ultimate">
                            Commercial Ultimate
                        </li>
                    </ul>
                </template>
                <div class="mt-5" v-else>
                    Available for purchase as an a-la-carte option.
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    name: "RsnChannelTableRow",
    props: { channel: Object },
    data() {
        return {
            failed_image: false,
        };
    },
    computed: {
        channelLogo() {
            if (this.failed_image) return "https://www.directv.com/img/ent/global/missing_channel_logo.png";

            if (Number.isInteger(this.channel.logo)) {
                return `https://www.directv.com/dtvassets/dtv/teamsite/images/logos/channels/dark/medium/${this.channel.logo}.png`;
            } else {
                return `${this.appUrl}/img/logos/channels/${this.channel.logo}.svg`;
            }
        }
    },
    methods: {
        onImgError(event) {
            console.log("image error");
            this.failed_image = true;
        },
    },
};
</script>

<style scoped>
.channel-logo-shadow {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06),
        0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
}

.team-name {
    font-size: 14px;
}

.jet {
    font-family: "Roboto", sans-serif !important;
    color: #102641;
}

.check-mark-color {
    color: #2e71bc;
}

.channel-image {
    max-width: 70% !important;
    border-radius: 0 !important;
}

.packages-column {
    vertical-align: top;
}

.package-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.package-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 4px;
    font-size: 14px;
}

.package-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #2e71bc;
    font-weight: bold;
}
</style>
