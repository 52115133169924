<template>
    <div>
        <v-row
            class="ma-2 text-caption d-print-none"
            v-if="!isEmbed && gameBulletins.length"
        >
            <v-col
                cols="12"
                v-for="bulletin in gameBulletins"
                :key="bulletin.id"
            >
                <div v-html=bulletin.body />
            </v-col>
        </v-row>
        <v-divider class="divider mb-2 d-print-none" v-if="!isEmbed && gameBulletins.length" />
    </div>
</template>

<script>
export default {
    name: "GameBulletins",
    props: {
        game: Object,
    },
    computed: {
        layout() {
            return this.$store.getters.getCurrentSelectedOptions("layout");
        },
        bulletins() {
            return this.$store.getters.getBulletins;
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
        gameBulletins() {
            let gameSpecificBulletins = this.game.bulletins.filter(b => {
                return this.isCommercial ? b.commercial : b.residential;
            });

            let gameGeneralBulletins = this.bulletins.filter((bulletin) => {
                let hasMatchingChannel = false;
                let hasMatchingLeague = false;
                let hasMatchingTeam = false;

                let channelNumbers = this.game.showings.map((showing) => {
                    return showing.channel.number_major;
                });

                if (bulletin.channel_number_major == null && bulletin.league_id == null && bulletin.team_id == null) {
                    return false;
                }

                if (bulletin.channel_number_major == null || channelNumbers.includes(bulletin.channel_number_major)) {
                    hasMatchingChannel = true;
                }
                if (bulletin.league_id == null || bulletin.league_id == this.game.league_id) {
                    hasMatchingLeague = true;
                }

                if (bulletin.team_id == null || (bulletin.team_id == this.game.home_team_id || bulletin.team_id == this.game.away_team_id)) {
                    hasMatchingTeam = true;
                }

                return hasMatchingChannel && hasMatchingLeague && hasMatchingTeam;
            });

            // Combine game-specific and general bulletins
            let allBulletins = gameSpecificBulletins.concat(gameGeneralBulletins);

            // Remove duplicates based on bulletin.body
            let uniqueBulletins = allBulletins.reduce((acc, current) => {
                const x = acc.find(item => item.body === current.body);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            return uniqueBulletins;
        }
    },
};
</script>

<style scoped>
.divider
{
    border: 1px solid rgba(0, 0, 0, .12)
}
</style>
